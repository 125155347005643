import { configure, makeAutoObservable } from 'mobx';
import { IOrder } from './OrderService';
import apiService from '../../../services/ApiService';
import { IShowImages } from '../../Inventory/Services/ReceiveService';
import { IRandomInspection } from './RandomInspectionService';
import { IUser } from '../../UserManagement/Services/RoleService';
import { IVapourHeatTreatment } from './VapourHeatTreatmentService';
import { IPackagingDetail } from './PackagingService';

export interface IBake {
  id?: string;
  censorInspecWeight?: string;
  totalBeforeBakeWeight?: number;
  totalAfterBakeWeight?: number;
  docNo?: string;
  issueWeight?: number;
  vhtCost?: number;
  totalVht?: number;
  eliminateWeight?: number;
  order?: IOrder | null;
  // TODO : change this when vapourHeatTreatment is ready
  vapourHeatTreatment?: IVapourHeatTreatment | undefined;
  status?: string;
  isDelete?: boolean;
  vat3?: number;
  vat7?: number;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  approveBy?: IUser | undefined;
  image?: IShowImages[];
  createAt?: Date;
  updateAt?: Date;
  details?: IBakeDetail[] | undefined;

}

export interface IBakeDetail {
  id?: string;
  // TODO : change this when randomInspection is ready
  randomInspection?: IRandomInspection | undefined;
  isDelete?: boolean;
  createAt?: Date;
  createBy?: IUser | undefined;
  updateAt?: Date;
  updateBy?: IUser | undefined;
  bake?: IBakeRef | undefined;
  packagings?: IPackagingDetail[] | undefined;
}

export interface IBakeRef {
  id?: string;
  status?: string;
  docNo?: string;
  issueWeight?: number;
  vhtCost?: number;
  totalVht?: number;
  eliminateWeight?: number;
  vapourHeatTreatment?: IVapourHeatTreatment | undefined;
  isDelete?: boolean;
  vat3?: number;
  vat7?: number;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  approveBy?: IUser | undefined;
  image?: IShowImages[];
  createAt?: Date;
  updateAt?: Date;
  remain?: number;
}

export interface IBakeListView {
  datas?: IBake[];
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IBakeCriteria {
  dateFrom?: Date;
  dateTo?: Date;
  docNo?: string;
  status?: string;
  page?: number;
  limit?: number;
}

class BakeService {
  bakeList: IBakeListView | undefined;
  bake: IBake | undefined;
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const bakeList = await apiService.get("bakes");
      this.bakeList = bakeList;
      return bakeList;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      const bake = await apiService.get(`bakes/${id}`);
      this.bake = bake;
      return bake;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IBakeCriteria) {
    try {
      const bakeList = await apiService.post("bakes/criteria", criteria);
      this.bakeList = bakeList;
      return bakeList;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IBake, uploadFile: any) {
    try {
      const bake = await apiService.post("bakes", data);
      if (uploadFile && uploadFile?.length > 0) {
        for (const file of uploadFile) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", bake.id || "");
          formData.append("refType", "bake");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      return bake;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: IBake, uploadFile: any) {
    try {
      delete data.image;
      const bake = await apiService.put(`bakes/${id}`, data);
      if (uploadFile && uploadFile?.length > 0 && (data.status !== 'approved' && data.status !== 'rejected')) {
        for (const file of uploadFile) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", id || "");
          formData.append("refType", "bake");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      return bake;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const bake = await apiService.delete(`bakes/${id}`);
      return bake;
    } catch (error) {
      throw error;
    }
  }

  get getBakeList() {
    return this.bakeList;
  }

  get getBake() {
    return this.bake;
  }
}

const bakeService = new BakeService();
export default bakeService;