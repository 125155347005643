import { observer } from "mobx-react-lite";
import randomInspectionService, { IRandomInspection } from "../Services/RandomInspectionService";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { enqueueSnackbar } from "notistack";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import RandomInspectionCreateView from "../Views/RandomInspectionCreateView";
import { IUploadFile } from "../../Company/Services/CompanyService";
import receiveService from "../../Inventory/Services/ReceiveService";
import clearState from "../../../utils/resetState";
import loginService, { IUserLogined } from "../../Login/Services/LoginService";


const RandomInspectionCreateController = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();

  useEffect(() => {
    if (id) {
      findOne(id);
    }
    checkPermissionActionButton();
    getOrderByIsComplete();

    return () => {
      clearState(randomInspectionService, ["randomInspection"]);
    };
  }, [id]);

  const create = async (data: IRandomInspection, uploadFile: IUploadFile) => {
    try {
      setIsLoading(true);
      const response = await randomInspectionService.create(data, { file: uploadFile });

      if (response) {
        setTimeout(() => {
          navigate(`/process-random-inspection-create/${response.id}`);
          window.location.reload();
        }, 200);
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    }
    catch (error) {
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const update = async (id: string, data: IRandomInspection, uploadFile: IUploadFile[], deleteImages: IUploadFile[]) => {
    try {
      setIsLoading(true);
      const _uploadFile = data.image === uploadFile ? undefined : uploadFile;

      const { image, ...formatData } = data;


      const res = await randomInspectionService.update(data.id, formatData, { file: _uploadFile });
      if (res) {
        if (deleteImages && deleteImages.length > 0) {
          const deletePromises = deleteImages.map((file: any) => {
            return receiveService.deleteImages({ file });
          });
          await Promise.all(deletePromises);
        }
        setTimeout(() => {
          findOne(id ?? "");
          window.location.reload();
        }, 200);
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    } finally {
      setIsLoading(false);
    }
  };


  const findOne = async (id: string) => {
    try {
      const res = await randomInspectionService.getOne(id);
      return res;
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการค้นหา", error);
    }
  };

  const onDelete = async (data: IRandomInspection) => {
    try {
      delete data.image;
      await randomInspectionService.update(id ?? "", data);
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
      navigate("/process-random-inspection");
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-random-inspection");
    if (result) {
      setPermission(result);
    }
  };

  const getOrderByIsComplete = async () => {
    try {
      setIsLoading(true);
      await randomInspectionService.getOrderByIsComplete();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };



  return <RandomInspectionCreateView onCreate={create} onUpdate={update} onDelete={onDelete} randomInspection={randomInspectionService.randomInspection ?? {} as IRandomInspection} randomInspectionId={id ?? ""} isLoading={isLoading} permission={permission ?? {} as IUserDetail} order={randomInspectionService.ordersData ?? []} user={loginService.userLoginedData ?? {} as IUserLogined}

  />;

});

export default RandomInspectionCreateController;