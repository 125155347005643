import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import marketService, { IMarket, IMarketCriteria, IMarkets } from "../Services/MarketService";
import companyService from "../../Company/Services/CompanyService";
import { enqueueSnackbar } from "notistack";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import MarketListView from "../Views/MarketListView";

const MarketController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [marketData, setMarketDataData] = useState<IMarkets>();
  const defaultCriteria: IMarketCriteria = {
    name: "",
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getProvince();
    getByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProvince = async () => {
    try {
      setIsLoading(true);
      await companyService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDistrict = async (id?: number) => {
    if (!id) return;
    try {
      setIsLoading(true);
      await companyService.getDistrict(id);
    } catch (error) {
      console.error("An error occurred while getting the district:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSubDistrict = async (id?: number) => {
    if (!id) return;
    try {
      setIsLoading(true);
      await companyService.getSubDistrict(id);
    } catch (error) {
      console.error("An error occurred while getting the sub-district:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getByCriteria = async (criteria: IMarketCriteria) => {
    try {
      setIsLoading(true);
      await marketService.getByCriteria(criteria).then((data) => {
        data?.datas?.sort((a: IMarket, b: IMarket) => (b.createAt?.toString() ?? '').localeCompare(a.createAt?.toString() ?? ''));
        setMarketDataData(data);
      });
    } catch (error) {
      console.error("An error occurred while getting the warehouses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (data: IMarket) => {
    try {
      setIsLoading(true);
      const res = await marketService.create(data);
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      getByCriteria(defaultCriteria);
    } catch (error) {
      console.error("An error occurred while creating the warehouse:", error);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: IMarket) => {
    try {
      setIsLoading(true);
      const res = await marketService.update(data);
      if (res) {
        enqueueSnackbar("อัพเดทข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("อัพเดทข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      getByCriteria(defaultCriteria);
    } catch (error) {
      console.error("An error occurred while updating the warehouse:", error);
      enqueueSnackbar("อัพเดทข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("setting-market");
    if (result) {
      setPermission(result);
    }
  };


  return <MarketListView isLoading={isLoading}
    markets={marketData ?? {} as IMarkets}
    market={marketService.marketData ?? {} as IMarket}
    onSerchByCriteria={getByCriteria}
    onCreate={create}
    onUpdate={update}
    provinceData={companyService.provinceData || []}
    districtData={companyService.districtData || []}
    subDistrictData={companyService.subDistrictData || []}
    onFetchDistrict={getDistrict}
    onFetchSubDistrict={getSubDistrict}
    permission={permission ?? {} as IUserDetail} />;;
});

export default MarketController;