import { FC, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { Box, Breadcrumbs, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
import SearchIcon from "@mui/icons-material/Search";
import { IShippingType, IShippingTypeCriteria, IShippingTypeList } from "../Services/ShippingTypeService";
import ShippingTypeCreateView from "./ShippingTypeCreateView";
import dayjs from "dayjs";

interface IShippingTypeListViewProps {
  isLoading: boolean;
  datas: IShippingTypeList;
  onSerchByCriteria: (criteria: IShippingTypeCriteria) => Promise<void>;
  onCreate: (data: IShippingType) => Promise<void>;
  onUpdate: (data: IShippingType) => Promise<void>;
  permission: IUserDetail;
}

const ShippingTypeListView: FC<IShippingTypeListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<IShippingType>();

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IShippingTypeCriteria = {
      name: name,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSerchByCriteria(criteria);
  };

  const onSubmit = (data: IShippingType) => {
    if (data.id) {
      props.onUpdate(data);
    } else {
      props.onCreate(data);
    }
  };

  const marketColumns = [
    { label: 'ชื่อ', render: (row: IShippingType) => row.name },
    {
      label: 'บันทึกล่าสุด', render: (row: IShippingType) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.createBy?.firstName || ''} {row.createBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? '' : dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IShippingType) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.updateBy?.firstName || ''} {row.updateBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? '' : dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
        </Box>
      )
    },
  ];
  const marketActions = (row: IShippingType) => (
    <IconButton
      onClick={() => {
        setData(row);
        setOpen(true);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );


  const actionButtons = (
    <Button
      id={`${!props.permission.create ? "hidden" : "create"}`}
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        setOpen(true);
        setData({} as IShippingType);
      }}>
      สร้าง
    </Button>
  );

  return <ListViewPage
    breadcrumb={
      <>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>ประเภทการขนส่ง</Typography>
          <Typography variant="h6" color="text.primary">รายการ</Typography>
        </Breadcrumbs>
      </>}

    criteria={
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10} md={10} lg={10}>
            <TextField fullWidth label="ชื่อรายการ" onChange={(e) => setName(e.target.value)} />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"center"}>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => searchByCriteria(0)}>ค้นหา
            </Button>
          </Grid>
        </Grid>
      </>
    }
    table={
      <>
        <TableDataList
          data={props?.datas?.datas || []}
          total={props.datas.total ?? 0}
          columns={marketColumns}
          actionButtons={actionButtons}
          rowActions={marketActions}
          page={page}
          limit={limit}
          onPageChange={(newPage) => {
            setPage(newPage);
            searchByCriteria(newPage, limit);
          }}
          onRowsPerPageChange={(newLimit) => {
            setLimit(newLimit);
            setPage(0);
            searchByCriteria(0, newLimit);
          }}
        />
      </>
    }
    dialog={
      <>
        <ShippingTypeCreateView open={open}
          data={data ?? {} as IShippingType}
          onClose={() => {
            setOpen(false);
            setData({} as IShippingType);
          }}
          onSubmit={onSubmit}
          permission={props.permission}
        />
      </>
    }
  />;
};

export default ShippingTypeListView;