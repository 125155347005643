import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import { IWarehouse } from "./WareshousesService";
import { IMaterial } from "./MaterialsService";
import { IUser } from "../../UserManagement/Services/RoleService";
import { ISupplierType } from "../../Supplier/Services/SupplierTypeService";
export interface IReceive {
  id: string;
  receivedDate: Date;
  poNumber: string;
  status: string;
  totalLists: number;
  totalItems: number;
  totalPrice: number;
  supplier: ISupplier;
  docNo?: string;
  note?: string;
  isDelete?: boolean;
  approveBy?: IUser | undefined;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: string;
  updateAt?: string;
  image?: IShowImages[] | undefined;
  details: IReceiveDetail[];
}

export interface IReceiveDetail {
  order: number;
  qty: number;
  unitPrice: number;
  totalPricePerRow: number;
  warehouse: IWarehouse;
  material: IMaterial;
}

export interface IReceiveView {
  datas: IReceive[];
  all: number;
  page: number;
  limit: number;
  total: number;
}

export interface IReceiveCriteria {
  dateFrom?: Date;
  dateTo?: Date;
  poNumber?: string;
  status?: string;
  warehouse?: string;
  page: number;
  limit: number;
}

export interface IUploadFile {
  file: any;
}

export interface IShowImages {
  id?: string;
  name?: string;
  refId?: string;
  refType?: string;
  isDelete?: boolean;
  createAt?: Date;
  updateAt?: Date;
}

export interface IProvince {
  id?: number;
  nameTh?: string;
}

export interface IDistrict {
  id?: number;
  nameTh?: string;
  province?: IProvince | null;
}

export interface ISubDistrict {
  id?: number;
  nameTh?: string;
  district?: IDistrict | null;
  zipCode?: string;
}

class ReceiveService {
  receives: IReceiveView = {} as IReceiveView;
  receive: IReceive = {} as IReceive;
  suppliers: ISupplier[] = [];
  warehouses: IWarehouse[] = [];
  materials: IMaterial[] = [];
  province: IProvince[] = [];
  district: IDistrict[] = [];
  subDistrict: ISubDistrict[] = [];
  supplierTypeList: ISupplierType[] | undefined;

  constructor() {
    configure({
      enforceActions: "never",
    });
    this.warehouses = [] as IWarehouse[];

    makeAutoObservable(this);
  }

  async getOne(id: string): Promise<IReceive> {
    try {
      const response = await apiService.get(`/receive/${id}`);
      if (response) {
        this.receive = response;
      }
      return this.receive;
    }
    catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IReceiveCriteria) {
    try {
      const response = await apiService.post(`/receive/criteria`, criteria);
      if (response.datas) {
        this.receives = response;
      }
      return this.receives;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IReceive, uploadFile?: IUploadFile) {
    try {
      const response = await apiService.post('/receive', data);
      if (uploadFile?.file.length > 0 && uploadFile?.file) {
        for (const file of uploadFile.file) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", response?.id || "");
          formData.append("refType", "receive");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      return response;
    } catch (error) {
      throw error;
    }
  }


  async update(id: string, data: IReceive, uploadFile?: IUploadFile) {
    try {
      const response = await apiService.put(`/receive/${id}`, data);
      if (uploadFile?.file.length > 0 && uploadFile?.file) {
        for (const file of uploadFile.file) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", id);
          formData.append("refType", "receive");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteImages(deleteFile: IUploadFile) {
    try {
      const imageId = deleteFile.file.id;
      const data = { isDelete: true };
      let response = '';
      await apiService.put(`/images/${imageId}`, data).then(async (res) => {
        if (res) {
          const data = { filename: deleteFile.file.name };
          await apiService.post('/images/delete', data).then((res) => {
            if (res) {
              response = res;
            }
          });
        }
        return response;
      });
    } catch (error) {
      throw error;
    }
  }

  async getSuppliers() {
    try {
      this.suppliers = await apiService.get('/supplier');
      return this.suppliers;
    } catch (error) {
      throw error;
    }
  }

  async getWarehouses() {
    try {
      this.warehouses = await apiService.get('/warehouses');
      return this.warehouses;
    } catch (error) {
      throw error;
    }
  }

  async getMaterials() {
    try {
      this.materials = await apiService.get('/materials');
      return this.materials;
    } catch (error) {
      throw error;
    }
  }

  async getProvince() {
    try {
      this.province = await apiService.get(`/provinces`);
      return this.province;
    } catch (error) {
      throw error;
    }
  }

  async getDistrict(id: number) {
    try {
      this.district = await apiService.get(`/districts?provinceId=${id}`);
      return this.district;
    } catch (error) {
      throw error;
    }
  }

  async getSubDistrict(id: number) {
    try {
      this.subDistrict = await apiService.get(
        `/sub-districts?districtId=${id}`
      );
      return this.subDistrict;
    } catch (error) {
      throw error;
    }
  }

  async getAllSupplierType() {
    try {
      const supplierTypes = await apiService.get("supplier/type/all");
      this.supplierTypeList = supplierTypes;
      return this.supplierTypeList;
    } catch (error) {
      throw error;
    }
  }

  get receiveData() {
    return this.receive;
  }

  get receivesData() {
    return this.receives;
  }

  get suppliersData() {
    return this.suppliers;
  }
  get warehousesData() {
    return this.warehouses;
  }

  get materialsData() {
    return this.materials;
  }

  get provinceData() {
    return this.province;
  }

  get districtData() {
    return this.district;
  }

  get subDistrictData() {
    return this.subDistrict;
  }

  get supplierTypeListData() {
    return this.supplierTypeList;
  }
}

const receiveService = new ReceiveService();
export default receiveService;