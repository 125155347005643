import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from "../../UserManagement/Services/RoleService";

export interface IDriver {
  id?: string;
  firstName?: string;
  lastName?: string;
  driverId?: string;
  phone?: string;
  isDelete?: boolean;
  createdBy?: IUser | undefined;
  updatedBy?: string;
  createdAt?: IUser | undefined;
  updatedAt?: string;
}

export interface IDriverListView {
  datas?: IDriver[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IDriverCriteria {
  page: number;
  limit: number;
  name: string;
}

class DriverService {
  drivers: IDriverListView | undefined;
  driver: IDriver | undefined;
  driverList = [] as IDriver[];

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      this.driverList = await apiService.get("drivers");
      return this.driverList;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      const driver = await apiService.get(`drivers/${id}`);
      this.driver = driver;
      return driver;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IDriverCriteria) {
    try {
      const drivers = await apiService.post("drivers/criteria", criteria);
      this.drivers = drivers;
      return drivers;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IDriver) {
    try {
      const driver = await apiService.post("drivers", data);
      return driver;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: IDriver) {
    try {
      const driver = await apiService.put(`drivers/${id}`, data);
      return driver;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      await apiService.delete(`drivers/${id}`);
    } catch (error) {
      throw error;
    }
  }

  get driversData() {
    return this.drivers;
  }

  get driverData() {
    return this.driver;
  }

  get driverListData() {
    return this.driverList;
  }
}

const driverService = new DriverService();
export default driverService;