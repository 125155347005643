import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from "../../UserManagement/Services/RoleService";

export interface IGrade {
  id?: string;
  name?: string;
  capacityFrom?: number;
  capacityTo?: number;
  weight?: number;
  remark?: string;
  isDelete?: boolean;
  createdBy?: IUser | undefined;
  updatedBy?: string;
  createdAt?: IUser | undefined;
  updatedAt?: string;
}

export interface IGradeListView {
  datas?: IGrade[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IGradeCriteria {
  page: number;
  limit: number;
  name: string;
}

class GradeService {
  grades: IGradeListView | undefined;
  grade: IGrade | undefined;
  gradeList: IGrade[] | undefined;

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      this.gradeList = await apiService.get("grades");
      return this.gradeList;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      const grade = await apiService.get(`grades/${id}`);
      this.grade = grade;
      return grade;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IGradeCriteria) {
    try {
      this.grades = await apiService.post("grades/criteria", criteria);
      return this.grades;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IGrade) {
    try {
      const result = await apiService.post("grades", data);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: IGrade) {
    try {
      const result = await apiService.put(`grades/${id}`, data);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const result = await apiService.delete(`grades/${id}`);
      return result;
    } catch (error) {
      throw error;
    }
  }

  get gradesData() {
    return this.grades;
  }

  get gradeData() {
    return this.grade;
  }

  get gradeListData() {
    return this.gradeList;
  }
}

const gradeService = new GradeService();
export default gradeService;