import { observer } from "mobx-react-lite";
import userService, { IUserSuperAdmin } from "../Services/UserService";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import UserDetailView from "../Views/UserDetailView";
import userGroupService from "../../UserManagement/Services/UserGroupService";
import companyService from "../Services/CompanyService";
import { enqueueSnackbar } from "notistack";
import clearState from "../../../utils/resetState";

const UserDetailController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) { getOne(id); }
    getAllUserGroups();
    getAllCompanies();
    getProvince();
    return () => {
      clearState(userService, ["userData"]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const getAllUserGroups = async () => {
    try {
      setIsLoading(true);
      await userGroupService.getAll();
    } catch (error) {
      console.error("Error searching user groups by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllCompanies = async () => {
    try {
      setIsLoading(true);
      await companyService.getAll();
    } catch (error) {
      console.error("Error searching companies by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOne = async (id: string) => {
    try {
      setIsLoading(true);
      await userService.getOne(id);
    } catch (error) {
      console.error("Error searching user by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const create = async (data: IUserSuperAdmin) => {
    try {
      setIsLoading(true);
      const response = await userService.create(data);
      getOne(response.id);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/admin-user-detail/${response.id}`);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: IUserSuperAdmin) => {
    try {
      setIsLoading(true);
      if (id) { await userService.update(data); }
      getOne(data.id);
      if (data.isDelete === true) {
        navigate(`/admin-user`);
      }
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const remove = async () => {
    try {
      setIsLoading(true);
      if (id) { await userService.delete(id); }
      navigate(`/admin-user-detail`);
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "position":
          response = await userGroupService.create(data);
          getAllUserGroups();
          break;
        case "company":
          response = await companyService.create(data);
          getAllCompanies();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const onFetchData = async (id?: any, type?: string) => {
    try {
      switch (type) {
        case "district": await companyService.getDistrict(id);
          break;
        case "subDistrict": await companyService.getSubDistrict(id);
          break;
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const getProvince = async () => {
    try {
      setIsLoading(true);
      await companyService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return <UserDetailView onCreate={create} onUpdate={update} onRemove={remove} isLoading={isLoading} data={id ? userService.userData as IUserSuperAdmin : undefined}
    userGroup={userGroupService.getGroupList} company={companyService.companiesList}
    onCreateDialog={onCreateDialog}
    onFetchData={onFetchData}
    provinceData={companyService.provinceData || []}
    districtData={companyService.districtData || []}
    subDistrictData={companyService.subDistrictData || []}
  />;
});

export default UserDetailController;
