import { FC, useState } from "react";
import { ICompany, ICompanyCriteria, ICompanyListView } from "../Services/CompanyService";
import { Chip, Box, IconButton, Button, Breadcrumbs, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
import { statusOptions } from "../../../constants/statusOptions";
import { isDeleteOptions } from "../../../constants/isDeleteOptions";
import { IUserDetail } from "../Services/UserService";

interface CompaniesListViewProps {
  onSearchByCriteria: (criteria: ICompanyCriteria) => Promise<void>;
  isLoading?: boolean | false;
  data: ICompanyListView;
  permission: IUserDetail;
}
const CompaniesListView: FC<CompaniesListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const [status, setStatus] = useState<boolean | string>("undefined");
  const [isDelete, setIsDelete] = useState<boolean | string>("undefined");
  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: ICompanyCriteria = {
      name: name,
      status: status === "undefined" ? undefined : status === "true" ? true : false,
      isDelete: isDelete === "undefined" ? undefined : isDelete === "true" ? true : false,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearchByCriteria(criteria);
  };
  //SECTION - Variables for columns TableDataList
  const columns = [
    { label: 'บริษัท', render: (row: ICompany) => (<Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>{`${row.name || ''}`}</Box>) },
    {
      label: 'Status', render: (row: ICompany) => (<Chip
        label={row.isActive ? 'Active' : 'Inactive'}
        color={row.isActive ? 'success' : 'default'}
        variant='outlined'
      />)
    },
    {
      label: 'บันทึกล่าสุด', render: (row: ICompany) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.createBy?.firstName || ''} {row.createBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? '' : dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: ICompany) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.updateBy?.firstName || ''} {row.updateBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? '' : dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
        </Box>
      )
    },
  ];
  const actions = (row: ICompany) => (
    <IconButton
      onClick={() => {
        navigate(`/admin-company-detail/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        navigate(`/admin-company-detail`);
      }}>
      สร้าง
    </Button>
  ) : <></>;
  return (<ListViewPage
    breadcrumb={
      <>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>บริษัท</Typography>
          <Typography variant="h6" color="text.primary">รายการ</Typography>
        </Breadcrumbs>
      </>
    }
    criteria={
      <>
        <Box component={"form"}>
          <Grid container spacing={2} alignItems={"end"}>
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <Grid container spacing={2} alignItems={"end"}>
                <Grid item xs={12} sm={12} md={12} lg={8}>
                  <TextField fullWidth label="บริษัท" value={name} onChange={(e) => setName(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">สถานะ</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      value={status}
                      label="สถานะ"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      {statusOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">ลบข้อมูล</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      value={isDelete}
                      label="ลบข้อมูล"
                      onChange={(e) => setIsDelete(e.target.value)}
                    >
                      {isDeleteOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} textAlign={"end"} alignSelf={"end"}>
              <Button
                onClick={() => searchByCriteria()}
                variant="contained"
                startIcon={<SearchIcon />}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>}
    table={
      <>
        <TableDataList
          data={props.data?.data || []}
          total={props.data?.total || 0}
          columns={columns}
          actionButtons={actionButtons}
          rowActions={actions}
          page={page}
          limit={limit}
          onPageChange={(newPage: number) => {
            setPage(newPage);
            searchByCriteria(newPage);
          }}
          onRowsPerPageChange={(newLimit) => {
            setLimit(newLimit);
            setPage(0);
            searchByCriteria(0, newLimit);
          }}
        />
      </>}
    isLoading={props.isLoading ?? false}
  />);
};

export default CompaniesListView;
