import { configure, makeAutoObservable } from "mobx";
import { IDistrict, IProvince, ISubDistrict } from "../../Company/Services/CompanyService";
import apiService from "../../../services/ApiService";
import { IUser } from "../../UserManagement/Services/RoleService";

export interface IWarehouse {
  id?: string;
  name?: string;
  address?: string;
  subDistrict?: ISubDistrict | undefined;
  district?: IDistrict | undefined;
  province?: IProvince | undefined;
  zipCode?: string;
  tel?: string;
  manager: IUser;
  isDelete?: boolean;
  createdBy?: IUser | undefined;
  updatedBy?: IUser | undefined;
  createdAt?: Date;
  updatedAt?: Date;
  total?: number;
}
export interface IWarehouses {
  datas: IWarehouse[];
  all: number;
  page: number;
  limit: number;
  total: number;
}

export interface IWarehouseCriteria {
  page: number;
  limit: number;
  name?: string;
}

class WaerehousesService {
  warehouses: IWarehouses | undefined;
  warehouse: IWarehouse | undefined;
  users: IUser[] | undefined;
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async get(id: string) {
    try {
      this.warehouse = await apiService.get(`warehouses/${id}`);
      return this.warehouse;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IWarehouseCriteria) {
    try {
      this.warehouses = await apiService.post("warehouses/criteria", criteria);
      return this.warehouses;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IWarehouse) {
    try {
      return await apiService.post("warehouses", data);
    } catch (error) {
      throw error;
    }
  }

  async update(data: IWarehouse) {
    try {
      return await apiService.put(`warehouses/${data.id}`, data);
    } catch (error) {
      throw error;
    }
  }

  async getUser() {
    try {
      this.users = await apiService.get(`users`);
      return this.users;
    } catch (error) {
      console.error(error);
    }
  }

  get warehousesData() {
    return this.warehouses;
  }

  get warehouseData() {
    return this.warehouse;
  }

  get usersData() {
    return this.users;
  }
}

const wareshousesService = new WaerehousesService();
export default wareshousesService;
