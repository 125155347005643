import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import reportService, { IReportCriteria, ISalesReport, ISalesReports } from "../Services/ReportService";
import SalesReportListView from "../Views/SalesReportListView";


const SalesReportListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [salesReportData, setSalesReportData] = useState<ISalesReports>();
  const defaultCriteria: IReportCriteria = {
    dateFrom: undefined,
    dateTo: undefined,
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getSalesReportData(defaultCriteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getSalesReportData = async (criteria: IReportCriteria) => {
    try {
      setIsLoading(true);
      await reportService.getSalesReports(criteria).then((data) => {
        data?.datas?.sort((a: ISalesReport, b: ISalesReport) => (b.orderDate?.toString() ?? '').localeCompare(a.orderDate?.toString() ?? ''));
        setSalesReportData(data);
      });
    } catch (error) {
      console.error("An error occurred while getting the loss reports:", error);
    } finally {
      setIsLoading(false);
    }
  };




  return <SalesReportListView isLoading={isLoading} salesReportData={salesReportData ?? {} as ISalesReports}
    getSalesReportData={getSalesReportData}
  />;
});

export default SalesReportListController;
