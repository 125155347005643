import { FC, useEffect, useState } from "react";
import { Box, Button, createFilterOptions, TextField, Grid, ButtonProps, Breadcrumbs, Typography, Link, Card, CardContent, IconButton, Stack, Divider, Autocomplete, useMediaQuery, FormControl, Checkbox, FormControlLabel, RadioGroup, Radio, FormLabel, FormHelperText } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DialogConfirm from "../../../components/DialogConfirm";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { IExpense } from "../Services/ExpenseService";
import { IExpenseType } from "../Services/ExpenseTypeService";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SimpleBackdrop from "../../../components/SimpleBackdrop";
import { theme } from "../../../themes";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import styled from "@emotion/styled";
import { IUploadFile } from "../../Company/Services/CompanyService";
import ImagePreview from "../../../components/ImagePreview";
import { IUserDetail } from "../../Login/Services/LoginService";
import { entryTypeExpense } from "../../../constants/entryTypeExpense";
import ExpenseTypeCreateView from "./ExpenseTypeCreateView";

interface ExpenseDetailViewProps {
  data: IExpense;
  typeOptions: IExpenseType[];
  onSubmit: (data: IExpense, uploadFile: IUploadFile) => void;
  onUpdate: (data: IExpense, uploadFile: IUploadFile[], deleteFile: IUploadFile[]) => void;
  onDelete: (data: IExpense, deleteFile: IUploadFile[]) => void;
  id?: string;
  isLoading: boolean;
  permission: IUserDetail;
  // ประเภทค่าใช้จ่าย
  onCreateExpenseType: (data: IExpenseType) => void;
}

const ExpenseDetailView: FC<ExpenseDetailViewProps> = (props) => {
  const filterOptions = createFilterOptions<any>();
  const { register, handleSubmit, formState: { errors }, reset, getValues, control, watch, setError, setValue } = useForm<IExpense>({
    defaultValues: {
      docDate: new Date(),
      type: null,
      total: 0,
      details: [{ name: "", amount: 0, id: undefined }]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'details',
    keyName: "key"
  });
  const { id } = useParams();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });
  const navigate = useNavigate();
  const details = watch("details");
  const totalIncome = details?.filter((item: any) => item.entryType === entryTypeExpense.income.value).reduce((acc: number, item: any) => acc + (parseFloat(item.amount) || 0), 0) || 0;
  const totalExpense = details?.filter((item: any) => item.entryType === entryTypeExpense.expense.value).reduce((acc: number, item: any) => acc + (parseFloat(item.amount) || 0), 0) || 0;
  const totalSummary = totalIncome - totalExpense;
  const [removeDetails, setRemoveDetails] = useState<any[]>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any>([]);
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [openExpenseType, setOpenExpenseType] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    reset();
    if (id) {
      reset(props.data);
      setImages(props.data.image || []);
    }
  }, [id, props.data, reset]);

  const onDelete = () => {
    const data = getValues();
    props.onDelete({ ...data, isDelete: true }, selectedFiles);
    reset();
  };

  const onFormSubmit = (data: IExpense) => {
    data.total = totalSummary;
    if (id) {
      const _data = { ...data, details: [...(data?.details ?? []), ...removeDetails] };
      props.onUpdate(_data, selectedFiles, deleteImages);
    } else {
      props.onSubmit(data, selectedFiles);
    }
    reset();
  };

  watch();

  const onSubmit = (type: "create" | "delete") => {
    if (!dayjs(getValues().docDate).isValid()) {
      setError("docDate", {
        type: "",
        message: ""
      });
      return;
    }
    if (type === "delete") {
      setOpenConfirm({
        open: true,
        title: "ลบข้อมูล",
        message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
        color: "error",
        type: "delete",
      });
    } else {
      handleSubmit(() => {
        setOpenConfirm({
          open: true,
          title: "บันทึกข้อมูล",
          message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: "primary",
          type: "create",
        });
      })();
    }
  };

  const handleRemove = (index: number) => {
    remove(index);
    setRemoveDetails([...removeDetails, { ...fields[index], isDelete: true }]);
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles([...selectedFiles, ...Array.from(files)]);
    }
  };

  const handleFileRemove = (index: number) => {
    const _selectedFiles = [...selectedFiles];
    _selectedFiles.splice(index, 1);
    setSelectedFiles(_selectedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const _images = [...images];
    const index = _images.findIndex((img) => img.name === file.name);
    if (index > -1) {
      const deletedImage = _images[index];
      setDeleteImages([...deleteImages, { id: deletedImage.id, name: deletedImage.name }]);


      _images.splice(index, 1);
      setImages(_images);
    }
  };

  const onImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setOpenImage(true);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/expense-other")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ค่าใช้จ่ายอื่นๆ</Typography>
            <Link component="button" onClick={() => navigate("/expense-other")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              รายละเอียด
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {id && props.data && props.permission.delete && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onSubmit("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Box component="form" mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Controller
                      name="docDate"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker sx={{ width: "100%" }}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  error: !!errors.docDate,
                                  helperText: (errors.docDate) ? "กรุณาระบุวันที่เอกสาร" : "",
                                }
                              }}
                              defaultValue={dayjs()}
                              label="วันที่เอกสาร"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={4} >
                    <Controller
                      name="type"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          sx={{ mt: 1 }}
                          {...field}
                          value={field?.value as IExpenseType | null}
                          options={props.typeOptions || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value?.id}
                          onChange={(event, newValue) => {
                            if (newValue?.id === "create") {
                              setOpenExpenseType(true);
                            } else if (newValue?.id !== "create") {
                              field.onChange(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.type}
                            helperText={errors.type ? "กรุณาระบุประเภทค่าใช้จ่ายอื่นๆ" : ""}
                            {...params} label="ประเภทค่าใช้จ่ายอื่นๆ" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>รายการค่าใช้จ่าย</Typography>
                  </Grid>
                  {!isMobile && (<Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={0.5}
                        justifyContent={"center"}
                        alignItems={"center"}
                        display={"flex"}
                      ></Grid>
                      <Grid
                        item
                        xs={0.5}
                        justifyContent={"center"}
                        alignItems={"center"}
                        display={"flex"}
                      >
                        <Typography>ลำดับ</Typography>
                      </Grid>
                    </Grid>
                  </Grid>)}
                  {fields.map((item, index) => (
                    <Grid item xs={12} sm={12} md={12} lg={12} key={`full-grid-${item.id}`}>
                      <Grid container spacing={2} alignItems={"center"} key={`container-${item.id}`}>
                        {!isMobile && (<Grid item xs={1} sm={1} md={1} lg={1}>
                          <Grid container >
                            <Grid item xs={6} sm={6} md={6} lg={6} mb={1.5} textAlign={"center"}>
                              <IconButton
                                aria-label={`delete-${index}`}
                                size="small"
                                color="error"
                                onClick={() => {
                                  handleRemove(index);
                                }}
                              >
                                <RemoveCircleIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} mb={2} textAlign={"center"}>
                              <Typography>{index + 1}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>)}
                        {isMobile && (
                          <Grid item xs={12} sm={2} md={2} lg={2} key={`{order-${index}}`}>
                            <Typography>รายการที่ {index + 1}</Typography>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={10} md={10} lg={11}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={4} justifyContent={"center"} alignItems={"center"} display={"flex"}>
                              <Controller
                                name={`details.${index}.name`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <TextField
                                    {...register(`details.${index}.name`)}
                                    label="รายการ"
                                    variant="outlined"
                                    fullWidth
                                    value={field.value}
                                    onChange={field.onChange}
                                    error={!!errors.details?.[index]}
                                    helperText={errors.details?.[index] ? "กรุณาระบุรายการ" : ""}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={4} lg={3} justifyContent={"center"} alignItems={"center"} display={"flex"}>
                              <Controller
                                name={`details.${index}.entryType`}
                                control={control}
                                rules={{ required: 'กรุณาเลือกประเภท' }}
                                render={({ field, fieldState: { error } }) => (
                                  <FormControl component="fieldset" error={!!error}>
                                    <FormLabel component="legend">ประเภท</FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="entryType"
                                      name={`details.${index}.entryType`}
                                      value={field.value}
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                      }}
                                    >
                                      <FormControlLabel
                                        value="income"
                                        control={<Radio />}
                                        label="รายรับ"
                                      />
                                      <FormControlLabel
                                        value="expense"
                                        control={<Radio />}
                                        label="รายจ่าย"
                                      />
                                    </RadioGroup>
                                    {error && <FormHelperText>{error.message}</FormHelperText>}
                                  </FormControl>
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={4} lg={3} justifyContent={"center"} alignItems={"center"} display={"flex"}>
                              <Controller
                                name={`details.${index}.amount`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <TextField
                                    label="จำนวนเงิน"
                                    variant="outlined"
                                    fullWidth
                                    value={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);

                                    }}
                                    type="number"
                                    error={!!errors.details?.[index]}
                                    helperText={errors.details?.[index] ? "กรุณาระบุจำนวนเงิน" : ""}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} sm={4} md={4} lg={2} alignSelf={'center'}>
                              <FormControl fullWidth>
                                <Box
                                  display="flex"
                                  justifyContent={"flex-start"}
                                  alignItems="center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox color="primary" checked={watch(`details.${index}.isBill`)}
                                        onChange={(e) => {
                                          setValue(`details.${index}.isBill`, e.target.checked);
                                        }} />
                                    }
                                    label="บิล/บันทึก"
                                  />
                                </Box>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} justifyContent={"center"} alignItems={"center"} display={"flex"}>
                              <Controller
                                name={`details.${index}.remark`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    multiline
                                    {...register(`details.${index}.remark`)}
                                    label="หมายเหตุ"
                                    variant="outlined"
                                    fullWidth
                                    value={field.value}
                                    onChange={field.onChange}
                                  />
                                )}
                              />
                            </Grid>
                            {isMobile && <Grid item xs={12} sm={12} mt={2} md={12} lg={12} key={`divider-${item.id}`}>
                              <Divider />
                            </Grid>}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  {!isMobile ? (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={() => {
                            append({ name: "", amount: 0, isNew: true, id: undefined });
                          }}
                        >
                          เพิ่มรายการ
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                        <Divider />
                      </Grid>
                    </>
                  ) :
                    (
                      <>
                        <Grid item xs={4} sm={4} md={12} lg={12} >
                          <Button
                            variant="outlined"
                            color="error"
                            sx={{ width: '100%' }}
                            type="button"
                            startIcon={<RemoveCircleIcon />}
                            onClick={() => handleRemove(fields.length - 1)}
                          >
                            ลบ
                          </Button>
                        </Grid>
                        <Grid item xs={8} sm={8} md={12} lg={12}>
                          <Button
                            variant="outlined"
                            sx={{ width: '100%' }}
                            type="button"
                            startIcon={<AddCircleIcon />}
                            onClick={() => append({
                              name: "",
                              amount: 0,
                              isNew: true,
                              id: undefined
                            })}
                          >
                            เพิ่มรายการ
                          </Button>
                        </Grid>

                      </>
                    )
                  }
                  <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={7} md={7} lg={7}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography>เอกสาร</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                              เลือกไฟล์
                              <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png" multiple onChange={handleFileChange} />
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            {selectedFiles.length > 0 && (
                              <Box component={'div'} mt={1}>
                                {selectedFiles.map((file: any, index: number) => (
                                  <Grid item xs={12} sm={12} md={12} key={`sf-${index.toString()}`} mt={1}>
                                    <Grid container >
                                      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                        <IconButton size="small" color="error" onClick={() => handleFileRemove(index)}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      </Grid>
                                      <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                                        <img
                                          src={URL.createObjectURL(file)}
                                          alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                          style={{ maxWidth: "50%", height: "auto", cursor: "pointer" }}
                                          onClick={() => onImageClick(URL.createObjectURL(file))}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            {images && (
                              <Box component={'div'} mt={1}>
                                {images.map((file: any, index: number) => (
                                  <Grid item xs={12} sm={12} md={12} key={`im-${index.toString()}`} mt={1}>
                                    <Grid container >
                                      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'} key={`im-btn-${index.toString()}`}>
                                        <IconButton size="small"
                                          color="error" onClick={() => handleFileRemoveImageList(file)}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      </Grid>
                                      <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} key={`im-img-${index.toString()}`}>
                                        <img
                                          src={`${process.env.REACT_APP_API_URL}/uploads/${(file).name
                                            }`}
                                          alt="Selected"
                                          style={{ maxWidth: "50%", height: "auto", cursor: 'pointer' }}
                                          onClick={() => onImageClick(`${process.env.REACT_APP_API_URL}/uploads/${(file).name}`)}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={5} md={5} lg={5} >
                        <Grid container spacing={2}>
                          <Grid item xs={8} sm={8} md={8} lg={8}>
                            <Typography>จำนวนรายรับทั้งหมด</Typography>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                            <Typography>
                              {` ${parseFloat(
                                totalIncome.toString()
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 20,
                                minimumFractionDigits: 2,
                              })} บาท`}
                            </Typography>
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={8}>
                            <Typography>จำนวนรายจ่ายทั้งหมด</Typography>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                            <Typography>
                              {` ${parseFloat(
                                totalExpense.toString()
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 20,
                                minimumFractionDigits: 2,
                              })} บาท`}
                            </Typography>
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={8}>
                            <Typography>จำนวนคงเหลือรวมทั้งสิ้น</Typography>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                            <Typography>
                              {` ${parseFloat(
                                totalSummary.toString()
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 20,
                                minimumFractionDigits: 2,
                              })} บาท`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                          <Typography>
                            <b>ผู้ทํารายการ: </b>
                            {props.data?.worker?.firstName} {props.data?.worker?.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid container spacing={2}>
                      <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => navigate("/expense-other")}
                          >
                            ยกเลิก
                          </Button>
                          {
                            props.permission.create || (props.id && props.permission.edit) ?
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => onSubmit("create")}
                                style={{ marginLeft: '8px' }}
                              >
                                บันทึก
                              </Button>
                              : <></>}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogConfirm
                  open={openConfirm.open}
                  type={openConfirm.type === "delete" ? "error" : "success"}
                  title={openConfirm.title}
                  message={openConfirm.message}
                  onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                  onSubmit={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    if (openConfirm.type === "delete") {
                      onDelete();
                    } else {
                      handleSubmit(onFormSubmit)();
                    }
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={props.isLoading ?? false} />
      <ImagePreview
        open={openImage}
        imageSrc={selectedImage}
        onClose={() => setOpenImage(false)}
      />
      <ExpenseTypeCreateView
        open={openExpenseType}
        onClose={() => setOpenExpenseType(false)}
        data={{} as IExpenseType}
        isLoading={false}
        onSubmit={(data: IExpenseType) => {
          setOpenExpenseType(false);
          props.onCreateExpenseType(data);
        }}
        permission={props.permission}
        name={name}
      />
    </Box >
  );
};

export default ExpenseDetailView;
