import { FC } from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Layout from "./layout";
import DashboardController from "./features/Dashboards/Controllers/DashboardController";
import { observer } from "mobx-react-lite";
import LoginController from "./features/Login/Controllers/LoginController";
import loginService from "./features/Login/Services/LoginService";
import getRoleMenu from "./utils/getRoleMenu";
import { menus } from "./constants/menus";

const App: FC = observer(() => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const allMenu = user.isSuperAdmin === true ? menus : getRoleMenu(user.details, user.isSuperAdmin === true ? true : undefined); if (user.access_token) {
    loginService.setUserLoginedData(user);
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><DashboardController /></Layout>} />
        <Route path="/login" element={<LoginController />} />
        {allMenu.map((menu: any) =>
          menu.children?.length === 0 ? (
            (menu.read || user.isSuperAdmin) && <Route
              key={menu.path + 'route'}
              path={menu.path}
              element={
                <Layout>{menu.controller && <menu.controller />}</Layout>
              }
            />
          ) : (
            menu.children?.map((child: any, index: number) => (
              <Route
                key={`${menu.path}-${child.path}`}
                path={child.path}
                element={
                  <Layout>{child.controller && <child.controller />}</Layout>
                }
              />
            ))
          )
        )}
      </Routes>
    </Router>
  );
});

export default App;