import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import orderService, { IOrderWithoutRandom } from "../Services/OrderService";
import { useEffect, useState } from "react";
import productService from "../Services/ProductService";
import supplierService from "../../Supplier/Services/SupplierService";
import { enqueueSnackbar } from "notistack";
import clearState from "../../../utils/resetState";
import OrderCreateView from "../Views/OrderCreateView";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import loginService from "../../Login/Services/LoginService";
import gradeService from "../Services/GradeService";
import supplierTypeService from "../../Supplier/Services/SupplierTypeService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";

const OrderCreateController = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();

  useEffect(() => {
    if (id) {
      getOneOrder(id);
    }
    getProduct();
    fetchSupplier();
    getGrade();
    getSupplierType();
    getProvince();
    checkPermissionActionButton();
    return () => {
      clearState(orderService, ["order"]);
    };
  }, [id]);

  const getOneOrder = async (id: string) => {
    try {
      await orderService.getOne(id);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const getProduct = async () => {
    try {
      setIsLoading(true);
      await productService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCreate = async (data: IOrderWithoutRandom) => {
    try {
      const result = await orderService.create(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/process-order-edit/${result?.id}`);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("An error occurred while creating the product:", error);
    }
  };

  const onUpdate = async (data: IOrderWithoutRandom) => {
    try {
      await orderService.update(id ?? '', data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/process-order-edit/${id}`);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("An error occurred while updating the product:", error);
    }
  };

  const fetchSupplier = async () => {
    try {
      setIsLoading(true);
      await supplierService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the suppliers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      await orderService.delete(id ?? '');
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/process-order`);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("An error occurred while deleting the product:", error
      );
    }
  };

  const getGrade = async () => {
    try {
      setIsLoading(true);
      await orderService.getGrade();
    } catch (error) {
      console.error("An error occurred while getting the grade:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "supplier":
          response = await supplierService.create(data);
          fetchSupplier();
          break;
        case "supplierType":
          response = await supplierTypeService.create(data);
          getSupplierType();
          break;
        case "grade":
          response = await gradeService.create(data);
          getGrade();
          break;
        case "product":
          response = await productService.create(data);
          getProduct();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const onFetchData = async (id?: any, type?: string) => {
    try {
      switch (type) {
        case "district": await orderService.getDistrict(id);
          break;
        case "subDistrict": await orderService.getSubDistrict(id);
          break;
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const getSupplierType = async () => {
    try {
      await productService.getAllSupplierType();
    } catch (error) {
      console.error("An error occurred while getting the supplier type:", error);
    }
  };

  const getProvince = async () => {
    try {
      await orderService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-order");
    if (result) {
      setPermission(result);
    }
  };
  return < OrderCreateView
    Order={id ? orderService.orderData ?? {} as IOrderWithoutRandom : {} as IOrderWithoutRandom}
    suppliers={supplierService.supplierListData ?? []}
    onCreate={onCreate}
    onUpdate={onUpdate}
    onDelete={onDelete}
    isLoading={isLoading}
    productSelects={productService.selectProduct ?? []}
    grades={orderService.gradesData ?? []}
    user={loginService.userLoginedData ?? {} as IUserDetail}
    onCreateDialog={onCreateDialog}
    onFetchData={onFetchData}
    provinceData={orderService.provinceData ?? []}
    districtData={orderService.districtData ?? []}
    subDistrictData={orderService.subDistrictData ?? []}
    supplierTypesOptions={productService.supplierTypeListData ?? []}
    permission={permission ?? {} as IUserDetail}
  />;
});

export default OrderCreateController;