import { IShippingTypeWithValue } from './ShippingTypeService';
import { IUser } from "../../UserManagement/Services/RoleService";
import { IRefOrder } from "./OrderService";
import { configure, makeAutoObservable } from 'mobx';
import apiService from '../../../services/ApiService';
import { IShowImages } from "../../Inventory/Services/ReceiveService";
import { IExpense } from "../../Expense/Services/ExpenseService";
import { IMaterial } from "../../Inventory/Services/MaterialsService";

export interface IShipping {
  id?: string;
  docNo?: string;
  isDelete?: boolean;
  order?: IRefOrder | undefined;
  status?: string;
  remark?: string;
  createAt?: Date;
  updateAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  details?: IShippingDetail[] | undefined;
  nonVat?: number;
  vat?: number;
  vat7?: number;
  vat3?: number;
  totalAmount?: number;
  costPerBox?: number;
  type?: IShippingType | undefined;
  totalBox?: number;
  images?: IShowImages[] | undefined;
  isFailed?: boolean;
  isSuccess?: boolean;
  expenses?: IExpense[] | undefined;
}

export interface IShippingDetail {
  id?: string;
  etdDate: Date;
  etaDate: Date;
  quantity?: number;
  packaging?: IPackagingExpense | undefined;
  shipping?: IShippingForDetail | undefined;
}

export interface IShippingForDetail {
  id?: string;
  docNo?: string;
  isDelete?: boolean;
  order?: IRefOrder | undefined;
  status?: string;
  remark?: string;
  createAt?: Date;
  updateAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  nonVat?: number;
  vat?: number;
  vat7?: number;
  vat3?: number;
  totalAmount?: number;
  costPerBox?: number;
  type?: IShippingType | undefined;
  totalBox?: number;
  images?: IShowImages[] | undefined;
  isFailed?: boolean;
  isSuccess?: boolean;
  // expense?: IExpense | undefined;
}

export interface IPackagingExpense {
  details?: IPackagingDetailExpense[] | undefined;
  showTooltip?: boolean;
  refDoc?: string;
  oldQty?: number;
  oldCapacity?: number;
  weightBake?: number;
  weightPerBox?: number;
  totalBox?: number;
  totalExport?: number;
  totalLoss?: number;
  mainMaterial?: IMaterial | null;
  remark?: string;
  sutiTotalBox?: number;
  docNo?: string | undefined;
  status?: string;
  isDelete?: boolean | undefined;
  createAt?: Date;
  updateAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  id?: string;

  image?: IShowImages[] | undefined;
}

export interface IPackagingDetailExpense {
  id?: string;
  quantity: number;
  package?: string | undefined;
  capacityValue?: number;
  showTooltip?: boolean;
  refDoc?: string;
  oldQty?: number;
  oldCapacity?: number;
  weightBake?: number;
  weightPerBox?: number;
  totalBox?: number;
  totalExport?: number;
  remark?: string;
  sutiTotalBox?: number;
  status?: string;
  netWeightFrom?: number;
  netWeightTo?: number;
  grossWeightFrom?: number;
  grossWeightTo?: number;
  group?: IGroupingExpense | null;

}

export interface IGroupingExpense {
  id?: string;
  mainMaterial?: IMaterial | null;
  name?: string;
}

export interface IShippingList {
  datas?: IShipping[] | undefined;
  all: number;
  page: number;
  limit: number;
  total: number;
}

export interface IShippingCriteria {
  dateFrom?: Date;
  dateTo?: Date;
  page: number;
  limit: number;
  status: string;
  docNo: string;
}

export interface IShippingType {
  id?: string;
  name: string;
}


class ShippingService {
  shipping: IShipping = {} as IShipping;
  shippings: IShippingList = {} as IShippingList;
  packagings: IPackagingExpense[] = [];
  orders: IRefOrder[] | undefined;
  shippingTypes: IShippingTypeWithValue[] = [];
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }
  async getOne(id: string): Promise<IShipping> {
    try {
      this.shipping = await apiService.get(`/shippings/${id}`);
      return this.shipping;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IShippingCriteria): Promise<IShippingList> {
    try {
      this.shippings = await apiService.post("shippings/criteria", criteria);
      return this.shippings;
    } catch (error) {
      throw error;
    }
  }

  async create(shipping: IShipping, uploadFile: any, uploadFileExpense: any): Promise<IShipping> {
    try {
      const result = await apiService.post("shippings", shipping);
      if (uploadFile && uploadFile?.length > 0) {
        for (const file of uploadFile) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", result.id || "");
          formData.append("refType", "shipping");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      if (uploadFileExpense && uploadFileExpense?.length > 0) {
        for (const file of uploadFileExpense) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", result.expense || "");
          formData.append("refType", "expense");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      return result;
    } catch (error) {
      throw error;
    }
  }

  async update(shipping: IShipping, uploadFile: any, uploadFileExpense: any): Promise<IShipping> {
    try {
      const result = await apiService.put(`shippings/${shipping.id}`, shipping);
      if (uploadFile && uploadFile?.length > 0 && shipping.id) {
        for (const file of uploadFile) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", shipping.id);
          formData.append("refType", "shipping");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      if (uploadFileExpense && uploadFileExpense?.length > 0) {
        for (const file of uploadFileExpense) {
          const formData = new FormData();
          formData.append("file", file);
          // formData.append("refId", shipping.expense?.id || "");
          formData.append("refType", "expense");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      return result;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string): Promise<IShipping> {
    try {
      const result = await apiService.delete(`shippings/${id}`);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getAllOrders() {
    try {
      this.orders = await apiService.get("orders");
      return this.orders;
    } catch (error) {
      throw error;
    }
  }

  async getPackagings(orderId: string) {
    try {
      this.packagings = await apiService.get(`packagings/order/${orderId}`);
      return this.packagings;
    } catch (error) {
      throw error;
    }
  }

  async getShippingTypes() {
    try {
      this.shippingTypes = await apiService.get("shipping-types");
      return this.shippingTypes;
    } catch (error) {
      throw error;
    }
  }

  get shippingData() {
    return this.shipping;
  }

  get shippingsData() {
    return this.shippings;
  }

  get ordersData() {
    return this.orders;
  }

  get packagingsData() {
    return this.packagings;
  }

  get shippingTypesData() {
    return this.shippingTypes;
  }
}

const shippingService = new ShippingService();
export default shippingService;
