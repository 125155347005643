import { FC, useState } from "react";
import { ICapacity, ICapacityCriteria, ICapacityListView } from "../Services/CapacityService";
import { Box, Breadcrumbs, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import CapacityCreateView from "./CapacityCreateView";

interface CapacitiesListViewProps {
  onSubmit: (data: ICapacity) => void;
  onSerchByCriteria: (criteria: ICapacityCriteria) => Promise<void>;
  data: ICapacityListView;
  isLoading: boolean;
  permission: IUserDetail;
}
const CapacitiesListView: FC<CapacitiesListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");
  const [data, setData] = useState<ICapacity>();
  const [openCreate, setOpenCreate] = useState(false);

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    props.onSerchByCriteria({ name: name, page: pageNumber, limit: rowLimit ? rowLimit : 10 });
  };

  const columns = [
    { label: 'ชื่อ', render: (row: ICapacity) => row.name },
    { label: 'จำนวนบบรรจุ', render: (row: ICapacity) => row.value },
    { label: 'รายละเอียด', render: (row: ICapacity) => row.description },
    {
      label: 'บันทึกล่าสุด', render: (row: ICapacity) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.createBy?.firstName || ''} {row.createBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? '' : dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: ICapacity) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.updateBy?.firstName || ''} {row.updateBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? '' : dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
        </Box>
      )
    },
  ];

  const actions = (row: ICapacity) => (
    <IconButton
      onClick={() => {
        setData(row);
        setOpenCreate(true);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        setOpenCreate(true);
      }}>
      สร้าง
    </Button>
  ) : <></>;

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>จำนวนบบรรจุ</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Box component="form">
            <Grid container spacing={2} alignItems={"end"}>
              <Grid item xs={12} sm={10} md={10} lg={10} >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'flex-end'}>
                    <TextField
                      label="ชื่อ"
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"end"}>
                <Button
                  onClick={() => searchByCriteria()}
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      }
      table={
        <>
          <TableDataList
            data={props.data.datas || []}
            total={props.data.total}
            columns={columns}
            actionButtons={actionButtons}
            rowActions={actions}
            page={page}
            limit={limit}
            onPageChange={(newPage) => {
              searchByCriteria(newPage);
            }}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0);
              searchByCriteria(0, newLimit);
            }}
          />
          <CapacityCreateView
            data={data ?? {} as ICapacity}
            open={openCreate}
            onClose={() => {
              setOpenCreate(false);
              setData(undefined);
            }}
            onSubmit={props.onSubmit}
            permission={props.permission}
          />
        </>
      }
      isLoading={props.isLoading}
    />
  );
};

export default CapacitiesListView;
