import React, { FC, ReactNode, useEffect, useState } from "react";
import {
  Toolbar, Typography, Drawer, List, ListItemText, IconButton, Menu, MenuItem, ListItemButton, styled, Box, CssBaseline, Divider, ListItemIcon, Collapse,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import APP_LOGO from "../res/images/app_logo.png";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { theme } from "../themes";
import { menus } from "../constants/menus";
import getRoleMenu from "../utils/getRoleMenu";
import loginService from "../features/Login/Services/LoginService";
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';


interface LayoutProps {
  children: ReactNode;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Layout: FC<LayoutProps> = observer(({ children }) => {
  const userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") ?? "")
    : "";
  const [appVersion, setAppVersion] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isCollapse, setIsCollapse] = useState(false);
  const [menuName, setMenuName] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const allMenu = userData.isSuperAdmin ? menus : getRoleMenu(userData.details, userData.isSuperAdmin === true ? true : undefined);
  useEffect(() => {
    setIsDrawerOpen(!isMobile);
  }, [isMobile]);


  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  //TODO - Implement logout
  const handleLogout = () => {
    navigate("/login");
    loginService.logout();
  };

  const profileMenuOpen = Boolean(anchorEl);

  const handleMeneClick = (path: string, isChild: boolean = false, isNav: boolean = false) => {
    if (!isChild) {
      if (menuName === path) {
        setIsCollapse(!isCollapse);
      } else if (path === menuName.split("-")[0]) {
        setIsCollapse(!isCollapse);
        setMenuName(path);
      } else {
        setIsCollapse(true);
      }
    }
    if (isNav) {
      navigate(path);
      setIsDrawerOpen(!isMobile);
    }
    setMenuName(path);
  };

  useEffect(() => {
    setAppVersion(process.env.REACT_APP_VERSION || "");
    localStorage.setItem("menus", JSON.stringify(allMenu));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        open={isDrawerOpen}
        position="fixed"
        elevation={1}
        sx={{ bgcolor: "white" }}
      >
        <Toolbar>
          <IconButton
            key={"open drawer"}
            aria-label="open drawer"
            onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" color={"black"} style={{ flexGrow: 1 }}>
            {userData && userData.company ? userData.company.name : ""}
          </Typography>
          <IconButton
            key={"userData"}
            onClick={handleProfileMenuOpen} sx={{ color: "black" }}>
            <AccountCircleIcon />
            <Typography style={{ marginLeft: "8px" }}>
              {userData ? `${userData?.firstName} ${userData?.lastName}` : ""}
            </Typography>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={profileMenuOpen}
            onClose={handleProfileMenuClose}
          >
            <MenuItem
              key={"user-role-detail"}
              onClick={() => {
                navigate("/user-role-detail/" + userData?.id);
                handleProfileMenuClose();
              }}
            >
              โปรไฟล์
            </MenuItem>
            <MenuItem key={"logout"} onClick={handleLogout}>ออกจากระบบ</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={isDrawerOpen}
      >
        <DrawerHeader sx={{ justifyContent: "center" }}>
          <Box component={"img"} src={APP_LOGO} sx={{ width: "70%" }} />
        </DrawerHeader>
        <Divider />
        {allMenu.map((menu) => (
          (menu.read || userData.isSuperAdmin) && <List key={`${menu.name}-list`}>
            <ListItemButton
              key={`${menu.name}-list-item`}
              onClick={() => {
                handleMeneClick(menu.path, false, menu.children?.length === 0 ? true : false);
              }}
              selected={menuName === menu.value}
            >
              <ListItemIcon>
                <menu.icon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary={menu.name} />
              {menu.children && menu.children.length > 0 ? (
                (isCollapse === true && menuName.split("-")[0] === menu.path) || window.location.pathname.split("-")[0] === menu.path ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </ListItemButton>
            {menu.children && menu.children.length > 0 && (
              <Collapse
                in={(menuName.split("-")[0] === menu.path && isCollapse) || window.location.pathname.split("-")[0] === menu.path}
                timeout="auto"
                unmountOnExit
              >
                <List
                  key={`${menu.name}-list-child`}
                  component={"div"} disablePadding>
                  {
                    menu.children?.map((child, index) => (
                      child.name && (child.read || userData.isSuperAdmin) &&
                      <ListItemButton
                        key={child.name ?? '' + index}
                        sx={{ pl: 4 }}
                        onClick={() => {
                          handleMeneClick(child.path, true, true);
                        }}
                        selected={menuName === child.path || window.location.pathname === child.path}
                      >
                        <ListItemIcon>
                          <ArrowRightIcon
                            fontSize="small"
                            sx={{ color: "black" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={child.name} />
                      </ListItemButton>
                    ))}
                </List>
              </Collapse>
            )}
          </List>
        ))}
        <Box sx={{ flexGrow: 1 }} />
        <List dense>
          <Box component={"div"}>
            <Typography variant="caption" sx={{ p: 2.5, }}>
              Supports
            </Typography>
          </Box>
          <ListItemButton onClick={() => { window.open(`${process.env.PUBLIC_URL}/assets/WSMS_manual.pdf`, '_blank'); }} >
            <ListItemIcon>
              <ArticleIcon sx={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="คู่มือการใช้งาน" />
          </ListItemButton>
          <ListItemButton onClick={() => { window.open('https://lin.ee/66yfWAV'); }} >
            <ListItemIcon><HelpIcon sx={{ color: 'black' }} /></ListItemIcon>
            <ListItemText primary="แจ้งปัญหาการใช้งาน" />
          </ListItemButton>
        </List>
        <Typography variant="caption" color={"black"} textAlign={"center"}>
          v {appVersion}
        </Typography>
      </Drawer>
      <Main open={isDrawerOpen}>
        <DrawerHeader />
        <div style={{ padding: "16px" }}>{children}</div>
      </Main>
    </Box>
  );
});

export default Layout;