import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import dayjs from "dayjs";
import bakeService, { IBakeCriteria, IBakeListView } from "../Services/BakeService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import BakeListView from "../Views/BakeListView";

const BakeListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [bakeData, setBakeData] = useState<IBakeListView>();

  const defaultCriteria: IBakeCriteria = {
    dateFrom: dayjs().startOf('month').toDate(),
    dateTo: dayjs().toDate(),
    docNo: '',
    status: '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const fetchByCriteria = async (criteria: IBakeCriteria) => {
    try {
      setIsLoading(true);
      await bakeService.getByCriteria(criteria).then((data) => {
        data?.datas?.sort((a: any, b: any) => (b.createAt?.toString() ?? '').localeCompare(a.createAt?.toString() ?? ''));
        setBakeData(data);
      }
      );
    } catch (error) {
      console.error("An error occurred while getting the bake:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-bake");
    if (result) {
      setPermission(result);
    }
  };

  return (
    <BakeListView
      isLoading={isLoading}
      bakeList={bakeData ?? {} as IBakeListView}
      onSearchByCriteria={fetchByCriteria}
      permission={permission ?? {} as IUserDetail}
    />
  );
});

export default BakeListController;