import { configure, makeAutoObservable } from "mobx";
import { IUserLogined } from "../../Login/Services/LoginService";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import apiService from "../../../services/ApiService";
import { IWarehouse } from "../../Inventory/Services/WareshousesService";
import { ISupplierType } from "../../Supplier/Services/SupplierTypeService";

export interface IProduct {
  id?: string;
  name?: string;
  supplier?: ISupplier | undefined;
  remark?: string;
  isDelete?: boolean;
  createdBy?: IUserLogined | undefined;
  updatedBy?: IUserLogined | undefined;
  createdAt?: string;
  updatedAt?: string;
}

export interface IProductCriteria {
  name: string;
  supplier: string;
  page: number;
  limit: number;
}

export interface IProductListView {
  datas?: IProduct[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IproductSelect {
  id: string;
  name: string;
}

export interface IProvince {
  id?: number;
  nameTh?: string;
}

export interface IDistrict {
  id?: number;
  nameTh?: string;
  province?: IProvince | null;
}

export interface ISubDistrict {
  id?: number;
  nameTh?: string;
  district?: IDistrict | null;
  zipCode?: string;
}

class ProductService {
  products: IProductListView | undefined;
  product: IProduct | undefined;
  warehouse: IWarehouse[] = [];
  selectProducts: IproductSelect[] = [];
  province: IProvince[] = [];
  district: IDistrict[] = [];
  subDistrict: ISubDistrict[] = [];
  supplierTypeList: ISupplierType[] | undefined;
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const data = await apiService.get("products");
      this.products = data;
      this.selectProducts = data;
      return this.products;

    } catch (error) {
      throw error;
    }
  }

  async getAllWarehouse() {
    try {
      this.warehouse = await apiService.get("warehouses");
      return this.warehouse;

    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      const product = await apiService.get(`products/${id}`);
      this.product = product;

      return product;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IProductCriteria) {
    try {
      const products = await apiService.post("products/criteria", criteria);
      this.products = products;
      return products;
    } catch (error) {
      throw error;
    }
  }

  async getProductWithSupplier() {
    try {
      const products = await apiService.get("products/product-supplier/all");
      this.selectProducts = products;
      return products;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IProduct) {
    try {
      const product = await apiService.post("products", data);
      return product;
    } catch (error) {
      throw error;
    }
  }

  async update(data: IProduct) {
    try {
      const product = await apiService.put(`/products/${data.id}`, data);
      return product;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const product = await apiService.delete(`products/${id}`);
      return product;
    } catch (error) {
      throw error;
    }
  }

  async getProvince() {
    try {
      this.province = await apiService.get(`/provinces`);
      return this.province;
    } catch (error) {
      throw error;
    }
  }

  async getDistrict(id: number) {
    try {
      this.district = await apiService.get(`/districts?provinceId=${id}`);
      return this.district;
    } catch (error) {
      throw error;
    }
  }

  async getSubDistrict(id: number) {
    try {
      this.subDistrict = await apiService.get(
        `/sub-districts?districtId=${id}`
      );
      return this.subDistrict;
    } catch (error) {
      throw error;
    }
  }

  async getAllSupplierType() {
    try {
      const supplierTypes = await apiService.get("supplier/type/all");
      this.supplierTypeList = supplierTypes;
      return this.supplierTypeList;
    } catch (error) {
      throw error;
    }
  }

  get productData() {
    return this.product;
  }

  get productListData() {
    return this.products;
  }

  get selectProduct() {
    return this.selectProducts;
  }

  get warehouseData() {
    return this.warehouse;
  }

  get provinceData() {
    return this.province;
  }

  get districtData() {
    return this.district;
  }

  get subDistrictData() {
    return this.subDistrict;
  }

  get supplierTypeListData() {
    return this.supplierTypeList;
  }

}
const productService = new ProductService();
export default productService;
