import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";
import shippingService, { IShippingCriteria, IShippingList } from "../Services/ShippingService";
import ShippingsListView from "../Views/ShippingsListView";
import dayjs from "dayjs";

const ShippingListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [datas, setData] = useState<IShippingList>();
  const defaultCriteria: IShippingCriteria = {
    dateFrom: dayjs().startOf('month').toDate(),
    dateTo: dayjs().toDate(),
    docNo: '',
    status: 'all',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchByCriteria(defaultCriteria);
    checkPermissionActionButton();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const fetchByCriteria = async (criteria: IShippingCriteria) => {
    try {
      setIsLoading(true);
      await shippingService.getByCriteria(criteria).then((data) => {
        data?.datas?.sort((a: any, b: any) => (b.createdAt?.toString() ?? '').localeCompare(a.createdAt?.toString() ?? ''));
        setData(data);
      }
      );
    } catch (error) {
      console.error("An error occurred while getting the shippings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-shipping");
    if (result) {
      setPermission(result);
    }
  };

  return <ShippingsListView isLoading={isLoading} datas={datas ?? {} as IShippingList} onSearchByCriteria={fetchByCriteria} permission={permission ?? {} as IUserDetail} />;
});

export default ShippingListController;