import { FC, useState } from "react";
import { Breadcrumbs, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import MaterialsCreateView from "./MaterialCreateView";
import { IMaterial, IMaterialCriteria, IMaterialView } from "../Services/MaterialsService";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
import { IUserDetail } from "../../Login/Services/LoginService";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";

interface MaterialsListViewProps {
  isLoading: boolean;
  materials: IMaterialView;
  onCreate: (data: IMaterial) => Promise<void>;
  onSerchByCriteria: (criteria: IMaterialCriteria) => Promise<void>;
  permission: IUserDetail;
}

const MaterialsListView: FC<MaterialsListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IMaterialCriteria = {
      name: name,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };

    props.onSerchByCriteria(criteria);
  };

  const materialsColumns = [
    { label: 'รายการ', render: (row: IMaterial) => row.name },
    { label: 'จำนวน', render: (row: IMaterial) => row.qty ? thaiFormatNumber(row.qty.toString() || '0') : '0.00' },
    { label: 'ราคา', render: (row: IMaterial) => row.price ? thaiFormatNumber(row.price.toString() || '0') : '0.00' },
  ];

  const materialActions = (row: IMaterial) => (
    <IconButton
      onClick={() =>
        navigate(`/inventory-material-detail/${row.id}`)
      }
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionsButton = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={handleOpenDialog}>
      สร้าง
    </Button>
  ) : <></>;


  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>การจัดการวัสดุ</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <TextField fullWidth label="ชื่อรายการ" onChange={(e) => setName(e.target.value)} />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"center"}>
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={() => searchByCriteria(0)}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </>
      }
      table={
        <>
          <TableDataList
            data={props.materials.datas}
            total={props.materials.total}
            columns={materialsColumns}
            actionButtons={actionsButton}
            rowActions={materialActions}
            page={page}
            limit={limit}
            onPageChange={(newPage) => {
              setPage(newPage);
              searchByCriteria(newPage, limit);
            }}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0);
              searchByCriteria(0, newLimit);
            }}
          />
        </>
      }
      dialog={
        <>
          <MaterialsCreateView open={open} onClose={handleCloseDialog} onCreate={props.onCreate} />
        </>
      }
      isLoading={props.isLoading ?? false}
    />
  );
};

export default MaterialsListView;
