import { FC, useState } from "react";
import { IGrouping, IGroupingCriteria, IGroupingView } from "../Services/GroupingService";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListViewPage from "../../../components/ListViewPage";
import SearchIcon from "@mui/icons-material/Search";
import TableDataList from "../../../components/TableDataList";
import { IUserDetail } from "../../Login/Services/LoginService";

interface GroupingsListViewProps {
  groupings: IGroupingView;
  isLoading: boolean;
  onSearchByCriteria: (criteria: IGroupingCriteria) => void;
  permission: IUserDetail;
}

const GroupingsListView: FC<GroupingsListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");

  const navigate = useNavigate();

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IGroupingCriteria = {
      mainMaterialGrouping: name,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };

    props.onSearchByCriteria(criteria);
  };

  const groupingsColumns = [
    { label: 'วัสดุหลัก', render: (row: IGrouping) => row.mainMaterial?.name || '' },
    { label: 'พนักงาน', render: (row: IGrouping) => `${row.createBy?.firstName || ''} ${row.createBy?.lastName || ''}` },
  ];

  const groupingActions = (row: IGrouping) => (
    <IconButton
      onClick={() => {
        navigate(`/inventory-grouping-create/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionsButton = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => navigate("/inventory-grouping-create")}>
      สร้าง
    </Button>
  ) : <></>;
  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>กลุ่มวัสดุ</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }

      criteria={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <TextField fullWidth label="วัสดุหลัก" onChange={(e) => setName(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"center"}>
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={() => searchByCriteria(0)}>ค้นหา
              </Button>
            </Grid>
          </Grid>
        </>
      }

      table={
        <>
          <TableDataList
            data={props.groupings.datas}
            total={props.groupings.total}
            columns={groupingsColumns}
            actionButtons={actionsButton}
            rowActions={groupingActions}
            page={page}
            limit={limit}
            onPageChange={setPage}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0);
              searchByCriteria(0, newLimit);
            }}
          /></>
      }

      isLoading={props.isLoading ?? false}
    />
  );
};

export default GroupingsListView;