import { Autocomplete, Box, Button, ButtonProps, Checkbox, Stack, Divider, Card, FormControlLabel, Grid, styled, TextField, Tooltip, Typography, useMediaQuery, CardContent, IconButton, Breadcrumbs, Link, MenuItem, FormControl, InputLabel, Select, FormHelperText, createFilterOptions } from "@mui/material";
import { FC, useState, useEffect, Fragment, } from "react";
import { useParams } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import DialogConfirm from "../../../components/DialogConfirm";
import { IGrouping, IGroupingDetail, } from "../../Inventory/Services/GroupingService";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { theme } from "../../../themes";
import { IDistrict, IProvince, ISubDistrict, IUploadFile } from "../../Company/Services/CompanyService";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ImagePreview from "../../../components/ImagePreview";
import { v4 as uuidv4 } from "uuid";
import { IRefOrder, } from "../Services/OrderService";
import { IBake, IGrade, IPackaging, IPackagingDetail, IRandomInspection } from "../Services/PackagingService";
import { IMaterial } from "../../Inventory/Services/MaterialsService";
import { useNavigate } from "react-router-dom";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { IWarehouse } from "../../Inventory/Services/WareshousesService";
import { useLocation } from "react-router-dom";
import { IUserLogined } from "../../Login/Services/LoginService";
import SimpleBackdrop from "../../../components/SimpleBackdrop";
import { ICapacity } from "../Services/CapacityService";
import { warehouseDatas } from "../../../constants/dialogCreateInputs";
import DialogCreate from "../../../components/DialogCreate";
import DialogCreateGroup from "../../../components/DialogCreateGroup";
import GradeCreateView from "./GradeCreateView";
import CapacityCreateView from "./CapacityCreateView";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
interface PackagingCreateViewProps {
  order: IRefOrder[];
  onCreate: (data: IPackaging, uploadFile: IUploadFile) => void;
  onUpdate: (data: IPackaging, oldImage: IUploadFile[], fileUpload: IUploadFile[], deleteFile: IUploadFile[]) => void;
  onClose: () => void;
  groupings: IGrouping[];
  weightBake?: number;
  onFetchBake: (id: string) => void;
  onFetchRandom: (id: string) => void;
  bake: IBake[];
  random: IRandomInspection[];
  permission: IUserDetail;
  packaging: IPackaging;
  mainMaterial: IMaterial[];
  onFetchGrouping: (warehouse: string, packaging?: string) => void;
  grades: IGrade[];
  warehouses: IWarehouse[];
  user: IUserLogined;
  isLoading: boolean;
  capacities: ICapacity[];
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onFetchDistrict: (id: number | undefined) => void;
  onFetchSubDistrict: (id: number | undefined) => void;
  onCreateDialog: (data: any, type: string) => void;
  onFetchData: (id?: any, type?: string) => void;
  // กลุ่มวัสดุ
  onCreateMaterialGroup: (warehouse: string, data: IGrouping) => void;
  mainMaterialsData?: IMaterial[];
  subMaterialsData?: IMaterial[];
}
interface IAccumulators {
  sutiTotalKg: number;
  sutiTotalBox: number;
  exportTotalKg: number;
  exportTotalBox: number;
  sutiWeightLossKg: number;
  sutiWeightLossBox: number;
}

interface IGroupingDetailWithQuantity extends Omit<IGroupingDetail, "_quantity"> {
  _quantity?: number;
}

interface calCostPerBoxProps {
  _index?: number;
  _exportBox?: number;
  _deleteIndex?: number;
  _weightExport?: number;
  _capacityValue?: number;
  _id?: string;
  _weightPerBox?: number;
  _isWarehouse?: boolean;
}
const PackagingCreateView: FC<PackagingCreateViewProps> = (props) => {
  const defaultValues: IPackagingDetail = {
    id: uuidv4(),
    group: null,
    quantity: 0,
    package: null,
    capacity: null,
    capacityValue: 0,
    showTooltip: false,
    mainMaterial: null,
    weightBake: 0,
    totalExport: 0,
    totalBox: 0,
    weightPerBox: 0,
    remark: "",
    isBake: true,
    bake: null,
    random: null,
    grade: null,
    netWeightFrom: 0,
    netWeightTo: 0,
    grossWeightFrom: 0,
    grossWeightTo: 0,
    warehouse: null,
  };
  const defaultValuesPackaging = {
    id: uuidv4(),
    order: null,
  };
  const filterOptions = createFilterOptions<any>();
  const { register, setValue, formState: { errors }, getValues, control, watch, setError, clearErrors, reset, handleSubmit } = useForm<IPackaging>({
    defaultValues: {
      ...defaultValuesPackaging,
      details: [defaultValues]
    }
  });
  const navigate = useNavigate();
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "details",
  });

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete" | "approved",
  });
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any>([]);
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const details = watch("details");
  const mainMaterial = watch("mainMaterial");
  const [sutiTotalBox, setSutiTotalBox] = useState(0);
  const [sutiTotalKg, setSutiTotalKg] = useState(0);
  const [sutiTotalExportBox, setSutiTotalExportBox] = useState(0);
  const [sutiTotalExportKg, setSutiTotalExportKg] = useState(0);
  const [sutiWeightLossBox, setSutiWeightLossBox] = useState(0);
  const [sutiWeightLossKg, setSutiWeightLossKg] = useState(0);
  const [remainList, setRemainList] = useState([{
    name: "",
    remain: 0,
  }]);
  const [groupOptions, setGroupOptions] = useState<{ [key: number]: IGrouping[]; }>({});
  const [indexRow, setIndexRow] = useState<number | null>(null);
  const order = watch("order");
  const { id } = useParams();
  const location = useLocation();
  const { from } = location.state || { from: null };
  const isFromStatus = from === "status" || from === "order";
  const isDisabled = isFromStatus ? true : false;
  const userGroup = props.user?.userGroup;
  const [showIndex, setShowIndex] = useState<number | null>(null);
  const handleFileChange = (_: React.ChangeEvent<HTMLInputElement>) => {
    const files = _.target.files;
    if (files) {
      setSelectedFiles([...selectedFiles, ...Array.from(files)]);
    }
  };
  const [nameType, setNameType] = useState("");
  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });
  const [warehouse, setWarehouse] = useState('');
  const [name, setName] = useState('');
  const _warehouseDatas: any = warehouseDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    name: name,
  }) || [];


  const handleFileRemove = (index: number) => {
    const _selectedFiles = [...selectedFiles];
    _selectedFiles.splice(index, 1);
    setSelectedFiles(_selectedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const _images = [...images];
    const index = _images.findIndex((img) => img.name === file.name);
    if (index > -1) {
      const deletedImage = _images[index];
      setDeleteImages([...deleteImages, { id: deletedImage.id, name: deletedImage.name }]);
      _images.splice(index, 1);
      setImages(_images);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const onImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setOpenImage(true);
  };

  const onCreate = () => {
    const data = getValues();
    if (id) {
      props.onUpdate({ ...data, totalWeight: sutiTotalKg, totalWeightExport: sutiTotalExportKg }, images, selectedFiles, deleteImages);
    } else { props.onCreate({ ...data, totalWeight: sutiTotalKg, totalWeightExport: sutiTotalExportKg }, selectedFiles); }
    reset();
    setSelectedFiles([]);
    setImages([]);
    setDeleteImages([]);
  };

  const onUpdate = () => {
    const data = getValues();
    props.onUpdate({ ...data, totalWeight: sutiTotalKg, totalWeightExport: sutiTotalExportKg }, images, selectedFiles, deleteImages);
    setSelectedFiles([]);
    setImages([]);
    setDeleteImages([]);
  };

  const onDelete = () => {
    if (id) {
      props.onUpdate({ isDelete: true, id: id }, images, selectedFiles, deleteImages);
    }
    setSelectedFiles([]);
    setImages([]);
    setDeleteImages([]);
  };

  const onSubmit = handleSubmit(() => {
    let isValidate = false;
    const status = getValues("status") || "";
    if (errors.details) {
      isValidate = true;
      return;
    }
    if (handleCheckGroup()) {
      isValidate = true;
      return;
    }
    // COMMENT : ถ้ามีปัญหาในส่วน validate ไอเทมคงเหลือในคลังใฟ้คอมเม้นออกก่อน
    if (remainList && remainList.length > 0) {
      remainList.forEach((remain, index) => {
        if (remain.remain < 0) {
          setError(`details.${index}.group`, { type: "manual", message: `${remain.name} ไม่เพียงพอ` });
          isValidate = true;
        } else {
          clearErrors(`details.${index}.group`);
          isValidate = false;

        }
      });
    }
    if (!isValidate) {
      if (status === "approved" || status === "rejected") {
        setOpenConfirm({
          open: true,
          title: status === "approved" ? "อนุมัติเอกสาร" : "ปฏิเสธเอกสาร",
          message: status === "approved" ? "คุณต้องการอนุมัติข้อมูลนี้ใช่หรือไม่" : "คุณต้องการปฏิเสธข้อมูลนี้ใช่หรือไม่",
          color: status === "approved" ? "primary" : "error",
          type: "approved",
        });
      }
      else {
        setOpenConfirm({
          open: true,
          title: "บันทึกข้อมูล",
          message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: "primary",
          type: "create",
        });
      }
    }
  });

  const handleCheckGroup = () => {
    return details?.find((detail) => !detail?.group?.details);
  };

  const calGrouping = ({
    deleteIndex,
    _capacityValue,
    _id,
    _isBake,
    _isBakeIndex
  }: {
    deleteIndex?: number;
    _capacityValue?: number;
    _id?: string;
    _isBake?: boolean;
    _isBakeIndex?: number;
  }) => {

    if (_isBakeIndex) {
      const finalDetails = details?.slice(0, _isBakeIndex).concat(details?.slice(_isBakeIndex + 1, details?.length));
      setValue(`details`, finalDetails);
      insert(_isBakeIndex, { ...defaultValues, isBake: _isBake, warehouse: {} as IWarehouse });
    }

    details?.forEach((detail, index) => {
      if (index !== deleteIndex && index !== _isBakeIndex) {
        const groupDetails: IGroupingDetailWithQuantity[] = [];
        //NOTE: find same warehouse and sum group detail
        const sameWarehouse = details?.filter((d, dIndex) => d?.warehouse?.id === detail?.warehouse?.id && deleteIndex !== dIndex && _isBakeIndex !== dIndex);

        let mainMaterialRemain = 0;
        const err: string[] = [];

        if (sameWarehouse) {
          sameWarehouse?.forEach((same) => {
            // NOTE: calculate quantity of same group in main material
            const mainMaterialSumQuantity = sameWarehouse?.reduce((acc, curr: IPackagingDetail) => acc + Number(curr?.totalBox || 0), 0);
            const mainMaterialResultQuantity = same?.group?.mainMaterial?.subUnit && (same?.group?.mainMaterial?.valueUnit && Number(same?.group?.mainMaterial?.valueUnit) > 0) ? (mainMaterialSumQuantity) / same?.group?.mainMaterial?.valueUnit : (mainMaterialSumQuantity);

            // NOTE: calculate remain in main material
            mainMaterialRemain = Number(same?.group?.mainMaterial?.remain || 0) - Number(mainMaterialResultQuantity);

            // NOTE: Push error if mainMaterialRemain < 0
            if (mainMaterialRemain < 0 && same?.group?.mainMaterial?.name) {
              err.push(same?.group?.mainMaterial?.name);
            } else {
              const nameErr = err.filter((e) => e !== same?.group?.mainMaterial?.name);
              err.splice(0, err.length, ...nameErr);
            }

            // NOTE: cal in sub material
            if (same?.group && same?.group?.details) {

              // NOTE: calculate quantity of same group in sub material
              same?.group?.details?.forEach((groupDetail: IGroupingDetail, indexGroupDetail) => {

                const findGroup = groupDetails.find((d: IGroupingDetail) => d?.subMaterial?.id === groupDetail?.subMaterial?.id);

                const sumQuantity = groupDetail.isCalculate ? (same?.totalBox || 0) * (same?.id === _id ? (_capacityValue || 0) : (same?.capacityValue || 0)) : (same?.totalBox || 0);

                const resultQuantity = groupDetail?.subMaterial?.subUnit && (groupDetail?.subMaterial?.valueUnit && Number(groupDetail?.subMaterial?.valueUnit) > 0) ? (groupDetail?.qty * sumQuantity) / groupDetail?.subMaterial?.valueUnit : (groupDetail?.qty * sumQuantity);

                if (!findGroup) {
                  groupDetails.push({ ...groupDetail, _quantity: resultQuantity });
                } else {
                  findGroup._quantity = parseFloat((resultQuantity || 0).toString()) + parseFloat((findGroup._quantity || 0).toString());
                }
              });
            }
          });
        }

        //NOTE: set group details
        const newRemainList = [{ name: "", remain: 0 }];
        const _g = detail?.group?.details?.map((groupDetail) => {
          const findGroupDetail = groupDetails?.find((d: IGroupingDetail) => d?.subMaterial?.id === groupDetail?.subMaterial?.id);
          if (findGroupDetail) {
            const remain = parseFloat((groupDetail.subMaterial.remain || 0).toString()) - parseFloat((findGroupDetail._quantity || 0).toString());
            groupDetail.subMaterial.total = remain === Infinity || remain === -Infinity ? 0 : remain;
            if (remain < 0) {
              err.push(groupDetail.subMaterial.name);
            } else {
              const nameErr = err.filter((e) => e !== groupDetail.subMaterial.name);
              err.splice(0, err.length, ...nameErr);
            }
            const remainObj = {
              name: groupDetail.subMaterial.name,
              remain: remain
            };
            newRemainList.push(remainObj);
          }
          return groupDetail;
        });

        const _mainMaterial = { ...detail?.group?.mainMaterial, total: mainMaterialRemain };

        setRemainList(newRemainList.filter((r) => r.name !== ""));
        if (_g && index !== deleteIndex) {
          setValue(`details.${index}.group`, { ...detail.group, mainMaterial: _mainMaterial, details: _g || [] } as IGrouping);
        }
        if (err?.length > 0) {
          setError(`details.${index}.group`, { type: "manual", message: err.join(", ") });
        }
        else {
          clearErrors(`details.${index}.group`);
        }
      }
    }
    );

    // NOTE: delete detail
    if (deleteIndex !== undefined) {
      const finalDetails = details?.slice(0, deleteIndex).concat(details?.slice(deleteIndex + 1, details?.length));
      setValue(`details`, finalDetails);
    }

    handleCalSutiTotal(deleteIndex);
  };
  const calCostPerBox = ({
    _index,
    _exportBox,
    _deleteIndex,
    _weightExport,
    _capacityValue,
    _id,
    _weightPerBox,
    _isWarehouse,
  }: calCostPerBoxProps) => {

    const _errors: string[] = [];
    details?.forEach((detail, index) => {
      // NOTE: calculate total box and quantity
      const _weight = (_weightExport && index === _index) ? _weightExport : (detail?.quantity !== 0 ? detail?.quantity : (detail?.isBake ? detail?.bake?.remain : detail?.random?.remain));
      const _totalBox = (_index === index && _exportBox) ? _exportBox : (_weight || 0) / ((index === _index && _weightPerBox) ? _weightPerBox : (detail?.weightPerBox ?? 0));
      const _quantity = (_weightExport && index === _index) ? _weightExport : ((!_weightPerBox && index === _index && detail?.quantity !== 0) ? detail?.quantity : ((index !== _index && detail?.quantity !== 0) ? detail?.quantity : ((detail?.weightPerBox ?? 0) * _totalBox)));

      if (index !== _deleteIndex && !_isWarehouse) {
        // NOTE: totalBox decimal upper integer
        setValue(`details.${index}.totalBox`, _totalBox === Infinity || _totalBox === -Infinity ? 0 : Math.ceil(_totalBox || 0));
        setValue(`details.${index}.quantity`, _quantity === Infinity || _quantity === -Infinity ? 0 : _quantity);
      }

      // NOTE: cal total quantity that have same bake or random to check remian weight
      const sumQuantity = details.filter((d) => (d.isBake && d.bake?.id === detail.bake?.id) || (!d.isBake && d.random?.id === detail.random?.id)).reduce((acc, curr) => acc + parseFloat((curr.quantity || 0).toString()), 0);

      //NOTE: set error
      if ((detail.isBake && detail.bake?.remain && sumQuantity > detail.bake?.remain) || (!detail.isBake && detail?.random?.remain && sumQuantity > detail?.random?.remain)) {
        if (detail.id) { _errors.push(detail.id); }

      } else {
        const nameErr = _errors.filter((e) => e !== detail.id);
        _errors.splice(0, _errors?.length, ...nameErr);
      }
    });

    details?.forEach((detail, dIndex) => {
      const findError = _errors.find((e) => e === detail.id);
      if (findError) {
        setError(`details.${dIndex}.quantity`, {
          type: "manual",
          message: `จํานวนสินค้าไม่เพียงพอ`,
        });
      } else {
        clearErrors(`details.${dIndex}.quantity`);
      }
    });

    const _details = details?.filter((detail, index) => index !== _deleteIndex);
    setValue("details", _details);
    calGrouping({ deleteIndex: _deleteIndex, _capacityValue, _id });
  };

  const handleCalSutiTotal = (deleteIndex?: number, _details?: IPackagingDetail[]) => {

    const initialAccumulators: IAccumulators = {
      sutiTotalKg: 0,
      sutiTotalBox: 0,
      exportTotalKg: 0,
      exportTotalBox: 0,
      sutiWeightLossKg: 0,
      sutiWeightLossBox: 0
    };
    const allDetails = _details ? _details : details;

    const allSuti = allDetails?.filter((detail, index) =>
      index !== deleteIndex
    ).reduce((acc: IAccumulators, curr: IPackagingDetail, index: number) => {
      //NOTE: find index docNo
      const findIndexDocNo = allDetails.findIndex((d) =>
        d.isBake ? d?.bake?.id === curr?.bake?.id : d?.random?.id === curr?.random?.id
      );
      //NOTE: check is docNo duplicate? for calculate suti total docNo
      if (
        findIndexDocNo === index) {
        if (curr.isBake) {
          acc.sutiTotalKg += parseFloat((curr?.bake?.remain || 0).toString());
          const a = parseFloat((curr?.bake?.remain || 0).toString()) / parseFloat((curr?.weightPerBox || 0).toString());
          acc.sutiTotalBox += a !== Infinity && a !== -Infinity ? a : 0;
        } else {
          acc.sutiTotalKg += parseFloat((curr?.random?.remain || 0).toString());
          const a = parseFloat((curr?.random?.remain || 0).toString()) / parseFloat((curr?.weightPerBox || 0).toString());
          acc.sutiTotalBox += a !== Infinity && a !== -Infinity ? a : 0;
        }
      }

      //NOTE: cal export total
      acc.exportTotalKg += parseFloat((curr?.quantity || 0).toString());
      acc.exportTotalBox += parseFloat((curr?.totalBox || 0).toString());

      //NOTE: cal suti weight loss
      acc.sutiWeightLossKg += parseFloat((acc.sutiTotalKg || 0).toString()) - parseFloat((acc.exportTotalKg || 0).toString());
      acc.sutiWeightLossBox += parseFloat((acc.sutiTotalBox || 0).toString()) - parseFloat((acc.exportTotalBox || 0).toString());
      return acc;
    }, initialAccumulators) || initialAccumulators;

    setSutiTotalKg(allSuti.sutiTotalKg || 0);
    setSutiTotalBox(allSuti.sutiTotalBox || 0);
    setSutiTotalExportKg(allSuti.exportTotalKg || 0);
    setSutiTotalExportBox(allSuti.exportTotalBox || 0);
    setSutiWeightLossKg(allSuti.sutiWeightLossKg || 0);
    setSutiWeightLossBox(allSuti.sutiWeightLossBox || 0);
  };

  const handleOpenCreateDailog = (name: string) => {
    setNameType(name);
    switch (name) {
      case "material": setOpenCreateDailog(
        {
          open: true, datas: [], type: "material", title: "เพิ่มรายการวัสดุ"
        }
      );
        break;
      case "group": setOpenCreateDailog(
        {
          open: true, datas: [], type: "group", title: "เพิ่มกลุ่มวัสดุ"
        }
      );
        break;
      case "warehouse": setOpenCreateDailog(
        {
          open: true, datas: _warehouseDatas, type: "warehouse", title: "สร้างคลังวัสดุ"
        }
      );
        break;
      case "grade": setOpenCreateDailog(
        {
          open: true, datas: [], type: "grade", title: "สร้างเกรด"
        }
      );
        break;
      case "capacity": setOpenCreateDailog(
        {
          open: true, datas: [], type: "capacity", title: "สร้างจำนวนบรรจุ"
        }
      );
        break;
    }
  };

  useEffect(() => {
    if (id) {
      reset(props.packaging);
      setGroupOptions(props.packaging?.groupOptions || {});
      setValue("remark", props.packaging?.remark);
      setImages(props.packaging?.image || []);
      calGrouping({});
      handleCalSutiTotal(undefined, props?.packaging?.details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, props.packaging]);

  useEffect(() => {
    if (typeof indexRow === 'number') {
      setGroupOptions(prevState => ({
        ...prevState,
        [indexRow]: props.groupings
      }));
    }
  }, [indexRow, props.groupings]);

  useEffect(() => {
    handleOpenCreateDailog(nameType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.districtData, props.subDistrictData, props.provinceData]);


  //NOTE: ฟังก์ชันจัดการเปิด Tooltip
  const handleOpen = (index: number) => {
    setShowIndex(index);
  };

  //NOTE: ฟังก์ชันจัดการปิด Tooltip
  const handleClose = () => {
    setShowIndex(null);
  };

  return (
    <Box component={'div'}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/process-packaging")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          {/* NOTE: check from order or not */}
          {from === "order" ? <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>แพ็ค</Typography>
            <Link component="button" onClick={() => navigate(`/process-order-edit/${order?.id}`)}>
              ใบคำสั่งซื้อ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.packaging?.id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs> : <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>แพ็ค</Typography>
            <Link component="button" onClick={() => navigate("/process-packaging")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.packaging?.id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>}
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.packaging && props.permission.delete && props.packaging.id) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenConfirm({
                  open: true,
                  title: "ลบข้อมูล",
                  message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
                  color: "error",
                  type: "delete",
                });
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Box component="form">
                <Grid container spacing={2} >
                  <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h6">บรรจุภัณฑ์</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                    <TextField disabled label="เลขที่เอกสาร"
                      fullWidth
                      {...register("docNo")}
                      value={(getValues("docNo")) || undefined}
                      InputLabelProps={{
                        shrink: getValues("docNo") ? true : undefined,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                    <Controller
                      name="order"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisabled}
                          value={field?.value as IRefOrder || null}
                          options={props.order || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option?.inputValue;
                            }
                            return option?.invoiceNo || "";
                          }}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          onChange={(_, newValue) => {
                            field.onChange(newValue);
                            reset({ ...getValues(), details: [defaultValues] });
                            handleCalSutiTotal();
                            if (newValue?.id) {
                              props.onFetchBake(newValue?.id);
                              props.onFetchRandom(newValue?.id);
                            }
                          }}
                          renderInput={(params) => <TextField
                            {...params}
                            error={!!errors.order}
                            helperText={errors.order ? "กรุณาเลือกใบคำสั่งซื้อ" : ""}
                            label="ใบคำสั่งซื้อ" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="subtitle1">จำนวนบรรจุ</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    details && details?.length > 0 && details.map((field, index) => (
                      <React.Fragment key={`${field.id}`}>

                        {!isMobile && (
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <IconButton
                              color="error"
                              disabled={isDisabled}
                              onClick={() => {
                                remove(index);
                                calCostPerBox({ _deleteIndex: index });
                              }}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={12} lg={11} alignSelf={'center'}>
                          <Grid container spacing={2}>
                            {isMobile && (
                              <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'}>
                                <Typography variant="h6">{`ลำดับที่ ${index + 1}`}</Typography>
                              </Grid>
                            )}
                            <Grid item xs={2} sm={2} md={3} lg={1} alignSelf={'center'}>
                              <FormControlLabel
                                control={
                                  <Controller
                                    name={`details.${index}.isBake`}
                                    control={control}
                                    render={({ field }) => (
                                      <Checkbox
                                        disabled={isDisabled}
                                        checked={(getValues(`details.${index}.isBake`) as boolean) ?? true}
                                        onChange={(_, newValue) => {
                                          field.onChange(newValue);
                                          setValue(`details.${index}`, { ...defaultValues, isBake: newValue, } as any);
                                          calGrouping({ _isBakeIndex: index, _isBake: newValue });
                                          if (newValue) {
                                            setValue(`details.${index}.random`, null);
                                          } else {
                                            setValue(`details.${index}.bake`, null);
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                }
                                label="อบ"
                              />
                            </Grid>
                            <Grid item xs={10} sm={10} md={4} lg={2.5} alignSelf={'center'}>
                              {details?.[index]?.isBake ? <Controller
                                name={`details.${index}.bake`}
                                control={control}
                                rules={{ required: true }}
                                disabled={!order}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    disabled={isDisabled}
                                    value={field?.value as IBake || null}
                                    options={props.bake as IBake[]}
                                    getOptionLabel={(option: IBake) => {
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      return `${option?.docNo} (${option?.totalAfterBakeWeight || 0} Kg) (คงเหลือ ${option?.remain} Kg)` || "";
                                    }}
                                    isOptionEqualToValue={(option: IBake, value) => option?.id === value?.id}
                                    onChange={(_, newValue) => {
                                      field.onChange(newValue);
                                      setValue(`details.${index}.quantity`, newValue?.totalAfterBakeWeight || 0);
                                      calCostPerBox({ _index: index, _weightExport: Number(newValue?.totalAfterBakeWeight) || undefined });
                                    }}
                                    renderInput={(params) => <TextField
                                      error={!!errors.details?.[index]?.bake}
                                      helperText={
                                        errors.details?.[index]?.bake?.message ? errors.details?.[index]?.bake?.message : (errors.details?.[index]?.bake ? "กรุณาเลือกเลขที่เอกสารอบ" : "")
                                      }
                                      {...params} label={"เลขที่เอกสารอบ"} />}
                                  />
                                )}
                              /> : <Controller
                                name={`details.${index}.random`}
                                control={control}
                                disabled={!order}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    value={field?.value || null}
                                    options={props.random as IRandomInspection[]}
                                    disabled={isDisabled}
                                    getOptionLabel={(option: IRandomInspection) => {
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      return `${option?.docNo} (${option?.totalRemainingWeight || 0} Kg) (คงเหลือ ${option?.remain} Kg)` || "";
                                    }}
                                    isOptionEqualToValue={(option: IRandomInspection, value) => option?.id === value?.id}
                                    onChange={(_, newValue) => {
                                      if (newValue) {
                                        field.onChange(newValue);
                                        calCostPerBox({ _index: index, _weightExport: Number(newValue?.totalRemainingWeight) || undefined });
                                      } else {
                                        setValue(`details.${index}.random`, null);
                                        calCostPerBox({ _index: index });
                                      }
                                    }}
                                    renderInput={(params) => <TextField
                                      error={!!errors.details?.[index]?.random}
                                      helperText={
                                        errors.details?.[index]?.random?.message ? errors.details?.[index]?.random?.message : (errors.details?.[index]?.random ? "กรุณาเลือกเลขที่เอกสารสุ่มผ่า" : "")
                                      }
                                      {...params} label={"เลขที่เอกสารสุ่มผ่า"} />}
                                  />
                                )}
                              />}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={2.5} alignSelf={'center'}>
                              <Controller
                                name={`details.${index}.warehouse`}
                                control={control}
                                disabled={!order}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <Autocomplete
                                    disabled={isDisabled}
                                    value={field?.value || null}
                                    options={props.warehouses || []}
                                    getOptionLabel={(option: IWarehouse) => {
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      return option?.name || "";
                                    }}
                                    isOptionEqualToValue={(option: any, value) => option?.id === value?.id}
                                    onChange={(_, newValue) => {
                                      if (newValue?.id === "create") {
                                        handleOpenCreateDailog("warehouse");
                                      } else if (newValue?.id !== "create") {
                                        field.onChange(newValue);
                                        if (newValue?.id) {
                                          setWarehouse(newValue?.id);
                                          props.onFetchGrouping(newValue?.id, id || undefined);
                                        }
                                        setIndexRow(index);
                                        calCostPerBox({ _isWarehouse: true });
                                        setValue(`details.${index}.group`, {} as IGrouping);

                                      }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filterOptions(options, params);
                                      if (params.inputValue !== '') {
                                        filtered.push({
                                          name: `สร้าง '${params.inputValue}'`,
                                          id: 'create',
                                        });
                                        setName(params.inputValue);
                                      }
                                      return filtered;
                                    }}
                                    renderOption={(props, option) => {
                                      const { key, ...rest } = props;
                                      return (
                                        <Typography key={key} {...rest} variant="body2" >{option.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                          <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                                        </Box> : option.name}</Typography>
                                      );
                                    }}
                                    renderInput={(params) => <TextField
                                      error={!!errors.details?.[index]?.warehouse}
                                      helperText={
                                        errors.details?.[index]?.warehouse?.message ? errors.details?.[index]?.warehouse?.message : (errors.details?.[index]?.warehouse ? "กรุณาเลือกคลังวัสดุ" : "")
                                      }
                                      {...params} label={"คลังวัสดุ"} />}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} alignSelf={'center'}>
                              <Controller
                                name={`details.${index}.group`}
                                control={control}
                                rules={{
                                  required: true
                                }}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    disabled={isDisabled ? isDisabled : !details?.[index]?.warehouse?.id}
                                    value={field?.value || null}
                                    options={groupOptions?.[index] || []}
                                    getOptionLabel={(option: IGrouping) => {
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      return option?.mainMaterial?.name ? option?.mainMaterial?.name : (option?.name ? option?.name : "");
                                    }}
                                    isOptionEqualToValue={(option: any, value) => option?.id === value?.id}
                                    onChange={(_, newValue) => {
                                      if (newValue?.id === "create") {
                                        handleOpenCreateDailog("group");
                                      } else if (newValue?.id !== "create") {
                                        field.onChange(newValue);
                                        if (newValue?.mainMaterial?.weight) { setValue(`details.${index}.weightPerBox`, newValue?.mainMaterial?.weight); }
                                        calCostPerBox({ _index: index, _weightPerBox: newValue?.mainMaterial?.weight, _isWarehouse: true });
                                      }
                                    }
                                    }
                                    filterOptions={(options, params) => {
                                      const filtered = filterOptions(options, params);
                                      if (params.inputValue !== '') {
                                        filtered.push({
                                          name: `สร้าง '${params.inputValue}'`,
                                          id: 'create',
                                        });
                                        setName(params.inputValue);
                                      }
                                      return filtered;
                                    }}
                                    renderOption={(props, option) => {
                                      const { key, ...rest } = props;
                                      return (
                                        <Typography key={key} {...rest} variant="body2" >{option.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                          <AddCircleIcon /><Box component={'span'} ml={1}> {option?.name}</Box>
                                        </Box> : option?.mainMaterial?.name}</Typography>
                                      );
                                    }}
                                    renderInput={(params) => <TextField
                                      {...params}
                                      error={!!errors.details?.[index]?.group}
                                      helperText={
                                        errors.details?.[index]?.group?.message ?
                                          errors.details?.[index]?.group?.message : (errors.details?.[index]?.group ? "กรุณาเลือกบรรจุภัณฑ์" : "")
                                      }
                                      label={"บรรจุภัณฑ์"} />}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={1.5} alignSelf={'center'}>
                              <Controller
                                name={`details.${index}.netWeightFrom`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <TextField
                                    fullWidth
                                    label="NW เริ่มต้น"
                                    type="number"
                                    {...field}
                                    disabled={isDisabled}
                                    value={field.value}
                                    error={!!errors.details?.[index]?.netWeightFrom}
                                    helperText={errors.details?.[index]?.netWeightFrom ? "กรุณาระบุ NW เริ่มต้น" : ""}
                                    InputLabelProps={{ shrink: field.value ? true : undefined }}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      calCostPerBox({ _index: index });
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={1.5} alignSelf={'center'}>
                              <Controller
                                name={`details.${index}.netWeightTo`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <TextField
                                    fullWidth
                                    label="NW สิ้นสุด"
                                    type="number"
                                    {...field}
                                    disabled={isDisabled}
                                    value={field.value}
                                    error={!!errors.details?.[index]?.netWeightTo}
                                    helperText={errors.details?.[index]?.netWeightTo ? "กรุณาระบุ NW สิ้นสุด" : ""}
                                    InputLabelProps={{ shrink: field.value ? true : undefined }}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      calCostPerBox({ _index: index });
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={1.5} alignSelf={'center'}>
                              <Controller
                                name={`details.${index}.grossWeightFrom`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <TextField
                                    fullWidth
                                    label="GW เริ่มต้น"
                                    type="number"
                                    {...field}
                                    disabled={isDisabled}
                                    value={field.value}
                                    error={!!errors.details?.[index]?.grossWeightFrom}
                                    helperText={errors.details?.[index]?.grossWeightFrom ? "กรุณาระบุ GW เริ่มต้น" : ""}
                                    InputLabelProps={{ shrink: field.value ? true : undefined }}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      calCostPerBox({ _index: index });
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={1.5} alignSelf={'center'}>
                              <Controller
                                name={`details.${index}.grossWeightTo`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <TextField
                                    fullWidth
                                    label="GW สิ้นสุด"
                                    type="number"
                                    {...field}
                                    disabled={isDisabled}
                                    value={field.value}
                                    error={!!errors.details?.[index]?.grossWeightTo}
                                    helperText={errors.details?.[index]?.grossWeightTo ? "กรุณาระบุ GW สิ้นสุด" : ""}
                                    InputLabelProps={{ shrink: field.value ? true : undefined }}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      calCostPerBox({ _index: index });
                                    }}
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={3} alignSelf={'center'}>
                              <Controller
                                name={`details.${index}.grade`}
                                control={control}
                                rules={{ required: "กรุณาเลือกเกรด" }}
                                render={({ field, fieldState: { error } }) => (
                                  <Autocomplete
                                    {...field}
                                    disabled={isDisabled}
                                    value={field?.value}
                                    options={props.grades || []}
                                    getOptionLabel={(option: IGrade) => {
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      return option?.name || "";
                                    }}
                                    isOptionEqualToValue={(option: any, value) => option?.id === value?.id}
                                    onChange={(_, newValue) => {
                                      if (newValue?.id === "create") {
                                        handleOpenCreateDailog("grade");
                                        field.onChange(null);
                                      } else if (newValue?.id) { field.onChange(newValue); }
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filterOptions(options, params);
                                      if (params.inputValue !== '') {
                                        filtered.push({
                                          name: `สร้าง '${params.inputValue}'`,
                                          id: 'create',
                                        });
                                        setName(params.inputValue);
                                      }
                                      return filtered;
                                    }}
                                    renderOption={(props, option) => {
                                      const { key, ...rest } = props;
                                      return (
                                        <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                          <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                                        </Box> : option.name}</Typography>
                                      );
                                    }}
                                    renderInput={(params) => <TextField
                                      {...params}
                                      error={!!error}
                                      helperText={error ? error.message : null
                                      }
                                      label={"เกรด"} />}
                                    clearOnBlur
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} alignSelf={'center'}>
                              <Controller
                                name={`details.${index}.weightPerBox`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <TextField
                                    fullWidth
                                    label="น้ำหนักต่อกล่อง (Kg/Box)"
                                    type="number"
                                    {...field}
                                    disabled={isDisabled}
                                    value={field.value}
                                    error={!!errors.details?.[index]?.weightPerBox}
                                    helperText={errors.details?.[index]?.weightPerBox ? "กรุณาระบุน้ำหนักต่อกล่อง" : ""}
                                    InputLabelProps={{ shrink: field.value ? true : undefined }}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      calCostPerBox({ _index: index });
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} alignSelf={'center'}>
                              <Controller
                                name={`details.${index}.capacity`}
                                control={control}
                                rules={{ required: "กรุณาเลือกจํานวนบรรจุ" }}
                                render={({ field, fieldState: { error } }) => {
                                  //NOTE: Extract the value from the field
                                  const value = field.value?.id || "";
                                  return (
                                    <FormControl fullWidth error={!!error} disabled={isDisabled}>
                                      <InputLabel id={`capacity-label-${index}`}>จํานวนบรรจุ</InputLabel>
                                      <Select
                                        labelId={`capacity-label-${index}`}
                                        value={value}
                                        onChange={(event) => {
                                          if (event.target.value === "create") {
                                            handleOpenCreateDailog("capacity");
                                          } else {
                                            //NOTE: Find the selected capacity object
                                            const selectedValue = props.capacities.find(capacity => capacity.id === event.target.value);
                                            field.onChange(selectedValue);
                                            if (selectedValue?.value) {
                                              setValue(`details.${index}.capacityValue`, selectedValue.value);
                                              calCostPerBox({ _capacityValue: selectedValue.value, _id: details[index]?.id });
                                            }
                                          }
                                        }}
                                        displayEmpty
                                      >
                                        {props.capacities?.map((option: ICapacity) => (
                                          <MenuItem key={option.id} value={option.id}>
                                            {`${option.name} (${option.value})`}
                                          </MenuItem>
                                        ))}
                                        <MenuItem key={"create"} value={"create"} sx={{ color: "primary.main" }}>
                                          <AddCircleIcon /><Box component={'span'} ml={1}> สร้างจํานวนบรรจุ</Box>
                                        </MenuItem>
                                      </Select>
                                      {error && <FormHelperText id={`capacity-error-${index}`}>{error.message}</FormHelperText>}
                                    </FormControl>
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} alignSelf={'center'}>
                              <Controller
                                name={`details.${index}.quantity`}
                                control={control}
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => (
                                  <TextField
                                    fullWidth
                                    label="จํานวนส่งออก (Kg)"
                                    type="number"
                                    {...field}
                                    disabled={isDisabled}
                                    value={field.value}
                                    error={!!errors.details?.[index]?.quantity}
                                    helperText={errors.details?.[index]?.quantity?.message ? errors.details?.[index]?.quantity?.message : errors.details?.[index]?.quantity ? "กรุณาระบุจํานวนส่งออก" : ""}
                                    InputLabelProps={{ shrink: field.value ? true : undefined }}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      calCostPerBox({ _index: index, _weightExport: parseFloat(e.target.value) });
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} alignSelf={'center'}>
                              <Controller
                                name={`details.${index}.totalBox`}
                                rules={{
                                  required: true,
                                }}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    fullWidth
                                    label="จำนวนส่งออก (กล่อง)"
                                    type="number"
                                    {...field}
                                    disabled={isDisabled}
                                    value={field.value}
                                    error={!!errors.details?.[index]?.totalBox}
                                    helperText={errors.details?.[index]?.totalBox?.message ? errors.details?.[index]?.totalBox?.message : errors.details?.[index]?.totalBox ? "กรุณาระบุจํานวนส่งออก" : ""}
                                    InputLabelProps={{ shrink: field.value ? true : undefined }}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      calCostPerBox({ _index: index, _exportBox: parseInt(e.target.value) });
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={0.5} alignSelf={'center'} textAlign={'end'}>
                          <Tooltip
                            title=
                            {
                              details?.[index]?.group?.details && details?.[index]?.group?.details.map((detail: any, indexDetail: number) => (
                                <Fragment key={`detail-tooltip-packing-${field.id}-${index}-${indexDetail}`}>
                                  <Grid container spacing={1} sx={{ minWidth: 300, pr: 1 }} >
                                    {indexDetail === 0 && (
                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container >
                                          <Grid item xs={7} sm={6} md={6} lg={6}>
                                            <Typography >{`${details?.[index]?.group?.mainMaterial?.name || ''} (${details?.[index]?.group?.mainMaterial?.unit || ''})`}</Typography>
                                          </Grid>
                                          <Grid item xs={5} sm={6} md={6} lg={6} textAlign={'right'}>
                                            <Typography>{
                                              parseFloat(
                                                (details?.[index]?.group?.mainMaterial?.subUnit && details?.[index]?.group?.mainMaterial?.valueUnit ?
                                                  ((details?.[index]?.group?.mainMaterial?.valueUnit || 0) * (details?.[index]?.group?.mainMaterial?.total || 0))
                                                  : (details?.[index]?.group?.mainMaterial?.total || 0)
                                                ).toString()).toLocaleString(undefined, {
                                                  maximumFractionDigits: 10,
                                                  minimumFractionDigits: 2,
                                                })
                                            }</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    )}
                                    <Grid item xs={7} sm={6} md={6} lg={6}>
                                      <Typography >{`${detail?.subMaterial?.name || ''} (${detail?.subMaterial?.unit || ''})`}</Typography>
                                    </Grid>
                                    <Grid item xs={5} sm={6} md={6} lg={6} textAlign={'right'}>
                                      <Typography >{
                                        parseFloat(detail?.subMaterial?.subUnit && detail?.subMaterial?.valueUnit ? (detail?.subMaterial?.valueUnit * detail?.subMaterial?.total) : detail?.subMaterial?.total || 0).toLocaleString(undefined, {
                                          maximumFractionDigits: 10,
                                          minimumFractionDigits: 2,
                                        })
                                      }</Typography>
                                    </Grid>
                                  </Grid>
                                </Fragment>
                              ))
                            }
                            open={showIndex === index}
                            onOpen={() => handleOpen(index)}
                            onClose={handleClose}
                          >
                            <IconButton onClick={(e) => {
                              if (showIndex === index) {
                                handleClose();
                              } else { handleOpen(index); }
                            }} >
                              <InfoOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>

                        {index !== details?.length - 1 && (
                          <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'}>
                            <Divider />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))
                  }
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Grid container spacing={2}>
                      {isMobile ? <>
                        <Grid item xs={4} sm={4} >
                          <Button
                            variant="outlined"
                            color="error"
                            disabled={isDisabled}
                            sx={{ width: '100%' }}
                            type="button"
                            startIcon={<RemoveCircleIcon />}
                            onClick={() => {
                              remove(fields.length - 1);
                              calCostPerBox({ _deleteIndex: fields.length - 1 });
                            }
                            }
                          >
                            ลบ
                          </Button>
                        </Grid>
                        <Grid item xs={8} sm={8} >
                          <Button
                            variant="outlined"
                            sx={{ width: '100%' }}
                            type="button"
                            disabled={isDisabled}
                            startIcon={<AddCircleIcon />}
                            onClick={() => {
                              const _group = details?.[0]?.group;
                              append({ ...defaultValues, mainMaterial: mainMaterial, group: _group, });
                            }
                            }
                          >
                            เพิ่มรายการ
                          </Button>
                        </Grid></> : <Grid item md={12} lg={12}>
                        <Button
                          variant="outlined"
                          sx={{ width: '100%' }}
                          type="button"
                          disabled={isDisabled}
                          startIcon={<AddCircleIcon />}
                          onClick={() => {
                            append({ ...defaultValues, mainMaterial: mainMaterial, group: null });
                          }}
                        >
                          เพิ่มรายการ
                        </Button>
                      </Grid>
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} sm={8} md={8} lg={6}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'}>
                        <TextField label="หมายเหตุ"
                          {...register("remark")}
                          disabled={isDisabled}
                          InputLabelProps={{
                            shrink: getValues("remark") ? true : undefined,
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                        <Typography>เอกสาร</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button component="label" variant="outlined" disabled={isDisabled} startIcon={<UploadFileIcon />}>
                          เลือกไฟล์
                          <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png" multiple onChange={handleFileChange} />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {selectedFiles?.length > 0 && (
                          <Box component={'div'} mt={1}>
                            {selectedFiles.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`sf-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small" color="error" disabled={isDisabled} onClick={() => handleFileRemove(index)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                      style={{ maxWidth: "50%", height: "auto", cursor: "pointer" }}

                                      onClick={() => onImageClick(URL.createObjectURL(file))}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {images && (
                          <Box component={'div'} mt={1}>
                            {images.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`im-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small"
                                      color="error"
                                      disabled={isDisabled}
                                      onClick={() => handleFileRemoveImageList(file)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/uploads/${(file).name
                                        }`}
                                      alt="Selected"
                                      style={{ maxWidth: "50%", height: "auto", cursor: 'pointer' }}
                                      onClick={() => onImageClick(`${process.env.REACT_APP_API_URL}/uploads/${(file).name}`)}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={6} justifyContent={'flex-end'}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Typography >จำนวนทั้งหมด </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography >
                          {`${thaiFormatNumber(parseFloat(sutiTotalBox.toString()).toFixed(2))} (กล่อง)`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography >
                          {`${thaiFormatNumber(parseFloat(sutiTotalKg.toString()).toFixed(2))} (Kg)`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Typography >จำนวนส่งออกทั้งหมด</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography >
                          {`${thaiFormatNumber(parseFloat(sutiTotalExportBox.toString()).toFixed(2))} (กล่อง)`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography >
                          {`${thaiFormatNumber(parseFloat(sutiTotalExportKg.toString()).toFixed(2))} (Kg)`}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Typography >จำนวนสูญหายทั้งหมด</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography >{`${thaiFormatNumber(parseFloat(sutiWeightLossBox.toString()).toFixed(2))} (กล่อง)`}</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography >{`${thaiFormatNumber(parseFloat(sutiWeightLossKg.toString()).toFixed(2))} (Kg)`}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} >
                    <Stack spacing={2} direction="row">
                      {isFromStatus ? (<>
                        {userGroup?.canApprove && props.packaging.status === "waiting" && (
                          <>
                            <Button
                              variant="contained"
                              fullWidth
                              color="error"
                              onClick={() => {
                                setValue("status", "rejected" as string);
                                onSubmit();
                              }}
                            >
                              ปฏิเสธ
                            </Button>
                            <Button
                              variant="contained"
                              color="success"
                              fullWidth
                              onClick={() => {
                                setValue("status", "approved" as string);
                                onSubmit();
                              }}
                              style={{ marginLeft: '8px' }}
                            >
                              อนุมัติ
                            </Button>
                          </>)}
                      </>) :
                        ((!id || (props.packaging?.status === "waiting")) && (<>
                          <Button
                            variant="outlined"
                            onClick={() => navigate("/process-packaging")}
                          >
                            ยกเลิก
                          </Button>
                          {props.permission.create ?
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                onSubmit();
                              }}
                            >
                              บันทึก
                            </Button>
                            : <></>}
                        </>))
                      }
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <SimpleBackdrop open={props.isLoading ?? false} />
              <ImagePreview
                open={openImage}
                imageSrc={selectedImage}
                onClose={() => setOpenImage(false)}
              />
              <DialogConfirm
                open={openConfirm.open}
                type={openConfirm.type === "delete" ? "error" : "success"}
                title={openConfirm.title}
                message={openConfirm.message}
                onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                onSubmit={() => {
                  setOpenConfirm({ ...openConfirm, open: false });
                  if (openConfirm.type === "create" || openConfirm.type === "approved") {
                    onCreate();
                  } else if (openConfirm.type === "update") {
                    onUpdate();
                  } else if (openConfirm.type === "delete") {
                    onDelete();
                  }
                }}
              />
              <DialogCreate datas={openCreateDailog?.datas}
                open={openCreateDailog?.open && (openCreateDailog?.type === "material" || openCreateDailog?.type === "warehouse")}
                title={openCreateDailog?.title}
                type={openCreateDailog?.type}
                onClose={() => {
                  setOpenCreateDailog({ ...openCreateDailog, open: false });
                  setName("");
                }}
                onSubmit={(data, type) => { props?.onCreateDialog(data, type); }} onFetchData={(id, type) => {
                  props?.onFetchData(id, type);
                }}
                onCreateDialog={(data, type) => { props?.onCreateDialog(data, type); }}
              />
              <DialogCreateGroup
                onClose={() => {
                  setOpenCreateDailog({ ...openCreateDailog, open: false });
                  setName("");
                }}
                open={openCreateDailog?.open && openCreateDailog?.type === "group"}
                onCreate={(data: IGrouping) => {
                  setOpenCreateDailog({ ...openCreateDailog, open: false });
                  props.onCreateMaterialGroup(warehouse, data);
                }}
                mainMaterialsData={props.mainMaterialsData}
                subMaterialsData={props.subMaterialsData}
                permission={props.permission}
                groupingData={{} as IGrouping}
                groupingId=""
                isLoading={props.isLoading}
                onCreateDialog={(data, type) => { props?.onCreateDialog(data, type); }}
                name={name}
              />
              <GradeCreateView open={openCreateDailog?.open && openCreateDailog?.type === "grade"} onClose={() => {
                setOpenCreateDailog({ ...openCreateDailog, open: false });
                setName("");
              }}
                onSubmit={(data) => props.onCreateDialog(data, nameType)}
                permission={props.permission}
                name={name}
              />
              <CapacityCreateView open={openCreateDailog?.open && openCreateDailog?.type === "capacity"} onClose={() => {
                setOpenCreateDailog({ ...openCreateDailog, open: false });
                setName("");
              }} onSubmit={(data) => props.onCreateDialog(data, nameType)} permission={props.permission} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box >
  );
};

export default PackagingCreateView;