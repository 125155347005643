import { observer } from "mobx-react-lite";
import MaterialDetailView from "../Views/MaterialDetailView";
import materialService, { IMaterial, IMaterialTransactionCriteria } from "../Services/MaterialsService";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import receiveService from "../Services/ReceiveService";
import clearState from "../../../utils/resetState";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";



const MaterialDetailController = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();

  const defaultCriteria: IMaterialTransactionCriteria = {
    warehouseId: '',
    materialId: id ?? '',
    page: 0,
    limit: 10,
  };
  useEffect(() => {
    if (id) {
      getOne(id);
    }
    getMaterialTransactions(defaultCriteria);
    getWarehouses();
    checkPermissionActionButton();
    return () => {
      clearState(materialService, ["material", "materialTransactions"]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const onUpdate = async (data: IMaterial) => {
    try {
      setIsLoading(true);
      await materialService.update(id ?? "", data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getOne(id ?? "");
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async (data: IMaterial) => {
    try {
      setIsLoading(true);
      await materialService.update(id ?? "", data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/inventory-material`);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const getOne = async (id: string) => {
    try {
      setIsLoading(true);
      await materialService.getOne(id);
    } catch (error) {
      console.error("Error fetching material:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMaterialTransactions = async (criteria: IMaterialTransactionCriteria) => {
    try {
      setIsLoading(true);
      await materialService.getMaterialsTransactions(criteria);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouses = async () => {
    try {
      setIsLoading(true);
      await receiveService.getWarehouses();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("inventory-warehouses");
    if (result) {
      setPermission(result);
    }
  };

  return <MaterialDetailView
    onUpdate={onUpdate}
    material={materialService.materialDataById}
    onDelete={onDelete}
    materialTransactionsData={materialService.materialTransactionData} onSearchByCriteria={getMaterialTransactions}
    warehousesData={receiveService.warehousesData ?? []}
    isLoading={isLoading}
    permission={permission ?? {} as IUserDetail} />;

});

export default MaterialDetailController;
