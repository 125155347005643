import { useEffect, useState } from "react";
import MaterialsListView from "../Views/MaterialsListView";
import materialService, { IMaterial, IMaterialCriteria, IMaterialView } from "../Services/MaterialsService";
import { observer } from "mobx-react-lite";
import { enqueueSnackbar } from "notistack";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";

const MaterialsListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [materialData, setMaterialData] = useState<IMaterialView>();
  const defaultCriteria: IMaterialCriteria = {
    name: "",
    page: 0,
    limit: 10,
  };
  useEffect(() => {
    searchByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const create = async (data: IMaterial) => {
    try {
      setIsLoading(true);
      const res = await materialService.create(data);
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      searchByCriteria(defaultCriteria);
    } catch (error) {
      console.error("An error occurred while creating material:", error);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }

  };

  const searchByCriteria = async (criteria: IMaterialCriteria) => {
    try {
      setIsLoading(true);
      await materialService.getByCriteria(criteria).then((data) => {
        data?.datas?.sort((a: IMaterial, b: IMaterial) => (b.createAt?.toString() ?? '').localeCompare(a.createAt?.toString() ?? ''));
        setMaterialData(data);
      });
    } catch (error) {
      console.error("Error searching materials by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("inventory-material");
    if (result) {
      setPermission(result);
    }
  };


  return <MaterialsListView
    isLoading={isLoading}
    onCreate={create}
    materials={materialData ?? {} as IMaterialView}
    onSerchByCriteria={searchByCriteria}
    permission={permission ?? {} as IUserDetail} />;
});

export default MaterialsListController;
