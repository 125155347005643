import { FC, Fragment, useEffect, useState } from "react";
import { IUserGroup, IUserGroupDetail } from "../Services/UserGroupService";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Box, Breadcrumbs, Grid, IconButton, Typography, Link, Button, Card, CardContent, TextField, Divider, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, FormControl, FormControlLabel } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { IMenu, menus } from "../../../constants/menus";
import DialogConfirm from "../../../components/DialogConfirm";
import SimpleBackdrop from "../../../components/SimpleBackdrop";
import { IUserDetail } from "../../Login/Services/LoginService";
interface UserGroupDetailViewProps {
  data: IUserGroup | undefined;
  onCreate: (data: IUserGroup) => Promise<void>;
  onUpdate: (data: IUserGroup) => Promise<void>;
  onRemove: (id: string) => Promise<void>;
  isLoading: boolean;
  permission: IUserDetail;
}
const UserGroupDetailView: FC<UserGroupDetailViewProps> = (props) => {
  const allMenu = menus.map((menu: IMenu) => {
    menu.children = menu.children?.map((child: IMenu) => {
      return { ...child, isSelected: false, read: false, edit: false, create: false, delete: false };
    });
    return { ...menu, isSelected: false, read: false, edit: false, create: false, delete: false };
  });
  const { register, handleSubmit, formState: { errors }, reset, getValues, control, watch, setValue
  } = useForm<IUserGroup>({
    defaultValues: {
      details: allMenu as IUserGroupDetail[],
    }
  });
  const { fields, replace } = useFieldArray({
    control,
    name: 'details',
    keyName: "key"

  });

  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    action: "create" as "create" | "edit" | "delete" | "warning",
    type: "success" as "success" | "error" | "warning",
    data: {} as IUserGroup
  });
  const [isCkeckAll, setIsCheckAll] = useState(false);
  const { id } = useParams();
  const handleChangeCheckall = () => {
    const _fields = fields.map((menu: IUserGroupDetail) => {
      if (!isCkeckAll) {
        // parent menu
        menu.isSelected = !isCkeckAll;
        menu.isSelected = !isCkeckAll;
        menu.read = !isCkeckAll;
        menu.edit = !isCkeckAll;
        menu.create = !isCkeckAll;
        menu.delete = !isCkeckAll;

      } else if (isCkeckAll) {
        // parent menu
        menu.isSelected = false;
        menu.isSelected = false;
        menu.read = false;
        menu.edit = false;
        menu.create = false;
        menu.delete = false;
      };
      // child menu
      menu.children?.map((childMenu: IUserGroupDetail) => {
        if (!isCkeckAll) {
          childMenu.isSelected = true;
          childMenu.read = true;
          childMenu.edit = true;
          childMenu.create = true;
          childMenu.delete = true;
        } else if (isCkeckAll) {
          childMenu.isSelected = false;
          childMenu.read = false;
          childMenu.edit = false;
          childMenu.create = false;
          childMenu.delete = false;
        };
        return childMenu;
      });
      return menu;
    });
    replace(_fields);
  };

  const handleOpenConfirm = (action: "create" | "edit" | "delete" | "warning" = "create") => {
    const data = getValues();
    setOpenConfirm({
      ...openConfirm,
      open: true,
      title: action !== "create" ? (action === "delete" ? "ลบข้อมูล" : "แก้ไขข้อมูล") : ("บันทึกข้อมูล"),
      message: action !== "create" ? (action === "delete" ? "คุณต้องการลบข้อมูลนี้ใช่หรือไม่" : "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่") : "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
      type: action === "create" || action === "edit" ? "success" : (action === "delete" ? "error" : "warning"),
      action: action,
      data: data,
    });
  };

  const handleSave = (data: IUserGroup) => {
    props.onCreate({ ...data });
    reset();
  };

  const handleEdit = (data: IUserGroup) => {
    if (id) {
      props.onUpdate({ ...data, id: id });
      reset();
    }
  };
  const handleDelete = (id: string) => {
    props.onRemove(id);
    reset();
  };

  const onSubmit = handleSubmit(() => {
    handleOpenConfirm(id ? "edit" : "create");
  });

  const onConfirmSubmit = () => {
    if (openConfirm.action === "create") {
      handleSave(openConfirm.data);
    } else if (openConfirm.action === "edit") {
      handleEdit(openConfirm.data);
    } else if (openConfirm.action === "delete" && id) {
      handleDelete(id);
    }
    setOpenConfirm({ ...openConfirm, open: false });
  };

  watch();

  const handleCheckboxChange = (index: number, field: "isSelected" | "read" | "create" | "edit" | "delete", value: boolean, childIndex?: any) => {
    if (childIndex === undefined) {
      if (field === 'isSelected') {
        // parent menu
        fields[index].isSelected = value;
        fields[index].read = value;
        fields[index].create = value;
        fields[index].edit = value;
        fields[index].delete = value;
        // child menu
        fields[index].children?.map((child: IUserGroupDetail) => {
          child.isSelected = value;
          child.read = value;
          child.create = value;
          child.edit = value;
          child.delete = value;
          return child;
        });
      } else {
        // parent menu
        fields[index][field] = value;
        if (value === false) {
          fields[index].isSelected = value;
          if (field === 'read') {
            fields[index].create = value;
            fields[index].edit = value;
            fields[index].delete = value;
          }
        }
      }
    } else {
      if (field === 'isSelected' && fields[index].children![childIndex]) {
        // child menu
        fields[index].children![childIndex].isSelected = value;
        fields[index].children![childIndex].read = value;
        fields[index].children![childIndex].create = value;
        fields[index].children![childIndex].edit = value;
        fields[index].children![childIndex].delete = value;
      } else {
        // child menu
        fields[index].children![childIndex][field] = value;
        if (value === false) {
          fields[index].children![childIndex].isSelected = value;
          if (field === 'read') {
            fields[index].children![childIndex].create = value;
            fields[index].children![childIndex].edit = value;
            fields[index].children![childIndex].delete = value;
          }
        }
      }
    }
    if (value === false) {
      setIsCheckAll(false);
    }
    setValue('details', fields);
  };
  const findChildName = (value: string | undefined, isChild?: boolean): string | null => {
    if (isChild) {
      for (const menu of allMenu) {
        const foundChild = menu.children?.find(c => c.value === value);
        if (foundChild) {
          return foundChild.name || null;
        }
      }
    } else {
      return allMenu.find((m) => (m.value === value))?.name || "";
    }
    return null;
  };

  useEffect(() => {
    reset({
      details: allMenu as IUserGroupDetail[]
    });
    if (id) {
      setIsCheckAll(true);
      const _findParentFalse = allMenu.find((m) => (m.read === false));
      if (_findParentFalse) {
        setIsCheckAll(false);
      }
      //  map menu
      const _details = allMenu.map((menu: IMenu) => {
        const found = props.data?.details?.find((data: IUserGroupDetail) => data.menu === menu.value);
        if (found) {
          menu.id = found.id;
          menu.isSelected = found.read;
          menu.read = found.read;
          menu.create = found.create;
          menu.edit = found.edit;
          menu.delete = found.delete;
          menu.value = found.menu;
          if (found.children) {
            const _children = menu?.children?.map((child: IMenu) => {
              const foundChild = found.children?.find((dataChild: IUserGroupDetail) => dataChild.menu === child.value);
              if (foundChild) {
                if (foundChild.read === false) {
                  setIsCheckAll(false);
                }
                return {
                  ...child,
                  id: foundChild.id,
                  isSelected: foundChild.read,
                  read: foundChild.read,
                  create: foundChild.create,
                  edit: foundChild.edit,
                  delete: foundChild.delete,
                  value: foundChild.menu,
                };
              }
              return child;
            });

            menu.children = _children || [];
          }
        }
        return menu;
      });
      reset({ ...props.data, details: _details });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, reset, id]);

  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/user-group")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ตำแหน่ง</Typography>
            <Link component="button" onClick={() => navigate("/user-group")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              รายละเอียด
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.data && props.permission.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => handleOpenConfirm("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Box component="form" onSubmit={onSubmit} mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField label="ตำแหน่ง" variant="outlined" fullWidth {...register("name", { required: true })} error={!!errors.name}
                      helperText={errors.name ? "กรุณาระบุตำแหน่ง" : " "}
                      InputLabelProps={{
                        shrink: getValues("name") ? true : undefined,
                      }} />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={2} alignSelf={'center'} >
                    <FormControl fullWidth>
                      <Box
                        display="flex"
                        justifyContent={"flex-start"}
                        alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox color="primary" checked={getValues("canApprove") ?? false}
                              onChange={(e) => {
                                setValue("canApprove", e.target.checked);
                              }} />
                          }
                          label="อนุมัติ / ไม่อนุมัติ ได้"
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={2} alignSelf={'center'} >
                    <FormControl fullWidth>
                      <Box
                        display="flex"
                        justifyContent={"flex-start"}
                        alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox color="primary" checked={getValues("canCost") ?? false}
                              onChange={(e) => {
                                setValue("canCost", e.target.checked);
                              }} />
                          }
                          label="ระบุราคาต้นทุนได้"
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={2} alignSelf={'center'}>
                    <FormControl fullWidth>
                      <Box
                        display="flex"
                        justifyContent={"flex-start"}
                        alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox color="primary" checked={getValues("canFinance") ?? false}
                              onChange={(e) => {
                                setValue("canFinance", e.target.checked);
                              }} />
                          }
                          label="ระบุราคาได้"
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                    <Typography variant="body1">สิทธิ์การใช้งาน</Typography>
                  </Grid>
                  <Grid item xs={12} >
                    <TableContainer sx={{ height: 600, overflow: 'auto' }} >
                      <Table sx={{ minWidth: 650 }} stickyHeader >
                        <TableHead >
                          <TableRow >
                            <TableCell width={100}><Checkbox value={isCkeckAll} checked={isCkeckAll} onChange={() => {
                              setIsCheckAll(!isCkeckAll);
                              handleChangeCheckall();
                            }} /></TableCell>
                            <TableCell width={400} align="left" >เมนู</TableCell>
                            <TableCell>ดูข้อมูล</TableCell>
                            <TableCell>สร้าง</TableCell>
                            <TableCell>แก้ไข</TableCell>
                            <TableCell>ลบ</TableCell>
                            <TableCell width={200} align="center"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fields && fields.length > 0 &&
                            fields.map((menu, index) => (
                              <Fragment key={`menu-parent-${index}-${menu?.menu}`}>
                                <TableRow>
                                  <TableCell width={100}>
                                    <Controller
                                      name={`details.${index}.isSelected`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.isSelected ?? false}
                                          defaultValue={""}
                                          onChange={() => handleCheckboxChange(index, 'isSelected', !menu.isSelected)}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell width={400} align="left" sx={{ fontWeight: 'bold' }}>
                                    {findChildName(id && menu.menu ? menu.menu : menu.value, false)}
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.read`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.read ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'read', !menu.read);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.create`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.create ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'create', !menu.create);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.edit`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.edit ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'edit', !menu.edit);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.delete`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.delete ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'delete', !menu.delete);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell width={200} align="center"></TableCell>
                                </TableRow>
                                {menu.children?.map((child, indexchild) => {
                                  if (!child.value || !child.name) {
                                    return null;
                                  }
                                  return (
                                    <Fragment key={`menu-child-${indexchild}-${child?.value}`}>
                                      <TableRow>
                                        <TableCell width={100}>
                                          <Controller
                                            name={`details.${index}.children`}
                                            control={control}
                                            render={({ field }) => (
                                              <Checkbox
                                                {...field}
                                                checked={menu.children![indexchild].isSelected ?? false}
                                                defaultValue={""}
                                                onChange={() => handleCheckboxChange(index, 'isSelected', !menu.children![indexchild].isSelected, indexchild)}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell width={400} align="left" sx={{ pl: 5 }}>
                                          {`- ${findChildName(child.value, true)}`}
                                        </TableCell>
                                        <TableCell>
                                          <Controller
                                            name={`details.${index}.children`}
                                            control={control}
                                            render={({ field }) => (
                                              <Checkbox
                                                {...field}
                                                checked={menu.children![indexchild].read ?? false}
                                                onChange={() => {
                                                  handleCheckboxChange(index, 'read', !menu.children![indexchild].read, indexchild);
                                                }}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Controller
                                            name={`details.${index}.children`}
                                            control={control}
                                            render={({ field }) => (
                                              <Checkbox
                                                {...field}
                                                checked={menu.children![indexchild].create ?? false}
                                                onChange={() => {
                                                  handleCheckboxChange(index, 'create', !menu.children![indexchild].create, indexchild);
                                                }}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Controller
                                            name={`details.${index}.children`}
                                            control={control}
                                            render={({ field }) => (
                                              <Checkbox
                                                {...field}
                                                checked={menu.children![indexchild].edit ?? false}
                                                onChange={() => {
                                                  handleCheckboxChange(index, 'edit', !menu.children![indexchild].edit, indexchild);
                                                }}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Controller
                                            name={`details.${index}.children.${indexchild}.delete` as any}
                                            control={control}
                                            render={({ field }) => (
                                              <Checkbox
                                                {...field}
                                                checked={menu.children![indexchild].delete ?? false}
                                                onChange={() => {
                                                  handleCheckboxChange(index, 'delete', !menu.children![indexchild].delete, indexchild);
                                                }}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell width={200} align="center"></TableCell>
                                      </TableRow>
                                    </Fragment>
                                  );
                                })}
                              </Fragment>
                            ))}

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => navigate("/expense-other")}
                          >
                            ยกเลิก
                          </Button>
                          {props.permission.create || (props.permission.edit && props.data?.id) ?
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                              style={{ marginLeft: '8px' }}
                            >
                              บันทึก
                            </Button>
                            : <></>}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <SimpleBackdrop open={props.isLoading ?? false} />
                <DialogConfirm
                  open={openConfirm.open}
                  type={openConfirm.type}
                  title={openConfirm.title}
                  message={openConfirm.message}
                  onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                  onSubmit={onConfirmSubmit}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserGroupDetailView;
