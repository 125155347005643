import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import farmProductReceiptService, { IFarmProductReceipt, IFarmProductReceiptCriteria, IFarmProductReceiptListView } from "../Services/FarmProductReceipt";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import FarmProductReceiptListView from "../Views/FarmProductReceiptListView";
import supplierService, { ISupplier } from "../../Supplier/Services/SupplierService";
import dayjs from "dayjs";

const FarmProductReceiptListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [FarmProductReceiptData, setFarmProductReceiptData] = useState<IFarmProductReceiptListView>();

  const defaultCriteria: IFarmProductReceiptCriteria = {
    dateFrom: dayjs().startOf('month').toDate(),
    dateTo: dayjs().toDate(),
    docNo: '',
    agent: '',
    status: '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchByCriteria(defaultCriteria);
    checkPermissionActionButton();
    getSupplier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const fetchByCriteria = async (criteria: IFarmProductReceiptCriteria) => {
    try {
      setIsLoading(true);
      await farmProductReceiptService.getByCriteria(criteria).then((data) => {
        data?.datas?.sort((a: IFarmProductReceipt, b: IFarmProductReceipt) => (b.createAt?.toString() ?? '').localeCompare(a.createAt?.toString() ?? ''));
        setFarmProductReceiptData(data);
      }
      );
    } catch (error) {
      console.error("An error occurred while getting the farm product receipt:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSupplier = async () => {
    try {
      await supplierService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the suppliers:", error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-product-receipt");
    if (result) {
      setPermission(result);
    }
  };


  return (
    <FarmProductReceiptListView
      isLoading={isLoading}
      onSearchByCriteria={fetchByCriteria}
      farmProductReceipts={FarmProductReceiptData ?? {} as IFarmProductReceiptListView}
      permission={permission ?? {} as IUserDetail}
      suppliers={supplierService.supplierListData ?? [] as ISupplier[]}
    />
  );

});

export default FarmProductReceiptListController;