import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DashboardController from "../features/Dashboards/Controllers/DashboardController";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CompanyController from "../features/Company/Controllers/CompanyController";
import SupplierTypeController from "../features/Supplier/Controllers/SupplierTypeController";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import MaterialDetailController from "../features/Inventory/Controllers/MaterialDetailController";
import MaterialsListController from "../features/Inventory/Controllers/MaterialsListController";
import TransfersListController from "../features/Inventory/Controllers/TransfersListController";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import WarehousesController from "../features/Inventory/Controllers/WarehousesController";
import SupplierListController from "../features/Supplier/Controllers/SupplierListController";
import SupplierCreateController from "../features/Supplier/Controllers/SupplierCreateController";
import TransferCreateController from "../features/Inventory/Controllers/TransferDetailController";
import ProductListController from "../features/ProductProcess/Controllers/ProductListController";
import ProductDetailController from "../features/ProductProcess/Controllers/ProductDetailController";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ReceiveCreateController from "../features/Inventory/Controllers/ReceiveCreateController";
import ReceivesListController from "../features/Inventory/Controllers/ReceivesListController";
import ExpenseTypesListController from "../features/Expense/Controllers/ExpenseTypesListController";
// import OrderListController from "../features/ProductProcess/Controllers/OrderListController";
// import OrderCreateController from "../features/ProductProcess/Controllers/OrderCreateController";
import ExpensesListController from "../features/Expense/Controllers/ExpensesListController";
import ExpenseDetailController from "../features/Expense/Controllers/ExpenseDetailController";
import GroupingsListController from "../features/Inventory/Controllers/GroupingsListController";
import GroupingCreateController from "../features/Inventory/Controllers/GroupingCreateController";
import WarehouseDetailController from "../features/Inventory/Controllers/WarehouseDetailController";
import RolesListController from "../features/UserManagement/Controllers/RolesListController";
import RoleDetailController from "../features/UserManagement/Controllers/RoleDetailController";
import UserGroupsListController from "../features/UserManagement/Controllers/UserGroupsListController";
import UserGroupDetailController from "../features/UserManagement/Controllers/UserGroupDetailController";
import UsersListController from "../features/SuperAdminManagement/Controllers/UsersListController";
import CompaniesListController from "../features/SuperAdminManagement/Controllers/CompaniesListController";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CompanyDetailController from "../features/SuperAdminManagement/Controllers/CompanyDetailController";
import UserDetailController from "../features/SuperAdminManagement/Controllers/UserDetailController";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import EliminateController from "../features/ProductProcess/Controllers/EliminateController";
import CostReportListController from "../features/Report/Controllers/CostReportListController";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LossReportListController from "../features/Report/Controllers/LossReportListController";
import PackagingCreateController from "../features/ProductProcess/Controllers/PackagingCreateController";
import PackagingListController from "../features/ProductProcess/Controllers/PackagingListController";
import FarmProductReceiptListController from "../features/ProductProcess/Controllers/FarmProductReceiptListController";
import RandomInspectionListController from "../features/ProductProcess/Controllers/RandomInspectionListController";
import RandomInspectionCreateController from "../features/ProductProcess/Controllers/RandomInspectionCreateController";
import VapourHeatTreatmentController from "../features/ProductProcess/Controllers/VapourHeatTreatmentController";
import FarmProductReceiptCreateController from "../features/ProductProcess/Controllers/FarmProductReceiptCreateController";
import BakeListController from "../features/ProductProcess/Controllers/BakeListController";
import BakeCreateController from "../features/ProductProcess/Controllers/BakeCreateController";
import { IUser } from "../features/UserManagement/Services/RoleService";
import DriverController from "../features/Transport/Controllers/DriverController";
import GradeController from "../features/ProductProcess/Controllers/GradeController";
import CarController from "../features/Transport/Controllers/CarController";
import CapacitiesListController from "../features/ProductProcess/Controllers/CapacitiesController";
import ShippingListController from "../features/ProductProcess/Controllers/ShippingListController";
import ShippingCreateController from "../features/ProductProcess/Controllers/ShippingCreateController";
import MarketController from "../features/ProductProcess/Controllers/MarketController";
import EliminateCreateController from "../features/ProductProcess/Controllers/EliminateCreateController";
import ShippingtypeController from "../features/ProductProcess/Controllers/ShippingTypeController";
import OrderListController from "../features/ProductProcess/Controllers/OrderListController";
import OrderCreateController from "../features/ProductProcess/Controllers/OrderCreateController";
import SalesReportListController from "../features/Report/Controllers/SalesReportListController";
import ProfitReportListController from "../features/Report/Controllers/ProfitReportListController";

export interface IMenu {
  name: string | null;
  value?: string;
  path: string;
  icon?: any;
  controller?: any;
  children?: Omit<IMenu, "children">[],
  isSelected?: boolean;
  read?: boolean;
  edit?: boolean;
  create?: boolean;
  delete?: boolean;
  createAt?: Date;
  updateAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  isAdminMenu?: boolean;
  id?: string;
}

export const menus: IMenu[] = [
  {
    name: "ภาพรวม",
    value: "dashboard",
    path: "/dashboard",
    icon: DashboardOutlinedIcon,
    controller: DashboardController,
    isAdminMenu: false,
    children: [],
  },
  {
    name: "การจัดการคลังวัสดุ",
    value: "inventory",
    path: "/inventory",
    icon: WarehouseOutlinedIcon,
    controller: MaterialsListController,
    isAdminMenu: false,
    children: [
      {
        name: "คลังวัสดุ",
        value: "inventory-warehouses",
        path: "/inventory-warehouses",
        icon: null,
        controller: WarehousesController,
      },
      {
        name: null,
        path: "/inventory-warehouses-detail/:id",
        controller: WarehouseDetailController,
      },
      {
        name: "จัดการวัสดุ",
        value: "inventory-material",
        path: "/inventory-material",
        icon: null,
        controller: MaterialsListController,
      },
      {
        name: null,
        path: "/inventory-material-detail/:id",
        controller: MaterialDetailController,
      },
      {
        name: "รับเข้าวัสดุ",
        value: "inventory-receive",
        path: "/inventory-receive",
        icon: null,
        controller: ReceivesListController,
      },
      {
        name: null,
        path: "/inventory-receive-create/:id?",
        controller: ReceiveCreateController,
      },
      {
        name: "การโยกวัสดุ",
        value: "inventory-transfer",
        path: "/inventory-transfer",
        icon: null,
        controller: TransfersListController,
      },
      {
        name: null,
        path: "/inventory-transfer/:id",
        controller: TransferCreateController,
      },
      {
        name: null,
        path: "/inventory-transfer-create",
        controller: TransferCreateController,
      },
      {
        name: "กลุ่มวัสดุ",
        value: "inventory-grouping",
        path: "/inventory-grouping",
        icon: null,
        controller: GroupingsListController,
      },
      {
        name: null,
        path: "/inventory-grouping-create/:id?",
        controller: GroupingCreateController,
      },
    ],
  },
  {
    name: "กระบวนการผลิต",
    value: "process",
    path: "/process",
    icon: AutorenewIcon,
    controller: ProductListController,
    isAdminMenu: false,
    children: [
      {
        name: "ผลิตภัณฑ์",
        value: "process-product",
        path: "/process-product",
        icon: null,
        controller: ProductListController,
      },
      {
        name: null,
        path: "/process-product-detail/:id",
        controller: ProductDetailController,
      },
      {
        name: "ใบคำสั่งซื้อ",
        value: "process-order",
        path: "/process-order",
        icon: null,
        controller: OrderListController,
      },
      {
        name: null,
        value: "process-order-create",
        path: "/process-order-create",
        icon: null,
        controller: OrderCreateController
      },
      {
        name: null,
        value: "process-order-edit/:id",
        path: "/process-order-edit/:id",
        icon: null,
        controller: OrderCreateController
      },
      {
        name: 'รับจากสวน',
        value: 'process-product-receipt',
        path: '/process-product-receipt',
        icon: null,
        controller: FarmProductReceiptListController
      },
      {
        name: null,
        value: 'process-product-receipt-create',
        path: '/process-product-receipt-create',
        icon: null,
        controller: FarmProductReceiptCreateController
      },
      {
        name: null,
        value: 'process-product-receipt-edit/:id',
        path: '/process-product-receipt-edit/:id',
        icon: null,
        controller: FarmProductReceiptCreateController
      },
      {
        name: 'ตรวจสอบคุณภาพ',
        value: 'process-random-inspection',
        path: '/process-random-inspection',
        icon: null,
        controller: RandomInspectionListController
      },
      {
        name: null,
        path: '/process-random-inspection-create/:id?',
        icon: null,
        controller: RandomInspectionCreateController
      },
      {
        name: 'อบ',
        value: 'process-bake',
        path: '/process-bake',
        icon: null,
        controller: BakeListController
      },
      {
        name: null,
        value: 'process-bake-create',
        path: '/process-bake-create',
        icon: null,
        controller: BakeCreateController
      },
      {
        name: null,
        value: 'process-bake-edit/:id',
        path: '/process-bake-edit/:id',
        icon: null,
        controller: BakeCreateController
      },
      {
        name: "แพ็ค",
        value: "process-packaging",
        path: "/process-packaging",
        icon: null,
        controller: PackagingListController
      },
      {
        name: null,
        value: "process-packaging-create",
        path: "/process-packaging-create",
        icon: null,
        controller: PackagingCreateController
      },
      {
        name: null,
        value: "process-packaging-edit/:id",
        path: "/process-packaging-edit/:id",
        icon: null,
        controller: PackagingCreateController
      },
      {
        name: null,
        value: "process-packaging-approve/:id",
        path: "/process-packaging-approve/:id",
        icon: null,
        controller: PackagingCreateController
      },
      {
        name: "Shipping",
        value: "process-shipping",
        path: "/process-shipping",
        icon: null,
        controller: ShippingListController
      },
      {
        name: null,
        value: "process-shipping-create",
        path: "/process-shipping-create",
        icon: null,
        controller: ShippingCreateController
      },
      {
        name: null,
        value: "process-shipping-edit/:id",
        path: "/process-shipping-edit/:id",
        icon: null,
        controller: ShippingCreateController
      },
      {
        name: null,
        value: "process-shipping-approve/:id",
        path: "/process-shipping-approve/:id",
        icon: null,
        controller: PackagingCreateController
      },
      {
        name: 'การคัดขาย/ทิ้ง',
        value: 'process-eliminate',
        path: '/process-eliminate',
        icon: null,
        controller: EliminateController
      },

      {
        name: null,
        value: 'process-eliminate-create/:id',
        path: '/process-eliminate-create/:id',
        icon: null,
        controller: EliminateCreateController
      }

    ]
  },
  {
    name: "ค่าใช้จ่าย",
    value: "expense",
    path: "/expense",
    icon: ReceiptLongOutlinedIcon,
    controller: DashboardController,
    isAdminMenu: false,
    children: [
      {
        name: "ค่าใช้จ่าย",
        value: "expense-other",
        path: "/expense-other",
        icon: null,
        controller: ExpensesListController,
      },
      {
        name: null,
        path: "/expense-other-detail",
        controller: ExpenseDetailController,
      },
      {
        name: null,
        path: "/expense-other-detail/:id",
        controller: ExpenseDetailController,
      },
      {
        name: "ประเภทค่าใช้จ่าย",
        value: "expense-type",
        path: "/expense-type",
        icon: null,
        controller: ExpenseTypesListController,
      },
    ],
  },
  {
    name: "รายงาน",
    value: "report",
    path: "/report",
    icon: ArticleOutlinedIcon,
    controller: null,
    isAdminMenu: false,
    children: [
      {
        name: "รายงานต้นทุน",
        value: "report-cost",
        path: "/report-cost",
        icon: null,
        controller: CostReportListController,
      },
      {
        name: "รายงานยอดสูญเสีย",
        value: "report-loss",
        path: "/report-loss",
        icon: null,
        controller: LossReportListController,
      },
      {
        name: "รายงานยอดขาย",
        value: "report-sales",
        path: "/report-sales",
        icon: null,
        controller: SalesReportListController,
      },
      {
        name: "รายงานกำไร",
        value: "report-profit",
        path: "/report-profit",
        icon: null,
        controller: ProfitReportListController,
      },
    ]
  },
  {
    name: "ข้อมูลรถขนส่ง",
    value: "transport",
    path: "/transport",
    icon: LocalShippingOutlinedIcon,
    controller: null,
    isAdminMenu: false,
    children: [
      {
        name: "คนขับ",
        value: "transport-driver",
        path: "/transport-driver",
        icon: null,
        controller: DriverController,
      },
      {
        name: "ข้อมูลรถ",
        value: "transport-car",
        path: "/transport-car",
        icon: null,
        controller: CarController,
      }
    ],
  },
  {
    name: "Supplier",
    value: "supplier",
    path: "/supplier",
    icon: PeopleOutlineIcon,
    controller: null,
    isAdminMenu: false,
    children: [
      {
        name: "Supplier",
        value: "supplier-list",
        path: "/supplier-list",
        icon: null,
        controller: SupplierListController,
      },
      {
        name: null,
        path: "/supplier-create",
        icon: null,
        controller: SupplierCreateController,
      },
      {
        name: null,
        path: "/supplier-edit/:id",
        icon: null,
        controller: SupplierCreateController,
      },
      {
        name: "ประเภท Supplier",
        value: "supplier-type",
        path: "/supplier-type",
        icon: null,
        controller: SupplierTypeController,
      },
    ],
  },
  {
    name: "ผู้ใช้งาน",
    value: "user",
    path: "/user",
    icon: ManageAccountsOutlinedIcon,
    controller: null,
    isAdminMenu: false,
    children: [
      {
        name: "ตำแหน่ง",
        value: "user-group",
        path: "/user-group",
        icon: null,
        controller: UserGroupsListController,
      },
      {
        name: null,
        path: "/user-group-detail",
        icon: null,
        controller: UserGroupDetailController,
      },
      {
        name: null,
        path: "/user-group-detail/:id",
        icon: null,
        controller: UserGroupDetailController,
      },
      {
        name: "ผู้ใช้งาน",
        value: "user-role",
        path: "/user-role",
        icon: null,
        controller: RolesListController,
      },
      {
        name: null,
        path: "/user-role-detail",
        icon: null,
        controller: RoleDetailController,
      },
      {
        name: null,
        path: "/user-role-detail/:id",
        icon: null,
        controller: RoleDetailController,
      },
    ]
  },
  {
    name: "ตั้งค่าระบบ",
    value: "setting",
    path: "/setting",
    icon: SettingsOutlinedIcon,
    controller: null,
    isAdminMenu: false,
    children: [
      {
        name: "ข้อมูลบริษัท",
        value: "setting-company",
        path: "/setting-company",
        icon: null,
        controller: CompanyController,
      },
      {
        name: "ข้อมูลโรงอบ",
        value: "setting-vapour-heat",
        path: "/setting-vapour-heat",
        icon: null,
        controller: VapourHeatTreatmentController,
      },
      {
        name: 'ข้อมูลเกรด',
        value: 'setting-grade',
        path: '/setting-grade',
        icon: null,
        controller: GradeController
      },
      {
        name: 'ข้อมูลตลาด',
        value: 'setting-market',
        path: '/setting-market',
        icon: null,
        controller: MarketController
      },
      {
        name: 'จำนวนบรรจุ',
        value: 'setting-capacity',
        path: '/setting-capacity',
        icon: null,
        controller: CapacitiesListController
      },
      {
        name: 'ประเภทการขนส่ง',
        value: 'setting-shipping-type',
        path: '/setting-shipping-type',
        icon: null,
        controller: ShippingtypeController
      },
    ],
  },
  {
    name: "จัดการระบบ",
    value: "admin",
    path: "/admin",
    icon: SupportAgentIcon,
    controller: null,
    isAdminMenu: true,
    children: [
      {
        name: "บริษัท",
        value: "admin-company",
        path: "/admin-company",
        icon: null,
        controller: CompaniesListController,
      },
      {
        name: null,
        path: "/admin-company-detail",
        icon: null,
        controller: CompanyDetailController,
      },
      {
        name: null,
        path: "/admin-company-detail/:id",
        icon: null,
        controller: CompanyDetailController,
      },
      {
        name: "ผู้ใช้งาน",
        value: "admin-user",
        path: "/admin-user",
        icon: null,
        controller: UsersListController,
      },
      {
        name: null,
        path: "/admin-user-detail",
        icon: null,
        controller: UserDetailController,
      },
      {
        name: null,
        path: "/admin-user-detail/:id",
        icon: null,
        controller: UserDetailController,
      },
    ]
  },

];



