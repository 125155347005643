import { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import receiveService, { IReceive, IUploadFile } from "../Services/ReceiveService";
import ReceiveCreateView from "../Views/ReceiveCreateView";
import clearState from "../../../utils/resetState";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import loginService, { IUserDetail, IUserLogined } from "../../Login/Services/LoginService";
import supplierService from "../../Supplier/Services/SupplierService";
import supplierTypeService from "../../Supplier/Services/SupplierTypeService";
import materialService from "../Services/MaterialsService";
import wareshousesService from "../Services/WareshousesService";

const ReceiveCreateController = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [permission, setPermission] = useState<IUserDetail>();

  useEffect(() => {
    if (id) {
      findOne(id);
    }
    getProvince();
    getSuppliers();
    getWarehouses();
    getMaterials();
    getSupplierType();
    checkPermissionActionButton();
    return () => {
      clearState(receiveService, ["receive"]);
    };
  }
    , [id]);

  const create = async (data: IReceive, uploadFile: IUploadFile) => {
    try {
      const res = await receiveService.create(data, { file: uploadFile });
      if (res) {
        setTimeout(() => {
          navigate(`/inventory-receive-create/${res.id}`);
          window.location.reload();
        }, 200);
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }

  };

  const update = async (id: string, data: IReceive, uploadFile: IUploadFile[], deleteImages?: IUploadFile[]) => {
    try {
      const _uploadFile = data.image === uploadFile ? undefined : uploadFile;
      const res = await receiveService.update(id, data, { file: _uploadFile });
      if (res) {
        if (deleteImages && deleteImages.length > 0) {
          const deletePromises = deleteImages.map((file: any) => {
            return receiveService.deleteImages({
              file: file,
            });
          });
          await Promise.all(deletePromises);
        }
        setTimeout(() => {
          findOne(id ?? "");
          window.location.reload();
        }, 200);
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }
  };
  const onDelete = async (data: IReceive) => {
    try {
      await receiveService.update(id ?? "", data);
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
      navigate("/inventory-receive");
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }
  };

  const findOne = async (id: string) => {
    try {
      const res = await receiveService.getOne(id);
      return res;
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการค้นหา", error);
    }
  };

  const getWarehouses = async () => {
    try {
      await receiveService.getWarehouses();
    } catch (error) {
      console.error(error);

    }
  };

  const getSuppliers = async () => {
    try {
      await receiveService.getSuppliers();
    } catch (error) {
      console.error(error);
    }
  };

  const getMaterials = async () => {
    try {
      await receiveService.getMaterials();
    } catch (error) {
      console.error(error);
    }
  };

  const getProvince = async () => {
    try {
      await receiveService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    }
  };

  const getSupplierType = async () => {
    try {
      await receiveService.getAllSupplierType();
    } catch (error) {
      console.error("An error occurred while getting the supplier type:", error);
    }
  };

  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "supplier": response = await supplierService.create(data);
          getSuppliers();
          break;
        case "supplierType": response = await supplierTypeService.create(data);
          getSupplierType();
          break;
        case "material":
          response = await materialService.create(data);
          getMaterials();
          break;
        case "warehouse":
          response = await wareshousesService.create(data);
          getWarehouses();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const onFetchData = async (id?: any, type?: string) => {
    try {
      switch (type) {
        case "district": await receiveService.getDistrict(id);
          break;
        case "subDistrict": await receiveService.getSubDistrict(id);
          break;
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("inventory-receive");
    if (result) {
      setPermission(result);
    }
  };

  return <ReceiveCreateView
    onCreate={create}
    onUpdate={update}
    onDelete={onDelete}
    receiveData={receiveService.receiveData ?? {} as IReceive}
    suppliersData={receiveService.suppliersData ?? []}
    warehousesData={receiveService.warehousesData ?? []}
    materialsData={receiveService.materialsData ?? []}
    receiveId={id ?? ""}
    permission={permission ?? {} as IUserDetail}
    user={loginService.userLoginedData ?? {} as IUserLogined}
    supplierTypesOptions={receiveService.supplierTypeListData ?? []}
    provinceData={receiveService.provinceData ?? []}
    districtData={receiveService.districtData || []}
    subDistrictData={receiveService.subDistrictData || []}
    onCreateDialog={onCreateDialog}
    onFetchData={onFetchData}
  />;
});

export default ReceiveCreateController;
