import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import randomInspectionService, { IRandomInspection, IRandomInspectionCriteria, IRandomInspections } from "../Services/RandomInspectionService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import RandomInspectionListView from "../Views/RandomInspectionListView";
import dayjs from "dayjs";



const RandomInspectionListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [randomInspectionData, setRandomInspectionData] = useState<IRandomInspections>();
  const defaultCriteria: IRandomInspectionCriteria = {
    dateFrom: dayjs().startOf('month').toDate(),
    dateTo: dayjs().toDate(),
    docNo: "",
    status: "all",
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    searchByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const searchByCriteria = async (criteria: IRandomInspectionCriteria) => {
    try {
      setIsLoading(true);
      await randomInspectionService.getByCriteria(criteria).then((data) => {
        data?.datas?.sort((a: IRandomInspection, b: IRandomInspection) => (b.createAt?.toString() ?? "").localeCompare(a.createAt?.toString() ?? ""));
        setRandomInspectionData(data);
      }
      );
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการค้นหา", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-random-inspection");
    if (result) {
      setPermission(result);
    }
  };


  return <RandomInspectionListView isLoading={isLoading} permission={permission ?? {} as IUserDetail} searchByCriteria={searchByCriteria} randomInspectionsData={randomInspectionData ?? {} as IRandomInspections} />;
});

export default RandomInspectionListController;