import { FC, Fragment, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Box, Button, ButtonProps, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DialogConfirm from "../../../components/DialogConfirm";
import { menus, IMenu } from "../../../constants/menus";
import { IUserGroup, IUserGroupDetail } from "../Services/UserGroupService";
import { useParams } from "react-router-dom";


interface UserGroupCreateViewProps {
  data?: IUserGroup | undefined;
  onCreate: (data: IUserGroup) => void;
  onUpdate: (data: IUserGroup) => void;
  onRemove: (id: string) => void;
  onClose: () => void;
  isLoading?: boolean;
  open: boolean;
  name?: string;
}

const UserGroupCreateView: FC<UserGroupCreateViewProps> = (props) => {
  const allMenu = menus.map((menu: IMenu) => {
    menu.children = menu.children?.map((child: IMenu) => {
      return { ...child, isSelected: false, read: false, edit: false, create: false, delete: false };
    });
    return { ...menu, isSelected: false, read: false, edit: false, create: false, delete: false };
  });
  const { register, handleSubmit, formState: { errors }, reset, getValues, control, watch, setValue
  } = useForm<IUserGroup>({
    defaultValues: {
      name: props?.name,
      details: allMenu as IUserGroupDetail[],
    }
  });
  watch();
  const { fields, replace } = useFieldArray({
    control,
    name: 'details',
    keyName: "key"

  });
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });
  const [isCkeckAll, setIsCheckAll] = useState(false);
  const { id } = useParams();
  const onSubmit = handleSubmit(() => {
    if (props.data?.id) {
      setOpenConfirm({
        open: true,
        title: "แก้ไขข้อมูล",
        message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "edit",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const onConfirm = () => {
    props.onCreate(getValues());
    reset();
    onClose();
  };

  const onDelete = () => {
    props.onRemove(props.data?.id as string);
    reset();
    onClose();
  };

  const onClose = () => {
    reset();
    props.onClose();
    setOpenConfirm({ ...openConfirm, open: false });
  };

  const handleChangeCheckall = () => {
    const _fields = fields.map((menu: IUserGroupDetail) => {
      if (!isCkeckAll) {
        // parent menu
        menu.isSelected = !isCkeckAll;
        menu.isSelected = !isCkeckAll;
        menu.read = !isCkeckAll;
        menu.edit = !isCkeckAll;
        menu.create = !isCkeckAll;
        menu.delete = !isCkeckAll;

      } else if (isCkeckAll) {
        // parent menu
        menu.isSelected = false;
        menu.isSelected = false;
        menu.read = false;
        menu.edit = false;
        menu.create = false;
        menu.delete = false;
      };
      // child menu
      menu.children?.map((childMenu: IUserGroupDetail) => {
        if (!isCkeckAll) {
          childMenu.isSelected = true;
          childMenu.read = true;
          childMenu.edit = true;
          childMenu.create = true;
          childMenu.delete = true;
        } else if (isCkeckAll) {
          childMenu.isSelected = false;
          childMenu.read = false;
          childMenu.edit = false;
          childMenu.create = false;
          childMenu.delete = false;
        };
        return childMenu;
      });
      return menu;
    });
    replace(_fields);
  };

  const handleCheckboxChange = (index: number, field: "isSelected" | "read" | "create" | "edit" | "delete", value: boolean, childIndex?: any) => {
    if (childIndex === undefined) {
      if (field === 'isSelected') {
        // parent menu
        fields[index].isSelected = value;
        fields[index].read = value;
        fields[index].create = value;
        fields[index].edit = value;
        fields[index].delete = value;
        // child menu
        fields[index].children?.map((child: IUserGroupDetail) => {
          child.isSelected = value;
          child.read = value;
          child.create = value;
          child.edit = value;
          child.delete = value;
          return child;
        });
      } else {
        // parent menu
        fields[index][field] = value;
        if (value === false) {
          fields[index].isSelected = value;
          if (field === 'read') {
            fields[index].create = value;
            fields[index].edit = value;
            fields[index].delete = value;
          }
        }
      }
    } else {
      if (field === 'isSelected' && fields[index].children![childIndex]) {
        // child menu
        fields[index].children![childIndex].isSelected = value;
        fields[index].children![childIndex].read = value;
        fields[index].children![childIndex].create = value;
        fields[index].children![childIndex].edit = value;
        fields[index].children![childIndex].delete = value;
      } else {
        // child menu
        fields[index].children![childIndex][field] = value;
        if (value === false) {
          fields[index].children![childIndex].isSelected = value;
          if (field === 'read') {
            fields[index].children![childIndex].create = value;
            fields[index].children![childIndex].edit = value;
            fields[index].children![childIndex].delete = value;
          }
        }
      }
    }
    if (value === false) {
      setIsCheckAll(false);
    }
    setValue('details', fields);
  };
  const findChildName = (value: string | undefined, isChild?: boolean): string | null => {
    if (isChild) {
      for (const menu of allMenu) {
        const foundChild = menu.children?.find(c => c.value === value);
        if (foundChild) {
          return foundChild.name || null;
        }
      }
    } else {
      return allMenu.find((m) => (m.value === value))?.name || "";
    }
    return null;
  };

  useEffect(() => {
    setValue('name', props?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.name]);

  return (
    <Dialog open={props.open} maxWidth={'lg'} fullWidth>
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {props.data?.id ? `แก้ไข` : `สร้าง`}ข้อมูลรถ
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
            {props.data?.id && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setOpenConfirm({
                    open: true,
                    title: "ลบข้อมูล",
                    message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
                    color: "error",
                    type: "delete",
                  });
                }}
              >
                ลบข้อมูล
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Box component={'form'} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField label="ตำแหน่ง" variant="outlined" fullWidth {...register("name", { required: true })} error={!!errors.name}
                helperText={errors.name ? "กรุณาระบุตำแหน่ง" : " "}
                InputLabelProps={{
                  shrink: getValues("name") ? true : undefined,
                }} />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={2} alignSelf={'center'} >
              <FormControl fullWidth>
                <Box
                  display="flex"
                  justifyContent={"flex-start"}
                  alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={getValues("canApprove") ?? false}
                        onChange={(e) => {
                          setValue("canApprove", e.target.checked);
                        }} />
                    }
                    label="อนุมัติ / ไม่อนุมัติ ได้"
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={2} alignSelf={'center'} >
              <FormControl fullWidth>
                <Box
                  display="flex"
                  justifyContent={"flex-start"}
                  alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={getValues("canCost") ?? false}
                        onChange={(e) => {
                          setValue("canCost", e.target.checked);
                        }} />
                    }
                    label="ระบุราคาต้นทุนได้"
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={2} alignSelf={'center'}>
              <FormControl fullWidth>
                <Box
                  display="flex"
                  justifyContent={"flex-start"}
                  alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={getValues("canFinance") ?? false}
                        onChange={(e) => {
                          setValue("canFinance", e.target.checked);
                        }} />
                    }
                    label="ระบุราคาได้"
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
              <Typography variant="body1">สิทธิ์การใช้งาน</Typography>
            </Grid>
            <Grid item xs={12} >
              <TableContainer sx={{ height: 600, overflow: 'auto' }} >
                <Table sx={{ minWidth: 650 }} stickyHeader >
                  <TableHead >
                    <TableRow >
                      <TableCell width={100}><Checkbox value={isCkeckAll} checked={isCkeckAll} onChange={() => {
                        setIsCheckAll(!isCkeckAll);
                        handleChangeCheckall();
                      }} /></TableCell>
                      <TableCell width={400} align="left" >เมนู</TableCell>
                      <TableCell>ดูข้อมูล</TableCell>
                      <TableCell>สร้าง</TableCell>
                      <TableCell>แก้ไข</TableCell>
                      <TableCell>ลบ</TableCell>
                      <TableCell width={200} align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields && fields.length > 0 &&
                      fields.map((menu, index) => (
                        <Fragment key={`menu-parent-${index}-${menu?.menu}`}>
                          <TableRow>
                            <TableCell width={100}>
                              <Controller
                                name={`details.${index}.isSelected`}
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    checked={menu.isSelected ?? false}
                                    defaultValue={""}
                                    onChange={() => handleCheckboxChange(index, 'isSelected', !menu.isSelected)}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell width={400} align="left" sx={{ fontWeight: 'bold' }}>
                              {findChildName(id && menu.menu ? menu.menu : menu.value, false)}
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={`details.${index}.read`}
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    checked={menu.read ?? false}
                                    onChange={() => {
                                      handleCheckboxChange(index, 'read', !menu.read);
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={`details.${index}.create`}
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    checked={menu.create ?? false}
                                    onChange={() => {
                                      handleCheckboxChange(index, 'create', !menu.create);
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={`details.${index}.edit`}
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    checked={menu.edit ?? false}
                                    onChange={() => {
                                      handleCheckboxChange(index, 'edit', !menu.edit);
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={`details.${index}.delete`}
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    {...field}
                                    checked={menu.delete ?? false}
                                    onChange={() => {
                                      handleCheckboxChange(index, 'delete', !menu.delete);
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell width={200} align="center"></TableCell>
                          </TableRow>
                          {menu.children?.map((child, indexchild) => {
                            if (!child.value || !child.name) {
                              return null;
                            }
                            return (
                              <Fragment key={`menu-child-${indexchild}-${child?.value}`}>
                                <TableRow>
                                  <TableCell width={100}>
                                    <Controller
                                      name={`details.${index}.children`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.children![indexchild].isSelected ?? false}
                                          defaultValue={""}
                                          onChange={() => handleCheckboxChange(index, 'isSelected', !menu.children![indexchild].isSelected, indexchild)}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell width={400} align="left" sx={{ pl: 5 }}>
                                    {`- ${findChildName(child.value, true)}`}
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.children`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.children![indexchild].read ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'read', !menu.children![indexchild].read, indexchild);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.children`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.children![indexchild].create ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'create', !menu.children![indexchild].create, indexchild);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.children`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.children![indexchild].edit ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'edit', !menu.children![indexchild].edit, indexchild);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.children.${indexchild}.delete` as any}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.children![indexchild].delete ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'delete', !menu.children![indexchild].delete, indexchild);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell width={200} align="center"></TableCell>
                                </TableRow>
                              </Fragment>
                            );
                          })}
                        </Fragment>
                      ))}

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          ยกเลิก
        </Button>
        {
          <Button
            variant="contained"
            onClick={() => {
              onSubmit();
            }}
          >
            บันทึก
          </Button>
        }
        <DialogConfirm
          open={openConfirm.open}
          type={openConfirm.type === "delete" ? "error" : "success"}
          title={openConfirm.title}
          message={openConfirm.message}
          onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
          onSubmit={() => {
            setOpenConfirm({ ...openConfirm, open: false });
            if (openConfirm.type === "delete") {
              onDelete();
            } else {
              onConfirm();
            }
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UserGroupCreateView;