import { FC, useEffect, useState } from "react";
import { Box, Button, TextField, Grid, ButtonProps, Breadcrumbs, Typography, Link, Card, CardContent, IconButton, Stack, Divider, Autocomplete, createFilterOptions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DialogConfirm from "../../../components/DialogConfirm";
import { IDistrict, IProvince, ISubDistrict, ITransfer } from "../Services/TransferService";
import { Controller, useForm } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { IMaterial, IMaterialInWarehouseCriteria } from "../Services/MaterialsService";
import { IWarehouse } from "../Services/WareshousesService";
import { IUserDetail } from "../../Login/Services/LoginService";
import { IUser } from "../../UserManagement/Services/RoleService";
import DialogCreate from "../../../components/DialogCreate";
import MaterialsCreateView from "./MaterialCreateView";
import { warehouseDatas } from "../../../constants/dialogCreateInputs";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface TransferDetailViewProps {
  transfer?: ITransfer;
  materialData?: IMaterial[];
  workerData?: IUser[];
  warehouseData?: IWarehouse[];
  onUpdate: (data: ITransfer) => void;
  onDelete: (data: ITransfer) => void;
  id?: string;
  materialQuantityInWarehouseFromData?: number;
  materialQuantityInWarehouseToData?: number;
  getMaterialQuantityInWarehouse: (criteria: IMaterialInWarehouseCriteria, warehouseType: string) => Promise<void>;
  permission: IUserDetail;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onFetchData: (id?: any, type?: string) => void;
  onCreateDialog: (data: any, type: string) => void;
}
const filterOptions = createFilterOptions<any>();
const TransferDetailView: FC<TransferDetailViewProps> = (props) => {
  const { register, handleSubmit, formState: { errors }, reset, getValues, control, watch } = useForm<ITransfer>({
    defaultValues: {
      docDate: new Date()
    }
  });

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });

  const navigate = useNavigate();
  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });
  const [nameType, setNameType] = useState("");
  const [name, setName] = useState("");
  // NOTE: warehouse input
  const _warehouseDatas: any = warehouseDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    name: name,
  }) || [];

  const onDelete = () => {
    const data = getValues();
    props.onDelete({ ...data, isDelete: true });
    reset();
  };

  const onFormSubmit = (data: ITransfer) => {
    props.onUpdate(data);
    reset();
  };

  watch();

  const onSubmit = (type: "create" | "delete") => {
    if (type === "delete") {
      setOpenConfirm({
        open: true,
        title: "ลบข้อมูล",
        message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
        color: "error",
        type: "delete",
      });
    } else {
      handleSubmit((data) => {
        setOpenConfirm({
          open: true,
          title: "บันทึกข้อมูล",
          message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: "primary",
          type: "create",
        });
      })();
    }
  };

  const validateStocksDifferently = (value: IWarehouse | null) => {
    const stockFrom = getValues("stockFrom");
    if (value && stockFrom && value.id === stockFrom.id) {
      return "คลังต้นทางและคลังปลายทางต้องไม่เหมือนกัน";
    }
    return true;
  };

  const handleOpenCreateDailog = (name: string) => {
    setNameType(name);
    switch (name) {
      case "material": setOpenCreateDailog(
        {
          open: true, datas: [], type: "material", title: "เพิ่มรายการวัสดุ"
        }
      );
        break;
      case "warehouse": setOpenCreateDailog(
        {
          open: true, datas: _warehouseDatas, type: "warehouse", title: "สร้างคลังวัสดุ"
        }
      );
        break;
    }
  };

  useEffect(() => {
    if (props.transfer) {
      reset(props.transfer);
    }
  }, [props.transfer, reset]);

  useEffect(() => {
    const material = getValues("material");
    const stockFrom = getValues("stockFrom");
    if (material && stockFrom) {
      props.getMaterialQuantityInWarehouse({
        materialId: material.id,
        warehouseId: stockFrom.id?.toString(),
      }, 'stockFrom');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("material"), getValues("stockFrom")]);

  useEffect(() => {
    const material = getValues("material");
    const stockTo = getValues("stockTo");
    if (material && stockTo) {
      props.getMaterialQuantityInWarehouse({
        materialId: material.id,
        warehouseId: stockTo.id?.toString(),
      }, 'stockTo');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("material"), getValues("stockTo")]);

  useEffect(() => {
    handleOpenCreateDailog(nameType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.districtData, props.subDistrictData]);

  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/inventory-transfer")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>การจัดการวัสดุ</Typography>
            <Link component="button" onClick={() => navigate("/inventory-transfer")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              รายละเอียด
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.transfer && props.transfer?.status === "waiting" && props.permission.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onSubmit("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>

      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Box component="form" onSubmit={handleSubmit(onFormSubmit)} mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Controller
                      name="docDate"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker sx={{ width: "100%" }}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  error: !!errors.docDate,
                                  helperText: errors.docDate ? "กรุณาระบุวันที่เอกสาร" : "",
                                }
                              }}
                              defaultValue={dayjs()}
                              label="วันที่เอกสาร"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} mt={1}>
                    <TextField
                      disabled={!props.id}
                      {...register("docNo")}
                      label="เลขที่เอกสาร"
                      fullWidth
                      InputLabelProps={{
                        shrink: getValues("docNo") ? true : undefined,
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} sx={{ display: { sm: "none", md: "none", lg: "block" } }} >
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Controller
                      name="material"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          value={value || null}
                          options={props.materialData || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_, newValue) => {

                            if (newValue?.id === "create") {
                              handleOpenCreateDailog("material");
                              onChange(null);
                            } else if (newValue?.id) {
                              onChange(newValue);
                              const stockFrom = getValues("stockFrom");
                              const stockTo = getValues("stockTo");
                              if (newValue) {
                                if (stockFrom) {
                                  props.getMaterialQuantityInWarehouse({
                                    materialId: newValue.id,
                                    warehouseId: stockFrom.id?.toString(),
                                  }, 'stockFrom');
                                }
                                if (stockTo) {
                                  props.getMaterialQuantityInWarehouse({
                                    materialId: newValue.id,
                                    warehouseId: stockTo.id?.toString(),
                                  }, 'stockTo');
                                }
                              }
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.material}
                            helperText={errors.material ? "กรุณาระบุวัสดุ" : ""}
                            {...params} label="วัสดุ" />}
                          clearOnBlur
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Controller
                      name="stockFrom"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          value={value || null}
                          options={props.warehouseData || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_, newValue) => {
                            if (newValue?.id === "create") {
                              handleOpenCreateDailog("warehouse");
                              onChange(null);
                            } else if (newValue?.id) {
                              onChange(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}

                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.stockFrom}
                            helperText={errors.stockFrom ? "กรุณาระบุคลังต้นทาง" : ""}
                            {...params} label="คลังต้นทาง" />}
                        />
                      )}
                    />
                  </Grid>
                  {/* TODO: get material by warehouse */}
                  <Grid item xs={12} sm={12} md={12} lg={4} display={"flex"} alignItems={"center"} >
                    <Typography>
                      <b>สินค้าคงคลัง: </b>
                      {props.materialQuantityInWarehouseFromData || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <TextField
                      fullWidth
                      label="จำนวนที่โยกย้าย"
                      type="number"
                      {...register("quantity", { required: "กรุณาระบุจำนวนที่โยกย้าย", min: { value: 0, message: "จำนวนที่โยกย้ายต้องไม่ติดลบ" }, max: { value: props.materialQuantityInWarehouseFromData || 0, message: "จำนวนที่โยกย้ายต้องไม่เกินจำนวนที่คงคลัง" } })}
                      error={!!errors.quantity}
                      helperText={errors.quantity ? errors.quantity.message : ""}
                      InputLabelProps={{ shrink: getValues("quantity") || getValues("quantity") === 0 ? true : undefined }}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Controller
                      name="stockTo"
                      control={control}
                      rules={{ required: { value: true, message: "กรุณาระบุคลังปลายทาง" }, validate: validateStocksDifferently }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          value={value || null}
                          options={props.warehouseData || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_, newValue) => {
                            if (newValue?.id === "create") {
                              handleOpenCreateDailog("warehouse");
                              onChange(null);
                            } else if (newValue?.id) {
                              onChange(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.stockTo}
                            helperText={errors.stockTo ? errors.stockTo.message : ""}
                            {...params} label="คลังปลายทาง" />}
                        />
                      )}
                    />
                  </Grid>
                  {/* TODO: get material by warehouse */}
                  <Grid item xs={12} sm={12} md={12} lg={4} display={"flex"} alignItems={"center"}>
                    <Typography>
                      <b>สินค้าคงคลัง: </b>
                      {props.materialQuantityInWarehouseToData || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Controller
                      name="worker"
                      control={control}
                      rules={{ required: "กรุณาระบุผู้ทํารายการ" }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          value={field.value as IUser || ""}
                          options={props.workerData || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            const name = (option.firstName || "") + " " + (option.lastName || "");

                            return name;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);
                          }}
                          renderInput={(params) => <TextField
                            {...params} label="ผู้ทํารายการ"
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : ""}
                          />}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2} direction="column">
                      <Grid item xs={12}>
                        <TextField
                          label="หมายเหตุ"
                          {...register("note")}
                          fullWidth
                          multiline
                          variant="outlined"
                          InputLabelProps={{ shrink: getValues("note") ? true : undefined }}
                        />
                      </Grid>
                      <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} >
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="outlined"
                            onClick={() => navigate("/inventory-transfer")}
                          >
                            ยกเลิก
                          </Button>
                          {props.permission.create ?
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => onSubmit("create")}
                              disabled={props.transfer?.status !== "waiting"}
                            >
                              บันทึก
                            </Button>
                            : <></>}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
                <DialogConfirm
                  open={openConfirm.open}
                  type={openConfirm.type === "delete" ? "error" : "success"}
                  title={openConfirm.title}
                  message={openConfirm.message}
                  onClose={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    setName("");
                  }}
                  onSubmit={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    if (openConfirm.type === "delete") {
                      onDelete();
                    } else {
                      handleSubmit(onFormSubmit)();
                    }
                  }}
                />
                <DialogCreate datas={openCreateDailog?.datas} open={openCreateDailog?.open && openCreateDailog?.type !== "material"} title={openCreateDailog?.title} type={openCreateDailog?.type} onClose={() => {
                  setOpenCreateDailog({ ...openCreateDailog, open: false });
                  setName("");
                }} onSubmit={(data, type) => { props?.onCreateDialog(data, type); }} onFetchData={(id, type) => {
                  props?.onFetchData(id, type);
                }} onCreateDialog={(data, type) => { props?.onCreateDialog(data, type); }} />
                <MaterialsCreateView open={openCreateDailog?.open && openCreateDailog?.type === "material"} onClose={() => {
                  setOpenCreateDailog({ ...openCreateDailog, open: false });
                  setName("");
                }} onCreate={(data) => { props?.onCreateDialog(data, "material"); }} name={name} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box >
  );
};

export default TransferDetailView;
