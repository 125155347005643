import { FC, useEffect, useState } from "react";
import { IDistrict, IProvince, ISubDistrict, ITransfer, } from "../Services/TransferService";
import { Autocomplete, Box, Button, ButtonProps, createFilterOptions, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import DialogConfirm from "../../../components/DialogConfirm";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import materialService, { IMaterial, IMaterialInWarehouseCriteria } from "../Services/MaterialsService";
import { IWarehouse } from "../Services/WareshousesService";
import CloseIcon from '@mui/icons-material/Close';
import { documentStatus } from "../../../constants/documentStatus";
import clearState from "../../../utils/resetState";
import { IUserGroup } from "../../SuperAdminManagement/Services/UserService";
import { IUser } from "../../UserManagement/Services/RoleService";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { warehouseDatas } from "../../../constants/dialogCreateInputs";
import DialogCreate from "../../../components/DialogCreate";
import MaterialsCreateView from "./MaterialCreateView";

interface TransferCreateViewProps {
  open: boolean;
  onClose: () => void;
  onCreate: (data: ITransfer) => Promise<void>;
  onUpdate: (data: ITransfer) => Promise<void>;
  id?: string;
  materialData?: IMaterial[];
  workerData?: IUser[];
  warehouseData?: IWarehouse[];
  transfer?: ITransfer;
  materialQuantityInWarehouseFrom?: number;
  materialQuantityInWarehouseTo?: number;
  getMaterialQuantityInWarehouse: (criteria: IMaterialInWarehouseCriteria, warehouseType: string) => Promise<void>;
  userGroup: IUserGroup;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onFetchData: (id?: any, type?: string) => void;
  onCreateDialog: (data: any, type: string) => void;
}
const filterOptions = createFilterOptions<any>();
const TransferCreateView: FC<TransferCreateViewProps> = (props) => {
  const { register, handleSubmit, formState: { errors }, reset, getValues, control, watch } = useForm<ITransfer>({
    defaultValues: {
      docDate: new Date(),
      status: documentStatus.waiting?.value,
    }
  });
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });
  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });
  const [nameType, setNameType] = useState("");
  const [name, setName] = useState("");
  // NOTE: warehouse input
  const _warehouseDatas: any = warehouseDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    name: name,
  }) || [];

  watch();

  const validateStocksDifferently = (value: IWarehouse | null) => {
    const stockFrom = getValues("stockFrom");
    if (value && stockFrom && value.id === stockFrom.id) {
      return "คลังต้นทางและคลังปลายทางต้องไม่เหมือนกัน";
    }
    return true;
  };


  const handleFormSubmit = () => {
    const data = getValues();
    if (props.transfer) {
      props.onUpdate({ ...data, status: openConfirm.type === "delete" ? "rejected" : "approved" });
    } else {
      props.onCreate(data);
    }
    props.onClose();
  };

  const onSubmit = handleSubmit(() => {
    setOpenConfirm({
      open: true,
      message: props.transfer ? "คุณต้องการอนุมัติรายการโยกวัสดุนี้ใช่หรือไม่" : "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
      color: "error",
      title: props.transfer ? "อนุมัติรายการโยกวัสดุ" : "บันทึกข้อมูล",
      type: props.transfer ? "update" : "create",
    });
  });

  const onConfirm = () => {
    handleFormSubmit();
  };

  const onClose = (_isReject?: boolean) => {

    if (_isReject) {
      setOpenConfirm({
        open: true,
        message: "คุณต้องการปฏิเสธรายการโยกวัสดุนี้ใช่หรือไม่",
        color: "error",
        title: "ปฏิเสธรายการโยกวัสดุ",
        type: "delete",
      });
    } else {
      props.onClose();
    }
  };

  const handleOpenCreateDailog = (name: string) => {
    setNameType(name);
    switch (name) {
      case "material": setOpenCreateDailog(
        {
          open: true, datas: [], type: "material", title: "เพิ่มรายการวัสดุ"
        }
      );
        break;
      case "warehouse": setOpenCreateDailog(
        {
          open: true, datas: _warehouseDatas, type: "warehouse", title: "สร้างคลังวัสดุ"
        }
      );
        break;
    }
  };

  useEffect(() => {
    if (props.open) {
      reset({
        docDate: new Date(),
        status: documentStatus.waiting?.value
      });
    }
    if (props.transfer) {
      reset(props.transfer);
    }

    clearState(materialService, ["materialQuantityInWarehouseFrom", "materialQuantityInWarehouseTo"]);

  }, [props.open, reset, props.transfer]);


  useEffect(() => {
    const material = getValues("material");
    const stockFrom = getValues("stockFrom");
    if (material && stockFrom) {
      props.getMaterialQuantityInWarehouse({
        materialId: material.id,
        warehouseId: stockFrom.id?.toString(),
      }, 'stockFrom');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("material"), getValues("stockFrom")]);

  useEffect(() => {
    const material = getValues("material");
    const stockTo = getValues("stockTo");
    if (material && stockTo) {
      props.getMaterialQuantityInWarehouse({
        materialId: material.id,
        warehouseId: stockTo.id?.toString(),
      }, 'stockTo');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("material"), getValues("stockTo")]);

  useEffect(() => {
    handleOpenCreateDailog(nameType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.districtData, props.subDistrictData]);
  return (
    <Dialog open={props.open} fullWidth maxWidth={"lg"}>
      <DialogTitle display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant="h6">{props.transfer ? "อนุมัติรายการโยกวัสดุ" : "สร้างรายการโยกวัสดุ"}</Typography>
        {props.transfer && <IconButton aria-label="delete" size="small" onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>}
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={onSubmit} >
          <Grid container spacing={2} mt={1}>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <Controller
                name="docDate"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                    <DemoContainer components={['DatePicker', 'DatePicker']} >
                      <DatePicker sx={{ width: "100%" }}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            error: !!errors.docDate,
                            helperText: errors.docDate ? "กรุณาระบุวันที่เอกสาร" : "",
                          }
                        }}
                        defaultValue={dayjs(new Date())}
                        label="วันที่เอกสาร"
                        value={field.value ? dayjs(field.value) : dayjs()}
                        onChange={(newValue: any) => {
                          field.onChange(newValue);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4} mt={1}>
              <TextField
                disabled
                {...register("docNo")}
                label="เลขที่เอกสาร"
                fullWidth
              />
            </Grid>
            <Grid item lg={4} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }} >
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Controller
                name="material"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    value={value || null}
                    options={props.materialData || []}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name || "";
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {

                      if (newValue?.id === "create") {
                        handleOpenCreateDailog("material");
                        onChange(null);
                      } else if (newValue?.id) {
                        onChange(newValue);
                        const stockFrom = getValues("stockFrom");
                        const stockTo = getValues("stockTo");
                        if (newValue) {
                          if (stockFrom) {
                            props.getMaterialQuantityInWarehouse({
                              materialId: newValue.id,
                              warehouseId: stockFrom.id?.toString(),
                            }, 'stockFrom');
                          }
                          if (stockTo) {
                            props.getMaterialQuantityInWarehouse({
                              materialId: newValue.id,
                              warehouseId: stockTo.id?.toString(),
                            }, 'stockTo');
                          }
                        }
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filterOptions(options, params);
                      if (params.inputValue !== '') {
                        filtered.push({
                          name: `สร้าง '${params.inputValue}'`,
                          id: 'create',
                        });
                        setName(params.inputValue);
                      }
                      return filtered;
                    }}
                    renderOption={(props, option) => {
                      const { key, ...rest } = props;
                      return (
                        <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                          <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                        </Box> : option.name}</Typography>
                      );
                    }}
                    renderInput={(params) => <TextField
                      error={!!errors.material}
                      helperText={errors.material ? "กรุณาระบุวัสดุ" : ""}
                      {...params} label="วัสดุ" />}
                    clearOnBlur
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <Controller
                name="stockFrom"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    value={value || null}
                    options={props.warehouseData || []}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name || "";
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {
                      if (newValue?.id === "create") {
                        handleOpenCreateDailog("warehouse");
                        onChange(null);
                      } else if (newValue?.id) {
                        onChange(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filterOptions(options, params);
                      if (params.inputValue !== '') {
                        filtered.push({
                          name: `สร้าง '${params.inputValue}'`,
                          id: 'create',
                        });
                        setName(params.inputValue);
                      }
                      return filtered;
                    }}

                    renderOption={(props, option) => {
                      const { key, ...rest } = props;
                      return (
                        <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                          <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                        </Box> : option.name}</Typography>
                      );
                    }}
                    renderInput={(params) => <TextField
                      error={!!errors.stockFrom}
                      helperText={errors.stockFrom ? "กรุณาระบุคลังต้นทาง" : ""}
                      {...params} label="คลังต้นทาง" />}
                  />
                )}
              />
            </Grid>
            {/* TODO: get material by warehouse */}
            <Grid item xs={6} sm={6} md={6} lg={4} display={"flex"} alignItems={"center"}>
              <Typography>
                <b>สินค้าคงคลัง: </b>
                {props.materialQuantityInWarehouseFrom || 0}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <TextField
                fullWidth
                label="จำนวนที่โยกย้าย"
                type="number"
                {...register("quantity", { required: "กรุณาระบุจำนวนที่โยกย้าย", min: { value: 0, message: "จำนวนที่โยกย้ายต้องไม่ติดลบ" }, max: { value: props.materialQuantityInWarehouseFrom || 0, message: "จำนวนที่โยกย้ายต้องไม่เกินจำนวนที่คงคลัง" } })}
                error={!!errors.quantity}
                helperText={errors.quantity ? errors.quantity.message : ""}
                InputLabelProps={{ shrink: getValues("quantity") ? true : undefined }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <Controller
                name="stockTo"
                control={control}
                rules={{ required: { value: true, message: "กรุณาระบุคลังปลายทาง" }, validate: validateStocksDifferently }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    value={value || null}
                    options={props.warehouseData || []}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name || "";
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {
                      if (newValue?.id === "create") {
                        handleOpenCreateDailog("warehouse");
                        onChange(null);
                      } else if (newValue?.id) {
                        onChange(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filterOptions(options, params);
                      if (params.inputValue !== '') {
                        filtered.push({
                          name: `สร้าง '${params.inputValue}'`,
                          id: 'create',
                        });
                        setName(params.inputValue);
                      }
                      return filtered;
                    }}
                    renderOption={(props, option) => {
                      const { key, ...rest } = props;
                      return (
                        <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                          <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                        </Box> : option.name}</Typography>
                      );
                    }}
                    renderInput={(params) => <TextField
                      error={!!errors.stockTo}
                      helperText={errors.stockTo ? errors.stockTo.message : ""}
                      {...params} label="คลังปลายทาง" />}
                  />
                )}
              />
            </Grid>
            {/* TODO: get material by warehouse */}
            <Grid item xs={6} sm={6} md={6} lg={4} display={"flex"} alignItems={"center"}>
              <Typography>
                <b>สินค้าคงคลัง: </b>
                {props.materialQuantityInWarehouseTo || 0}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} display={"flex"} alignItems={"center"}>
              <Typography>
                <b>ผู้ทํารายการ: </b>
                {`${getValues("worker.firstName") || ""} ${getValues("worker.lastName") || ""}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} display={"flex"} alignItems={"center"}>
              <Typography>
                <b>ผู้อนุมัติ/ปฏิเสธ: </b>
                {`${getValues("approver.firstName") || ""} ${getValues("approver.lastName") || ""}`}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} >
              <TextField
                label="หมายเหตุ"
                {...register("note")}
                fullWidth
                multiline
                variant="outlined"
                InputLabelProps={{ shrink: getValues("note") ? true : undefined }}
              />
            </Grid>
          </Grid>

          {(props.transfer?.status === "waiting" || !props.transfer) && <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
            <Divider />
          </Grid>}
        </Box>
      </DialogContent>
      <DialogActions sx={{ mb: 2 }}>
        {(props.transfer?.status === "waiting" || (!props.transfer)) &&
          <>
            <Button onClick={() => onClose(!!props.transfer)} variant={props.transfer ? "contained" : undefined} color={props.transfer ? "error" : "primary"}>
              {props.transfer && props.userGroup?.canApprove ? "ปฏิเสธ" : "ยกเลิก"}
            </Button>
            <Button type="submit" onClick={onSubmit} variant="contained" color={props.transfer ? "success" : "primary"}>
              {props.transfer && props.userGroup?.canApprove ? "อนุมัติ" : "บันทึก"}
            </Button>
          </>}
      </DialogActions>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          setName("");
        }}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          onConfirm();
        }}
      />
      <DialogCreate datas={openCreateDailog?.datas} open={openCreateDailog?.open && openCreateDailog?.type !== "material"} title={openCreateDailog?.title} type={openCreateDailog?.type} onClose={() => {
        setOpenCreateDailog({ ...openCreateDailog, open: false });
        setName("");
      }} onSubmit={(data, type) => { props?.onCreateDialog(data, type); }} onFetchData={(id, type) => {
        props?.onFetchData(id, type);
      }} onCreateDialog={(data, type) => {
        props?.onCreateDialog(data, type);
        setName("");
      }} />
      <MaterialsCreateView open={openCreateDailog?.open && openCreateDailog?.type === "material"} onClose={() => {
        setOpenCreateDailog({ ...openCreateDailog, open: false });
        setName("");
      }} onCreate={(data) => { props?.onCreateDialog(data, "material"); }} name={name} />
    </Dialog>
  );
};

export default TransferCreateView;
