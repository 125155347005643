/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Grid, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, TablePagination } from '@mui/material';
import { rowPerPageOptions } from "../constants/viewOption";

/**
 * TableDataListProps is an interface for TableDataList component
 * @interface
 * @property {T[]} data - The data array
 * @property {number} total - The total number of data
 * @property {Array<{ label: string; align?: 'left' | 'center' | 'right'; render: (row: T) => ReactNode; }>} columns - The columns array
 * @property {ReactNode} actionButtons - The action buttons component
 * @property {(row: T) => ReactNode} rowActions - The row actions component
 * @property {number} page - The page number
 * @property {number} limit - The limit number
 * @property {(page: number) => void} onPageChange - The page change function
 * @property {(rowsPerPage: number) => void} onRowsPerPageChange - The rows per page change function
 * @returns {ReactNode} - React component
 * @version 1.1.0
 */
interface TableDataListProps<T> {
  data: T[];
  total: number;
  columns: Array<{ label: string; align?: 'left' | 'center' | 'right'; render: (row: T) => ReactNode; }>;
  actionButtons?: ReactNode;
  rowActions?: (row: T) => ReactNode;
  totalQuantity?: number | null;
  page: number;
  limit: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
}

const TableDataList = <T,>({
  data,
  total,
  columns,
  actionButtons,
  rowActions,
  totalQuantity,
  page,
  limit,
  onPageChange,
  onRowsPerPageChange,
}: TableDataListProps<T>) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  //NOTE - Resize table container width, Incomplete responsive
  useEffect(() => {
    const element = containerRef.current;
    if (element) {
      const handleResize = () => {
        const newWidth = element.offsetWidth;
        const _containerWidth = newWidth - 20;
        setContainerWidth(_containerWidth < 265 ? 265 : _containerWidth);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [containerRef.current]);


  return (
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={10} md={10} lg={10}>
            <Typography>ทั้งหมด {total} รายการ</Typography>
          </Grid>
          {totalQuantity !== undefined && totalQuantity !== null ?
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <Typography color="text.primary">
                {`จำนวนทั้งหมด: ${totalQuantity.toFixed(2)}`}
              </Typography>
            </Grid> :
            <Grid item xs={12} sm={2} md={2} lg={2} style={{ textAlign: 'end' }}>
              {actionButtons}
            </Grid>}
        </Grid>
      </Grid>
      <Grid item xs={12} ref={containerRef}>
        <TableContainer component={Paper} sx={{ maxWidth: `${containerWidth}px` }}>
          <Table >
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index} align={column.align || 'center'}>
                    {column.label}
                  </TableCell>
                ))}
                {rowActions && <TableCell align="center"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data.map((row, rowIndex) => (
                  <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {columns.map((column, colIndex) => (
                      <TableCell key={colIndex} align={column.align || 'center'}>
                        {column.render(row)}
                      </TableCell>
                    ))}
                    {rowActions && (
                      <TableCell align="center">
                        {rowActions(row)}
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns?.length + (rowActions ? 1 : 0)} align="center">
                    <Typography>ไม่พบข้อมูล</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          <TablePagination
            component="div"
            rowsPerPageOptions={rowPerPageOptions}
            count={total ?? 0}
            page={page}
            rowsPerPage={limit}
            onPageChange={(e, newPage) => onPageChange(newPage)}
            onRowsPerPageChange={(e) => onRowsPerPageChange(parseInt(e.target.value, 10))}
          />
        </Box>
      </Grid>

    </Grid>
  );
};

export default TableDataList;
