import { FC, useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Box, Breadcrumbs, Button, ButtonProps, Divider, FormControl, Grid, IconButton, TextField, Typography, Link, Card, CardContent, Stack } from "@mui/material";
import { IRandomInspection } from "../Services/RandomInspectionService";
import DeleteIcon from '@mui/icons-material/Delete';
import styled from "@emotion/styled";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ImagePreview from "../../../components/ImagePreview";
import { IUploadFile } from "../../Company/Services/CompanyService";
import { IOrder } from "../Services/OrderService";

import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DialogConfirm from "../../../components/DialogConfirm";
import { IUserLogined } from "../../Login/Services/LoginService";
import { IFarmProductReceipt } from "../Services/FarmProductReceipt";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";



interface RandomInspectionCreateViewProps {
  onCreate: (data: IRandomInspection, uploadFile: IUploadFile) => void;
  onUpdate: (id: string, data: IRandomInspection, uploadFile: IUploadFile[], deleteFile: IUploadFile[]) => void;
  randomInspection: IRandomInspection;
  onDelete: (data: IRandomInspection) => void;
  order: IOrder[];
  permission: IUserDetail;
  randomInspectionId: string;
  isLoading: boolean;
  user: IUserLogined;
}

const RandomInspectionCreateView: FC<RandomInspectionCreateViewProps> = (props) => {
  const userGroup = props.user.userGroup;
  const { register, handleSubmit, reset, getValues, setValue, control, formState: { errors }, watch } = useForm<IRandomInspection>();

  const [actualWeight, setActualWeight] = useState(0);
  const [totalInspectionWeight, setTotalInspectionWeight] = useState(0);
  const [totalEliminate, setTotalEliminate] = useState(0);
  const [claimsWeight, setClaimsWeight] = useState(0);
  const [cutPoleWeight, setCutPoleWeight] = useState(0);
  const [outWeight, setOutWeight] = useState(0);
  const [farmReceipt, setFarmReceipt] = useState<IFarmProductReceipt[]>();
  const [selectedFarmReceipt, setSelectedFarmReceipt] = useState<IFarmProductReceipt | null>(null);



  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any>([]);
  const [openImage, setOpenImage] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { from } = location.state || { from: null };
  const isFromStatus = from === "status" || from === "order";
  const isDisable = isFromStatus ? true : false;
  const filteredFarmReceipts = farmReceipt?.filter(receipt => receipt.status === 'approved') || [];
  const order = watch('order');


  useEffect(() => {
    if (props.randomInspection && props.randomInspectionId) {
      reset(props.randomInspection);
      setImages(props.randomInspection.image || []);

      const selectedOrder = props.order.find(order => order.id === props.randomInspection.order?.id);
      if (selectedOrder) {
        setFarmReceipt(selectedOrder.farmProductReceipts || []);
        const selectedReceipt = selectedOrder.farmProductReceipts?.find(receipt => receipt.id === props.randomInspection.farm?.id) || null;
        setSelectedFarmReceipt(selectedReceipt);
      } else {
        setFarmReceipt([]);
        setSelectedFarmReceipt(null);
      }
    } else {
      reset();
      setFarmReceipt([]);
      setSelectedFarmReceipt(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.randomInspection, props.randomInspectionId, reset, props.order]);

  useEffect(() => {
    const totalEliminate = Number(claimsWeight) + Number(totalInspectionWeight) + Number(cutPoleWeight) + Number(outWeight);
    setTotalEliminate(totalEliminate);

    const remainingWeight = Number(actualWeight) - totalEliminate;
    setValue("totalRemainingWeight", remainingWeight);

    const price = (Number(selectedFarmReceipt?.pricePerKg) || 0) * totalEliminate;
    setValue("lostM", price);
  }, [actualWeight, claimsWeight, totalInspectionWeight, selectedFarmReceipt?.pricePerKg, setValue, cutPoleWeight, outWeight]);


  useEffect(() => {
    if (props.randomInspectionId) {
      const { actualWeight, claimsWeight, totalInspectionWeight, cutPoleWeight, out } = props.randomInspection;
      setActualWeight(actualWeight || 0);
      setClaimsWeight(claimsWeight || 0);
      setCutPoleWeight(cutPoleWeight || 0);
      setOutWeight(out || 0);
      setTotalInspectionWeight(totalInspectionWeight || 0);
    }
  }, [props.randomInspectionId, props.randomInspection]);

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });


  const onSubmit = (type: "create" | "delete" | "approve" | "reject") => {
    if (type === "delete") {
      setOpenConfirm({
        open: true,
        title: "ลบข้อมูล",
        message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
        color: "error",
        type: "delete",
      });
    } else {
      handleSubmit(() => {
        setOpenConfirm({
          open: true,
          title: isFromStatus ? type === 'reject' ? "ปฏิเสธรายการ" : "อนุมัติรายการ" : "บันทึกข้อมูล",
          message: isFromStatus ? type === 'reject' ? "คุณต้องการปฏิเสธรายการสุ่มผ่านี้ใช่หรือไม่" : "คุณต้องการอนุมัติรายการสุ่มผ่านี้ใช่หรือไม่" : "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: type === "reject" ? "error" : "primary",
          type: "create",
        });
      })();
    }
  };

  const onFormSubmit = (data: IRandomInspection) => {
    const processedData = {
      ...data,
      status: isFromStatus ? isReject ? "rejected" : "approved" : "waiting",
      claimsWeight: getValues("claimsWeight") || 0,
      totalInspectionWeight: getValues("totalInspectionWeight") || 0,
      cutPoleWeight: getValues("cutPoleWeight") || 0,
      out: getValues("out") || 0,
    };

    if (props.randomInspectionId) {
      props.onUpdate(props.randomInspectionId, processedData, selectedFiles, deleteImages);
    } else {
      props.onCreate(processedData, selectedFiles);
    }
    setIsReject(false);
  };



  const onDelete = () => {
    const data = getValues();
    props.onDelete({ ...data, isDelete: true });
  };

  const onReject = () => {
    setIsReject(true);
    onSubmit("reject");
  };

  const onApprove = () => {
    setIsReject(false);
    onSubmit("approve");
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles([...selectedFiles, ...Array.from(files)]);
    }
  };

  const handleFileRemove = (index: number) => {
    const _selectedFiles = [...selectedFiles];
    _selectedFiles.splice(index, 1);
    setSelectedFiles(_selectedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const _images = [...images];
    const index = _images.findIndex((img) => img.name === file.name);
    if (index > -1) {
      const deletedImage = _images[index];
      setDeleteImages([...deleteImages, { id: deletedImage.id, name: deletedImage.name }]);


      _images.splice(index, 1);
      setImages(_images);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const onImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setOpenImage(true);
  };

  return (
    <Box component={'div'}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="back"
            onClick={() => navigate("/process-random-inspection")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          {/* NOTE: check from order or not */}
          {from === "order" ? <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ตรวจสอบคุณภาพ</Typography>
            <Link component="button" onClick={() => navigate(`/process-order-edit/${order?.id}`)}>
              ใบคำสั่งซื้อ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.randomInspectionId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs> : <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ตรวจสอบคุณภาพ</Typography>
            <Link component="button" onClick={() => navigate("/process-random-inspection")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.randomInspectionId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>}
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.randomInspectionId && props.permission.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onSubmit("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              {props.randomInspectionId && <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                <Typography variant="h6">{props.randomInspection?.docNo}</Typography>
              </Grid>}
              <Box component="form" onSubmit={handleSubmit(onFormSubmit)} mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth error={!!errors.order}>
                      <Controller
                        name="order"
                        control={control}
                        rules={{ required: 'กรุณาเลือกใบคำสั่งซื้อ' }}
                        render={({ field }) => (
                          <>
                            <Autocomplete
                              id="order-autocomplete"
                              options={props.order}
                              getOptionLabel={(option) => option.invoiceNo || ""}
                              value={field.value || null}
                              onChange={(event, newValue) => {
                                field.onChange(newValue);
                                if (newValue?.farmProductReceipts) {
                                  setFarmReceipt(newValue?.farmProductReceipts || undefined);

                                  setSelectedFarmReceipt(null);
                                }
                              }}
                              disabled={isDisable}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="ใบคำสั่งซื้อ"
                                  error={!!errors.order}
                                  helperText={errors.order ? errors.order.message : ''}
                                />
                              )}
                            />
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth error={!!errors.farm}>
                      <Controller
                        name="farm"
                        control={control}
                        rules={{ required: 'กรุณาเลือกเลขที่รับจากสวน' }}
                        render={({ field }) => (
                          <>
                            <Autocomplete
                              id="receive-autocomplete"
                              options={filteredFarmReceipts}
                              getOptionLabel={(option) => option.docNo || ""}
                              value={field.value || null}
                              onChange={(event, newValue) => {
                                field.onChange(newValue);
                                setSelectedFarmReceipt(newValue || null);
                              }}
                              disabled={isDisable}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="เลขที่รับจากสวน"
                                  error={!!errors.farm}
                                  helperText={errors.farm ? errors.farm.message : ''}
                                />
                              )}
                            />

                          </>
                        )}
                      />
                    </FormControl>

                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Box
                      sx={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px'
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>รับโดย:</span> {selectedFarmReceipt?.createBy?.firstName ?? "N/A"} {selectedFarmReceipt?.createBy?.lastName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>โรงอบ:</span> {selectedFarmReceipt?.vapourHeatTreatment?.name ?? "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>น้ำหนักทั้งหมด:</span> {thaiFormatNumber(selectedFarmReceipt?.totalWeight?.toString() || '0') ?? 0} กก.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>น้ำหนักหลังหักตะกร้า:</span> {thaiFormatNumber(selectedFarmReceipt?.netWeight?.toString() || '0') ?? 0} กก.
                          </Typography>
                        </Grid>
                        {
                          userGroup?.canCost && (
                            <Grid item xs={12} sm={6} md={4}>
                              <Typography variant="body1">
                                <span style={{ fontWeight: 'bold' }}>ราคาต่อกิโล:</span> {thaiFormatNumber(selectedFarmReceipt?.pricePerKg?.toString() || '0') ?? 0} บาท
                              </Typography>
                            </Grid>
                          )
                        }

                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isDisable}
                        fullWidth
                        type="number"
                        label="น้ำหนักจริง (Kg)"
                        {...register("actualWeight", {
                          required: "กรุณาระบุน้ำหนักจริง",
                          onChange: (e) => setActualWeight(parseFloat(e.target.value) || 0)
                        })}
                        error={!!errors.actualWeight}
                        helperText={errors.actualWeight ? errors.actualWeight.message : ""}
                        InputLabelProps={{ shrink: getValues("actualWeight") ? true : undefined }}
                        InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isDisable}
                        fullWidth
                        type="number"
                        label="น้ำหนักคัดออก (Kg)"
                        {...register("out", {
                          onChange: (e) => setOutWeight(parseFloat(e.target.value) || 0)
                        })}
                        InputLabelProps={{ shrink: getValues("out") ? true : undefined }}
                        InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isDisable}
                        fullWidth
                        type="number"
                        label="น้ำหนักคืนสวน (Kg)"
                        {...register("claimsWeight", {
                          onChange: (e) => setClaimsWeight(parseFloat(e.target.value) || 0)
                        })}
                        InputLabelProps={{ shrink: getValues("claimsWeight") ? true : undefined }}
                        InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isDisable}
                        fullWidth
                        type="number"
                        label="น้ำหนักสุ่มผ่า (Kg)"
                        {...register("totalInspectionWeight", {
                          onChange: (e) => setTotalInspectionWeight(parseFloat(e.target.value) || 0)
                        })}
                        InputLabelProps={{ shrink: getValues("totalInspectionWeight") ? true : undefined }}

                        InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                      />

                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isDisable}
                        fullWidth
                        type="number"
                        label="น้ำหนักขั้วที่ตัดทิ้ง (Kg)"
                        {...register("cutPoleWeight", {
                          onChange: (e) => setCutPoleWeight(parseFloat(e.target.value) || 0)
                        })}
                        InputLabelProps={{ shrink: getValues("cutPoleWeight") ? true : undefined }}

                        InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={7} md={7} lg={7}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography>เอกสาร</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button component="label" variant="outlined" disabled={isDisable} startIcon={<UploadFileIcon />}>
                          เลือกไฟล์
                          <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png" multiple onChange={handleFileChange} />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {selectedFiles.length > 0 && (
                          <Box component={'div'} mt={1}>
                            {selectedFiles.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`sf-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small" color="error" onClick={() => handleFileRemove(index)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                      style={{ maxWidth: "50%", height: "auto", cursor: "pointer" }}
                                      onClick={() => onImageClick(URL.createObjectURL(file))}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {images && (
                          <Box component={'div'} mt={1}>
                            {images.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`im-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small"
                                      color="error" onClick={() => handleFileRemoveImageList(file)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/uploads/${(file).name
                                        }`}
                                      alt="Selected"
                                      style={{ maxWidth: "50%", height: "auto", cursor: 'pointer' }}
                                      onClick={() => onImageClick(`${process.env.REACT_APP_API_URL}/uploads/${(file).name}`)}

                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={5} md={5} lg={5} mt={1} justifyContent={'flex-end'}>
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography ><b>น้ำหนักจริง (Kg)</b></Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography >
                          <b> {thaiFormatNumber(parseFloat(actualWeight.toString()).toFixed(2))}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography color={'error'}>น้ำหนักที่คัดออก (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography color={'error'}>
                          {thaiFormatNumber(parseFloat(outWeight.toString()).toFixed(2))}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography color={'error'}>น้ำหนักคืนสวน (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography color={'error'}>
                          {thaiFormatNumber(parseFloat(claimsWeight.toString()).toFixed(2))}
                        </Typography>
                      </Grid>

                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography color={'error'}>น้ำหนักที่ใช้ในการสุ่มผ่า (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography color={'error'}>
                          {thaiFormatNumber(parseFloat(totalInspectionWeight.toString()).toFixed(2))}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography color={'error'}>น้ำหนักขั้วที่ตัดทิ้ง (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography color={'error'}>
                          {thaiFormatNumber(parseFloat(cutPoleWeight.toString()).toFixed(2))}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography color={'error'}><b>รวมน้ำหนักคัดออก (Kg)</b></Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography color={'error'}>
                          <b> {thaiFormatNumber(parseFloat(totalEliminate.toString()).toFixed(2))}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography ><b>น้ำหนักสุทธิ (Kg)</b></Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Controller
                          name="totalRemainingWeight"
                          control={control}
                          defaultValue={0}
                          render={({ field }) => (
                            <Typography>
                              <b>{thaiFormatNumber(parseFloat(field.value?.toString() || '0').toFixed(2))}</b>
                            </Typography>
                          )}
                        />

                      </Grid>
                      {
                        userGroup?.canFinance && (
                          <>
                            <Grid item xs={8} sm={8} md={8} lg={8}>
                              <Typography >มูลค่าที่สูญเสีย (บาท)</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                              <Controller
                                name="lostM"
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                  <Typography>
                                    <b>{thaiFormatNumber(parseFloat(field.value?.toString() || '0').toFixed(2))}</b>
                                  </Typography>
                                )}
                              />
                            </Grid>
                          </>
                        )
                      }


                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid container spacing={2} >
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                          <Typography>
                            <b>ผู้ทํารายการ: </b>
                            {props.randomInspection?.createBy?.firstName} {props.randomInspection?.createBy?.lastName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                          <Typography>
                            <b>ผู้อนุมัติ/ปฏิเสธ: </b>
                            {props.randomInspection?.approveBy?.firstName} {props.randomInspection?.approveBy?.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} justifyContent={"end"}>
                        <Stack spacing={2} direction="row">
                          {
                            isFromStatus ? (
                              <>
                                {
                                  userGroup?.canApprove && props.randomInspection?.status === "waiting" ?
                                    <>
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        color="error"
                                        onClick={onReject}
                                      >
                                        ปฏิเสธ
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                        onClick={onApprove}
                                        style={{ marginLeft: '8px' }}
                                      >
                                        อนุมัติ
                                      </Button></> :
                                    null
                                }

                              </>
                            ) :
                              <>
                                {
                                  props.randomInspection?.status === "waiting" || from === "create" ? <>  <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => navigate("/process-random-inspection")}
                                  >
                                    ยกเลิก
                                  </Button>
                                    {props.permission.create || (props.permission.edit && props.randomInspection) ? (
                                      <Button
                                        id="submit"
                                        variant="contained"
                                        onClick={() => onSubmit("create")}
                                      >
                                        บันทึก
                                      </Button>
                                    ) : null}
                                  </> :
                                    null
                                }

                              </>
                          }
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            handleSubmit(onFormSubmit)();
          }
        }}
      />
      <ImagePreview
        open={openImage}
        imageSrc={selectedImage}
        onClose={() => setOpenImage(false)}
      />
    </Box >
  );
};

export default RandomInspectionCreateView;