import { observer } from "mobx-react-lite";
import WarehousesView from "../Views/WarehousesView";
import { useEffect, useState } from "react";
import wareshousesService, { IWarehouse, IWarehouseCriteria, IWarehouses } from "../Services/WareshousesService";
import companyService from "../../Company/Services/CompanyService";
import { enqueueSnackbar } from "notistack";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";

const WarehousesController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [warehouseData, setWarehouseData] = useState<IWarehouses>();
  const defaultCriteria: IWarehouseCriteria = {
    name: "",
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getProvince();
    getWarehousesByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProvince = async () => {
    try {
      setIsLoading(true);
      await companyService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDistrict = async (id?: number) => {
    if (!id) return;
    try {
      setIsLoading(true);
      await companyService.getDistrict(id);
    } catch (error) {
      console.error("An error occurred while getting the district:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSubDistrict = async (id?: number) => {
    if (!id) return;
    try {
      setIsLoading(true);
      await companyService.getSubDistrict(id);
    } catch (error) {
      console.error("An error occurred while getting the sub-district:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehousesByCriteria = async (criteria: IWarehouseCriteria) => {
    try {
      setIsLoading(true);
      await wareshousesService.getByCriteria(criteria).then((data) => {
        data?.datas?.sort((a: IWarehouse, b: IWarehouse) => (b.createdAt?.toString() ?? '').localeCompare(a.createdAt?.toString() ?? ''));
        setWarehouseData(data);
      });
    } catch (error) {
      console.error("An error occurred while getting the warehouses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (data: IWarehouse) => {
    try {
      setIsLoading(true);
      const res = await wareshousesService.create(data);
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      getWarehousesByCriteria(defaultCriteria);
    } catch (error) {
      console.error("An error occurred while creating the warehouse:", error);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: IWarehouse) => {
    try {
      setIsLoading(true);
      const res = await wareshousesService.update(data);
      if (res) {
        enqueueSnackbar("อัพเดทข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("อัพเดทข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      getWarehousesByCriteria(defaultCriteria);
    } catch (error) {
      console.error("An error occurred while updating the warehouse:", error);
      enqueueSnackbar("อัพเดทข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("inventory-warehouses");
    if (result) {
      setPermission(result);
    }
  };

  return <WarehousesView
    isLoading={isLoading}
    warehouses={warehouseData ?? {} as IWarehouses}
    warehouse={wareshousesService.warehouseData ?? {} as IWarehouse}
    onSerchByCriteria={getWarehousesByCriteria}
    onCreate={create}
    onUpdate={update}
    provinceData={companyService.provinceData || []}
    districtData={companyService.districtData || []}
    subDistrictData={companyService.subDistrictData || []}
    onFetchDistrict={getDistrict}
    onFetchSubDistrict={getSubDistrict}
    permission={permission ?? {} as IUserDetail}
  />;
});

export default WarehousesController;