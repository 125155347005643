import { FC, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { IBakeCriteria, IBakeListView } from "../Services/BakeService";
import { documentStatus } from "../../../constants/documentStatus";
import { useNavigate } from "react-router-dom";
import { IBake } from "../Services/BakeService";
import dayjs from "dayjs";
import { Box, Breadcrumbs, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Controller, useForm } from "react-hook-form";
import ListViewPage from "../../../components/ListViewPage";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import TableDataList from "../../../components/TableDataList";
import SearchIcon from "@mui/icons-material/Search";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";

interface BakeListViewProps {
  isLoading: boolean;
  bakeList: IBakeListView;
  onSearchByCriteria: (critery: IBakeCriteria) => void;
  permission: IUserDetail;
}

const BakeListView: FC<BakeListViewProps> = (props) => {
  const { register, getValues, control } = useForm<IBakeCriteria>({
    defaultValues: {
      dateFrom: dayjs().startOf('month').toDate(),
      dateTo: dayjs().toDate(),
      docNo: '',
      status: 'all',
      page: 0,
      limit: 10,
    },
  });
  const status = Object.values(documentStatus);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const navigate = useNavigate();
  const column = [
    { label: 'วันที่เอกสาร', render: (row: IBake) => dayjs(row.createAt).format('DD/MM/YYYY') },
    { label: 'เลขที่เอกสาร', render: (row: IBake) => row.docNo },
    { label: 'ราคาอบสุทธิ', render: (row: IBake) => thaiFormatNumber(row.totalVht?.toString() || '0') || 0 },
    {
      label: 'สถานะ', render: (row: IBake) => {
        const status = row.status !== undefined ? documentStatus[row.status] : { name: "default", color: "default" };
        return (
          <Chip
            label={status.name}
            color={status.color as "default"}
            onClick={() => {
              navigate(`/process-bake-edit/${row.id}`, { state: { from: 'status' } });
            }}
          />
        );
      }
    },
  ];

  const actionRow = (row: IBake) => (
    <IconButton
      onClick={() => {
        navigate(`/process-bake-edit/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        openCreate();
      }}>
      สร้าง
    </Button>
  ) : <></>;

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const data = getValues();
    props.onSearchByCriteria({ ...data, page: pageNumber, limit: rowLimit ? rowLimit : 10 });
  };

  const openCreate = () => {
    navigate("/process-bake-create");
  };

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>อบ</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Box component="form">
            <Grid container spacing={2} alignItems={"end"}>
              <Grid item xs={12} sm={10} md={10} lg={10} >
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Controller
                      name="dateFrom"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker sx={{ width: "100%" }}
                              defaultValue={dayjs().startOf('month')}
                              label="ตั้งแต่วันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Controller
                      name="dateTo"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker
                              sx={{ width: "100%" }}
                              defaultValue={dayjs()}
                              label="ถึงวันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} alignSelf={'flex-end'}>
                    <TextField
                      {...register("docNo")}
                      label="เลขที่เอกสาร"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} alignSelf={'flex-end'}>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id="status-select-label">สถานะ</InputLabel>

                          <Select
                            labelId="status-select-label"
                            value={field.value || "all"}
                            onChange={(e) => field.onChange(e.target.value)}
                            label="สถานะ"
                          >                      {status.map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.name}
                            </MenuItem>
                          ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"end"}>
                <Button
                  onClick={() => searchByCriteria()}
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      }
      table={
        <> <TableDataList
          data={props.bakeList.datas || []}
          total={props.bakeList.total}
          columns={column}
          actionButtons={actionButtons}
          rowActions={actionRow}
          page={page}
          limit={limit}
          onPageChange={(newPage) => {
            searchByCriteria(newPage);
          }}
          onRowsPerPageChange={(newLimit) => {
            setLimit(newLimit);
            setPage(0);
            searchByCriteria(0, newLimit);
          }}
        />

        </>
      }
    />
  );
};

export default BakeListView;