import { FC, useEffect, useState } from "react";
import { IFarmExpenseDetail, IFarmProductReceipt, ITransportRefference } from "../Services/FarmProductReceipt";
import { IUploadFile } from "../../Company/Services/CompanyService";
import { Box, Breadcrumbs, Grid, IconButton, Typography, Link, CardContent, Card, Button, Autocomplete, TextField, FormControl, Divider, styled, Stack, ButtonProps, FormControlLabel, Radio, RadioGroup, useMediaQuery, createFilterOptions } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IUserDetail, IUserSuperAdmin } from "../../SuperAdminManagement/Services/UserService";
import DeleteIcon from "@mui/icons-material/Delete";
import { IOrder } from "../Services/OrderService";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DialogConfirm from "../../../components/DialogConfirm";
import ImagePreview from "../../../components/ImagePreview";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import { IDistrict, IProduct, IProvince, ISubDistrict } from "../Services/ProductService";
import { IVapourHeatTreatment } from "../Services/VapourHeatTreatmentService";
import { IGrade } from "../Services/GradeService";
import { IDriver } from "../../Transport/Services/DriverService";
import { ICar } from "../../Transport/Services/CarService";
import { IExpenseType } from "../../Expense/Services/ExpenseTypeService";
import { IUserLogined } from "../../Login/Services/LoginService";
import { theme } from "../../../themes";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuidv4 } from "uuid";
import { ISupplierType } from "../../Supplier/Services/SupplierTypeService";
import DialogCreate from "../../../components/DialogCreate";
import { supplierDatas, vapourHeatTreatmentDatas } from "../../../constants/dialogCreateInputs";
import CarCreateView from "../../Transport/Views/CarCreateView";
import DriverCreateView from "../../Transport/Views/DriverCreateView";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
import ExpenseTypeCreateView from "../../Expense/Views/ExpenseTypeCreateView";

interface FarmProductReceiptCreateViewProps {
  orders: IOrder[];
  farmProductReceipt: IFarmProductReceipt;
  permission: IUserDetail;
  suppliers: ISupplier[];
  grades: IGrade[];
  drivers: IDriver[];
  cars: ICar[];
  expenseTypes: IExpenseType[];
  vapourHeatTreatmentData: IVapourHeatTreatment[];
  onCreate: (data: IFarmProductReceipt, fileUpload: IUploadFile) => void;
  onUpdate: (data: IFarmProductReceipt, oldImage: IUploadFile[], fileUpload: IUploadFile[], deleteFile: IUploadFile[]) => void;
  onRemove: (id: string) => void;
  isLoading: boolean;
  user: IUserLogined;
  users: IUserSuperAdmin[];
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onCreateDialog: (data: any, type: string) => void;
  onFetchData: (id?: any, type?: string) => void;
  supplierTypesOptions?: ISupplierType[];
  // ประเภทค่าใช้จ่าย
  onCreateExpenseType: (data: IExpenseType) => void;
}
const filterOptions = createFilterOptions<any>();
const FarmProductReceiptCreateView: FC<FarmProductReceiptCreateViewProps> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const userGroup = props.user?.userGroup;
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: null };
  const isFromStatus = from === "status" || from === "order";
  const isDisabled = isFromStatus ? true : false;
  const [openExpenseType, setOpenExpenseType] = useState(false);
  const [name, setName] = useState("");

  const { handleSubmit, setValue, reset, getValues, watch, formState: { errors }, control } = useForm<IFarmProductReceipt>({
    defaultValues: {
      docDate: new Date(),
      depositDate: new Date(),
      remainPaymentDate: new Date(),
      farmExpenseDetail: [{
        id: uuidv4(),
        type: 1,
        amount: 0,
        payDate: new Date(),
        payBy: props.user
      }]
    }
  });

  const watchData = watch('farmExpenseDetail');
  watch();

  const { remove, fields, append } = useFieldArray({
    control,
    name: "farmExpenseDetail"
  });

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });
  const isDisable = isFromStatus ? true : false;
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any>([]);
  const [productList, setProductList] = useState<IProduct[]>();
  const [transportRefList, setTransportRefList] = useState<ITransportRefference[]>();
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isReject, setIsReject] = useState(false);
  const [totalBasketWeight, setTotalBasketWeight] = useState(0);
  const order = watch('order');
  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });
  const [nameType, setNameType] = useState("");
  // NOTE: supplier input
  const datasInChildCreateDialog = [{ type: "text", label: "ประเภท", name: "name", xs: 12, sm: 12, md: 12, lg: 12, defaultValue: "" },];
  const _supplierDatas: any = supplierDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    datasInChildCreateDialog: datasInChildCreateDialog || [],
    supplierTypesOptions: props?.supplierTypesOptions || [],
    name: name
  }) || [];

  const _vapourHeatTreatmentDatas: any = vapourHeatTreatmentDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    name: name
  }) || [];

  const setData = (data: IFarmProductReceipt) => {
    if (data) {
      if (data.order && data.order.product) {
        if (Array.isArray(data.order.product)) {
          setProductList(data.order.product);
        } else {
          setProductList([data.order.product]);
        }
      }
      setImages(data.image ?? []);
    }
    reset(data);
    calNetTotalWeight();
  };

  const calNetTotalWeight = () => {
    const totalWeight = getValues('totalWeight') || 0;
    const emptyBasketWeight = getValues('emptyBasketWeight') || 0;
    const totalBasket = getValues('basketAmount') || 0;
    const totalEmplyBasketWeight = Number(emptyBasketWeight) * Number(totalBasket);
    setTotalBasketWeight(totalEmplyBasketWeight);
    setValue('netWeight', totalWeight - totalEmplyBasketWeight);
  };

  const handleSave = handleSubmit(() => {
    if (props.farmProductReceipt.id) {
      setOpenConfirm({
        open: true,
        title: "แก้ไขข้อมูล",
        message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "update",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const handleDelete = () => {
    setOpenConfirm({
      open: true,
      title: "ลบข้อมูล",
      message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
      color: "error",
      type: "delete",
    });
  };


  const onDelete = () => {
    if (props.farmProductReceipt.id) {
      props.onRemove(props.farmProductReceipt.id);
    }

  };

  const onSubmit = () => {
    const value = getValues();
    if (props.farmProductReceipt.id) {
      props.onUpdate({ ...value, status: isFromStatus ? isReject ? "rejected" : "approved" : "waiting" }, images, selectedFiles, deleteImages);
    }
    else {
      props.onCreate(value, selectedFiles);
    }
    setIsReject(false);
  };

  const calGrandTotal = () => {
    const deposit = getValues('deposit') || 0;
    const totalRemain = getValues('totalRemain') || 0;
    setValue('grandTotal', (Number(deposit) + Number(totalRemain)));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles([...selectedFiles, ...Array.from(files)]);
    }
  };

  const handleFileRemove = (index: number) => {
    const _selectedFiles = [...selectedFiles];
    _selectedFiles.splice(index, 1);
    setSelectedFiles(_selectedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const _images = [...images];
    const index = _images.findIndex((img) => img.name === file.name);
    if (index > -1) {
      const deletedImage = _images[index];
      setDeleteImages([...deleteImages, { id: deletedImage.id, name: deletedImage.name }]);
      _images.splice(index, 1);
      setImages(_images);
    }
  };


  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const onImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setOpenImage(true);
  };

  const onApprove = () => {
    setOpenConfirm({
      open: true,
      title: "อนุมัติเอกสาร",
      message: "คุณต้องการอนุมัติข้อมูลรับจากสวนนี้ใช่หรือไม่",
      color: "primary",
      type: "update",
    });
    setIsReject(false);
  };

  const onReject = () => {
    setOpenConfirm({
      open: true,
      title: "ปฏิเสธเอกสาร",
      message: "คุณต้องการปฏิเสธข้อมูลรับจากสวนนี้ใช่หรือไม่",
      color: "error",
      type: "update",
    });
    setIsReject(true);
  };

  const handleTransportRef = (data: IFarmProductReceipt[]) => {
    if (props.farmProductReceipt.id) {
      const transportRef = data.filter((item) => item.id !== props.farmProductReceipt.id);
      setTransportRefList(transportRef);
    } else {
      setTransportRefList(data);
    }
  };

  const calTotalBasket = () => {
    const totalWeight = getValues('totalWeight') || 0;
    const weightPerBasket = getValues('weightPerBasket') || 0;
    const basketAmount = weightPerBasket === 0 ? 0 : Number(totalWeight) / Number(weightPerBasket);
    setValue('basketAmount', Math.floor(basketAmount));

  };

  const handleOpenCreateDailog = (name: string) => {
    setNameType(name);
    switch (name) {
      case "agent": setOpenCreateDailog(
        {
          open: true, datas: _supplierDatas, type: "agent", title: "สร้าง Supplier"
        }
      );
        break;
      case "supplierType": setOpenCreateDailog(
        {
          open: true, datas: [], type: "supplierType", title: "สร้างประเภท Supplier"
        }
      );
        break;
      case "vapourHeatTreatment": setOpenCreateDailog(
        {
          open: true, datas: _vapourHeatTreatmentDatas, type: "vapourHeatTreatment", title: "สร้างโรงอบ"
        }
      );
        break;
      case "car": setOpenCreateDailog(
        {
          open: true, datas: [], type: "car", title: "สร้างข้อมูลรถ"
        }
      );
        break;
      case "driver": setOpenCreateDailog(
        {
          open: true, datas: [], type: "driver", title: "สร้างคนขับ"
        }
      );
        break;
    }
  };

  useEffect(() => {
    if (props.farmProductReceipt && props.farmProductReceipt.id) {
      setData(props.farmProductReceipt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.farmProductReceipt]);

  useEffect(() => {
    handleOpenCreateDailog(nameType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.districtData, props.subDistrictData, props.supplierTypesOptions, props.supplierTypesOptions]);

  return (
    <Box component={'div'}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="back"
            onClick={() => navigate("/process-product-receipt")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          {/* NOTE: check from order or not */}
          {from === "order" ? <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>รับจากสวน</Typography>
            <Link component="button" onClick={() => navigate(`/process-order-edit/${order?.id}`)}>
              ใบคำสั่งซื้อ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.farmProductReceipt.id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs> :
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Typography>รับจากสวน</Typography>
              <Link component="button" onClick={() => navigate("/process-product-receipt")}>
                รายการ
              </Link>
              <Typography variant="h6" color="text.primary">
                {props.farmProductReceipt.id ? "แก้ไข" : "สร้าง"}
              </Typography>
            </Breadcrumbs>
          }
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.farmProductReceipt?.id && props.permission.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                handleDelete();
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                {props.farmProductReceipt?.id && <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                  <Typography variant="h6">{props.farmProductReceipt?.docNo}</Typography>
                </Grid>}
              </Grid>
              <Box component="form" mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          name="docDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              disabled={isDisabled}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  error: !!errors.docDate,
                                  helperText: errors.docDate ? "กรุณาระบุวันที่" : "",
                                }
                              }}
                              label="วันที่"
                              format="DD/MM/YYYY"
                              {...field}
                              defaultValue={dayjs(new Date())}
                              value={field.value ? dayjs(field.value) : dayjs(new Date())}
                              onChange={(date) => field.onChange(date)}

                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="order"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisabled}
                          value={field.value as IOrder || null}
                          options={props.orders ?? []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.invoiceNo || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              field.onChange(newValue);
                              // TODO : กลับมา handle ตอน rewrite order
                              const product = newValue.product;
                              if (product) {
                                if (Array.isArray(product)) {
                                  setProductList(product);
                                } else {
                                  setProductList([product]);
                                }
                              } else {
                                setProductList([]);
                              }
                              if (newValue.farmProductReceipts) {
                                handleTransportRef(newValue.farmProductReceipts);
                              }
                            } else {
                              field.onChange(null);
                              setProductList([]);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="ใบคำสั่งซื้อ"
                              error={!!errors.order}
                              helperText={errors.order ? "กรุณาเลือกใบคำสั่งซื้อ" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Box
                      sx={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px'
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>วันที่:</span> {order?.dateFrom ? `${dayjs(order?.dateFrom).format("DD/MM/YYYY") || ""} - ${dayjs(order?.dateTo).format("DD/MM/YYYY") || ""}` : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>ชื่อโปรเจค:</span> {order?.name || "-"}                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>สินค้า:</span> {order?.product?.name || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>รายการสินค้า:</span>
                          </Typography>
                        </Grid>
                        {
                          order?.orderDetails?.map((orderDetail, orderDetailIndex) => (
                            <>
                              <Grid item xs={12} sm={12} md={12} key={orderDetailIndex}>
                                <Typography>
                                  {`${orderDetailIndex + 1}.เกรด: ${orderDetail.grade?.name} จํานวน: ${orderDetail.quantity} กิโล`}
                                </Typography>
                              </Grid>
                            </>
                          ))
                        }
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Controller
                      name="agent"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disabled={isDisabled}
                          value={value as ISupplier || null}
                          options={props.suppliers.filter((supplier) => supplier.isOrchard)}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_, newValue) => {
                            if (newValue?.id === "create") {
                              handleOpenCreateDailog("agent");
                              onChange(null);
                            } else if (newValue?.id) { onChange(newValue); }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Agent"
                              error={!!errors.agent}
                              helperText={errors.agent ? "กรุณาเลือก Agent" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="vapourHeatTreatment"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disabled={isDisabled}
                          value={value as IVapourHeatTreatment || null}
                          options={props.vapourHeatTreatmentData}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_, newValue) => {
                            if (newValue?.id === "create") {
                              handleOpenCreateDailog("vapourHeatTreatment");
                              onChange(null);
                            } else if (newValue?.id) { onChange(newValue); }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="โรงอบ"
                              error={!!errors.vapourHeatTreatment}
                              helperText={errors.vapourHeatTreatment ? "กรุณาเลือกโรงอบ" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="product"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisabled}
                          value={field.value as IProduct || null}
                          options={productList ?? []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {

                            field.onChange(newValue);

                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="สินค้า"
                              error={!!errors.product}
                              helperText={errors.product ? "กรุณาเลือกสินค้า" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} textAlign={'center'}>
                    <Controller
                      name={`totalWeight`}
                      control={control}
                      rules={{ min: { value: 0, message: 'น้ำหนักทั้งหมดต้องไม่ติดลบ' } }}
                      render={({ field }) => (
                        <TextField
                          disabled={isDisabled}
                          fullWidth
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="น้ำหนักทั้งหมด"
                          {...field}
                          error={!!errors.totalWeight}
                          helperText={errors.totalWeight?.message || ''}
                          InputLabelProps={{ shrink: field.value ? true : undefined }}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            calTotalBasket();
                            calNetTotalWeight();
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Controller
                      name="weightPerBasket"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          disabled={isDisabled}
                          label="น้ำหนักต่อตะกร้า"
                          type="number"
                          fullWidth
                          InputLabelProps={{ shrink: field.value ? true : undefined }}
                          variant="outlined"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            calTotalBasket();
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Controller
                      name="basketAmount"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          disabled={isDisabled}
                          label="จำนวนตะกร้า"
                          type="number"
                          fullWidth
                          InputLabelProps={{ shrink: getValues("basketAmount") != null }}
                          variant="outlined"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            calNetTotalWeight();
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Controller
                      name="emptyBasketWeight"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          disabled={isDisabled}
                          label="น้ำหนักตระกร้าเปล่า"
                          type="number"
                          fullWidth
                          InputLabelProps={{ shrink: field.value ? true : undefined }}
                          variant="outlined"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            calNetTotalWeight();
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="transportRef"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisabled}
                          value={field.value as ITransportRefference || null}
                          options={transportRefList ?? []}
                          getOptionLabel={(option: ITransportRefference) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            return option && option.docNo ? option.docNo : "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);

                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="อ้างอิงการขนส่ง"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  {
                    (userGroup?.canCost || userGroup?.canFinance) && (
                      <>
                        <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="h6">ราคา</Typography>
                        </Grid>
                        {
                          userGroup?.canCost && (
                            <>
                              <Grid item xs={12} sm={12} md={2} lg={2} textAlign={'center'}>
                                <Controller
                                  name={`pricePerKg`}
                                  control={control}
                                  rules={{ min: { value: 0, message: 'ค่าน้ำมันรถต้องไม่ติดลบ' } }}
                                  render={({ field }) => (
                                    <TextField
                                      fullWidth
                                      disabled={from === "order" || (props.permission.create || (props.farmProductReceipt.id && props.permission.edit)) ? false : isDisabled}
                                      type="number"
                                      InputProps={{ inputProps: { min: 0 } }}
                                      label="ราคา/Kg"
                                      {...field}
                                      error={!!errors.pricePerKg}
                                      helperText={errors.pricePerKg?.message || ''}
                                      InputLabelProps={{ shrink: field.value ? true : undefined }}
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          )
                        }
                        {
                          userGroup?.canFinance && (
                            <>

                              <Grid item xs={6} sm={6} md={2} lg={2} textAlign={'center'}>
                                <Controller
                                  name={`deposit`}
                                  control={control}
                                  rules={{ min: { value: 0, message: 'เงินมัดจำต้องไม่ติดลบ' } }}
                                  render={({ field }) => (
                                    <TextField
                                      disabled={isDisabled}
                                      fullWidth
                                      type="number"
                                      InputProps={{ inputProps: { min: 0 } }}
                                      label="เงินมัดจำ"
                                      {...field}
                                      error={!!errors.deposit}
                                      helperText={errors.deposit?.message || ''}
                                      InputLabelProps={{ shrink: field.value ? true : undefined }}
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        calGrandTotal();
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={2} lg={2} textAlign={'center'}>
                                <FormControl fullWidth>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                      name="depositDate"
                                      control={control}
                                      render={({ field }) => (
                                        <DatePicker
                                          disabled={isDisabled}
                                          slotProps={{
                                            textField: {
                                              variant: 'outlined',
                                              error: !!errors.depositDate,
                                              helperText: errors.depositDate ? "กรุณาระบุวันที่มัดจำ" : "",
                                            }
                                          }}
                                          label="วันที่มัดจำ"
                                          format="DD/MM/YYYY"
                                          {...field}
                                          defaultValue={dayjs(new Date())}
                                          value={field.value ? dayjs(field.value) : dayjs(new Date())}
                                          onChange={(date) => field.onChange(date)}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2} lg={2} textAlign={'center'}>
                                <Controller
                                  name={`totalRemain`}
                                  control={control}
                                  rules={{ min: { value: 0, message: 'ยอดคงเหลือต้องไม่ติดลบ' } }}
                                  render={({ field }) => (
                                    <TextField
                                      disabled={isDisabled}
                                      fullWidth
                                      type="number"
                                      InputProps={{ inputProps: { min: 0 } }}
                                      label="ยอดคงเหลือ"
                                      {...field}
                                      error={!!errors.totalRemain}
                                      helperText={errors.totalRemain?.message || ''}
                                      InputLabelProps={{ shrink: field.value ? true : undefined }}
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        calGrandTotal();
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={2} lg={2} textAlign={'center'}>
                                <FormControl fullWidth>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                      name="remainPaymentDate"
                                      control={control}
                                      render={({ field }) => (
                                        <DatePicker
                                          disabled={isDisabled}
                                          slotProps={{
                                            textField: {
                                              variant: 'outlined',
                                              error: !!errors.remainPaymentDate,
                                              helperText: errors.remainPaymentDate ? "กรุณาระบุวันที่จ่าย" : "",
                                            }
                                          }}
                                          label="วันที่จ่าย"
                                          format="DD/MM/YYYY"
                                          {...field}
                                          defaultValue={dayjs(new Date())}
                                          value={field.value ? dayjs(field.value) : dayjs(new Date())}
                                          onChange={(date) => field.onChange(date)}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </FormControl>
                              </Grid>
                            </>
                          )
                        }
                      </>
                    )
                  }
                  {
                    userGroup?.canFinance && (
                      <>
                        <Grid item xs={12} sm={12} mt={2} mb={2} md={12} lg={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="h6">ค่าใช้จ่ายอื่นๆ</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>

                          {watchData?.map((data: IFarmExpenseDetail, index) => (
                            <Grid container spacing={2} key={data.id} mb={3}>
                              {
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} ml={1}>
                                      <Controller
                                        name={`farmExpenseDetail.${index}.type`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                          <RadioGroup
                                            {...field}
                                            row
                                            onChange={(e) => {
                                              field.onChange(parseFloat(e.target.value));
                                            }}
                                          >
                                            <FormControlLabel
                                              value={1}
                                              control={<Radio />}
                                              label="ค่ารถ"
                                              disabled={isDisabled}
                                            />
                                            <FormControlLabel
                                              value={2}
                                              control={<Radio />}
                                              label="ค่าใช้จ่ายอื่นๆ"
                                              disabled={isDisabled}
                                            />

                                          </RadioGroup>
                                        )}
                                      />
                                    </Grid>
                                    {!isMobile && (
                                      <Grid item xs={1} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                        <IconButton
                                          disabled={isDisable}
                                          color="error"
                                          onClick={() => {
                                            remove(index);
                                          }}
                                        >
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      </Grid>
                                    )}
                                    <Grid item xs={11} sm={11.5} md={11.5} lg={11.5}>
                                      <Grid container spacing={2}>
                                        {
                                          data.type === 1 && (
                                            <>
                                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <Controller
                                                  name={`farmExpenseDetail.${index}.car`}
                                                  control={control}
                                                  rules={{ required: true }}
                                                  render={({ field: { onChange, value } }) => (
                                                    <Autocomplete
                                                      disabled={isDisabled}
                                                      value={value as ICar || null}
                                                      options={props.cars ?? [] as ICar[]}
                                                      getOptionLabel={(option: ICar) => {
                                                        if (typeof option === 'string') {
                                                          return option;
                                                        }
                                                        return option && option.plateNumber ? `${option.plateNumber} ${option.province?.nameTh}` : "";
                                                      }}
                                                      isOptionEqualToValue={(option, value) => option.id === value.id}
                                                      onChange={(_, newValue) => {
                                                        if (newValue?.id === "create") {
                                                          handleOpenCreateDailog("car");
                                                          onChange(null);
                                                        } else if (newValue?.id) { onChange(newValue); }
                                                      }}
                                                      filterOptions={(options, params) => {
                                                        const filtered = filterOptions(options, params);
                                                        if (params.inputValue !== '') {
                                                          filtered.push({
                                                            plateNumber: `สร้าง '${params.inputValue}'`,
                                                            id: 'create',
                                                          });
                                                          setName(params.inputValue);
                                                        }
                                                        return filtered;
                                                      }}
                                                      renderOption={(props, option) => {
                                                        const { key, ...rest } = props;
                                                        return (
                                                          <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                                            <AddCircleIcon /><Box component={'span'} ml={1}> {option.plateNumber}</Box>
                                                          </Box> : option.plateNumber}</Typography>
                                                        );
                                                      }}
                                                      clearOnBlur
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="ทะเบียนรถ"
                                                          error={!!errors.farmExpenseDetail?.[index]?.car}
                                                          helperText={errors.farmExpenseDetail?.[index]?.car ? "กรุณาเลือกทะเบียนรถ" : ""}
                                                        />
                                                      )}
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <Controller
                                                  name={`farmExpenseDetail.${index}.driver`}
                                                  control={control}
                                                  rules={{ required: true }}
                                                  render={({ field: { onChange, value } }) => (
                                                    <Autocomplete
                                                      disabled={isDisabled}
                                                      value={value as IDriver || null}
                                                      options={props.drivers ?? [] as IDriver[]}
                                                      getOptionLabel={(option: IDriver) => {
                                                        if (typeof option === 'string') {
                                                          return option;
                                                        }
                                                        return option && option.firstName ? `${option.firstName} ${option.lastName}` : "";
                                                      }}
                                                      isOptionEqualToValue={(option, value) => option.id === value.id}
                                                      onChange={(_, newValue) => {
                                                        if (newValue?.id === "create") {
                                                          handleOpenCreateDailog("driver");
                                                          onChange(null);
                                                        } else if (newValue?.id) { onChange(newValue); }
                                                      }}
                                                      filterOptions={(options, params) => {
                                                        const filtered = filterOptions(options, params);
                                                        if (params.inputValue !== '') {
                                                          filtered.push({
                                                            firstName: `สร้าง '${params.inputValue}'`,
                                                            id: 'create',
                                                          });
                                                          setName(params.inputValue);
                                                        }
                                                        return filtered;
                                                      }}
                                                      renderOption={(props, option) => {
                                                        const { key, ...rest } = props;
                                                        return (
                                                          <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                                            <AddCircleIcon /><Box component={'span'} ml={1}> {option.firstName} </Box>
                                                          </Box> : option.firstName + " " + option.lastName}</Typography>
                                                        );
                                                      }}
                                                      clearOnBlur
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="คนขับรถ"
                                                          error={!!errors.farmExpenseDetail?.[index]?.driver}
                                                          helperText={errors.farmExpenseDetail?.[index]?.driver ? "กรุณาเลือกคนขับรถ" : ""}
                                                        />
                                                      )}
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                            </>
                                          )
                                        }
                                        {
                                          data.type === 2 && (
                                            <>
                                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <Controller
                                                  name={`farmExpenseDetail.${index}.expenseType`}
                                                  control={control}
                                                  rules={{ required: true }}
                                                  render={({ field }) => (
                                                    <Autocomplete
                                                      {...field}
                                                      disabled={isDisabled}
                                                      value={field.value as IExpenseType || null}
                                                      options={props.expenseTypes ?? [] as IExpenseType[]}
                                                      getOptionLabel={(option: IExpenseType) => {
                                                        if (typeof option === 'string') {
                                                          return option;
                                                        }
                                                        return option && option.name ? option.name : "";
                                                      }}
                                                      isOptionEqualToValue={(option, value) => option.id === value.id}
                                                      onChange={(event, newValue) => {
                                                        if (newValue?.id === "create") {
                                                          setOpenExpenseType(true);
                                                        } else if (newValue?.id !== "create") {
                                                          field.onChange(newValue);
                                                        }
                                                      }}
                                                      filterOptions={(options, params) => {
                                                        const filtered = filterOptions(options, params);
                                                        if (params.inputValue !== '') {
                                                          filtered.push({
                                                            name: `สร้าง '${params.inputValue}'`,
                                                            id: 'create',
                                                          });
                                                        }
                                                        return filtered;
                                                      }}
                                                      renderOption={(props, option) => {
                                                        const { key, ...rest } = props;
                                                        return (
                                                          <Typography key={key} {...rest} variant="body2" >{option.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                                            <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                                                          </Box> : option.name}</Typography>
                                                        );
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="ค่าใช้จ่าย"
                                                          error={!!errors.farmExpenseDetail?.[index]?.expenseType}
                                                          helperText={errors.farmExpenseDetail?.[index]?.expenseType ? "กรุณาเลือกค่าใช้จ่าย" : ""}
                                                        />
                                                      )}
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                            </>
                                          )
                                        }
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                          <Controller
                                            name={`farmExpenseDetail.${index}.amount`}
                                            control={control}
                                            rules={{ min: { value: 0, message: 'ราคาต้องไม่ติดลบ' } }}
                                            render={({ field }) => (
                                              <TextField
                                                disabled={isDisabled}
                                                fullWidth
                                                type="number"
                                                InputProps={{ inputProps: { min: 0 } }}
                                                label="ราคา"
                                                {...field}
                                                error={!!errors.farmExpenseDetail?.[index]?.amount}
                                                helperText={errors.farmExpenseDetail?.[index]?.amount?.message || ''}
                                                InputLabelProps={{ shrink: field.value ? true : undefined }}
                                                onChange={(e) => {
                                                  field.onChange(e.target.value);
                                                }}
                                              />
                                            )}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                          <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                              <Controller
                                                name={`farmExpenseDetail.${index}.payDate`}
                                                control={control}
                                                render={({ field }) => (
                                                  <DatePicker
                                                    disabled={isDisabled}
                                                    slotProps={{
                                                      textField: {
                                                        variant: 'outlined',
                                                        error: !!errors.farmExpenseDetail?.[index]?.payDate,
                                                        helperText: errors.farmExpenseDetail?.[index]?.payDate ? "กรุณาระบุวันที่จ่าย" : "",
                                                      }
                                                    }}
                                                    label="วันที่จ่าย"
                                                    format="DD/MM/YYYY"
                                                    {...field}
                                                    defaultValue={dayjs(new Date())}
                                                    value={field.value ? dayjs(field.value) : dayjs(new Date())}
                                                    onChange={(date) => field.onChange(date)}
                                                  />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                          <Controller
                                            name={`farmExpenseDetail.${index}.payBy`}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                              <Autocomplete
                                                {...field}
                                                disabled={isDisabled}
                                                value={field.value as IUserSuperAdmin || null}
                                                options={props.users ?? [] as IUserSuperAdmin[]}
                                                getOptionLabel={(option: IUserSuperAdmin) => {
                                                  if (typeof option === 'string') {
                                                    return option;
                                                  }
                                                  return option && option.firstName ? option.firstName : "";
                                                }}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                onChange={(event, newValue) => {
                                                  field.onChange(newValue);
                                                }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="ผู้จ่าย"
                                                    error={!!errors.farmExpenseDetail?.[index]?.payBy}
                                                    helperText={errors.farmExpenseDetail?.[index]?.payBy ? "กรุณาเลือกผู้จ่าย" : ""}
                                                  />
                                                )}
                                              />
                                            )}
                                          />
                                        </Grid>
                                      </Grid>

                                    </Grid>
                                  </Grid>
                                </Grid>
                              }


                            </Grid>
                          ))}
                          <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                            <Grid container spacing={2}>
                              {isMobile ? <>
                                <Grid item xs={4} sm={4} >
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{ width: '100%' }}
                                    disabled={isDisable}
                                    type="button"
                                    startIcon={<RemoveCircleIcon />}
                                    onClick={() => {
                                      remove(fields.length - 1);
                                    }}

                                  >
                                    ลบ
                                  </Button>
                                </Grid>
                                <Grid item xs={8} sm={8} >
                                  <Button
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    disabled={isDisable}
                                    type="button"
                                    startIcon={<AddCircleIcon />}
                                    onClick={() => append({
                                      id: uuidv4(),
                                      type: 1,
                                      amount: 0,
                                      payDate: new Date(),
                                      payBy: props.user
                                    })}
                                  >
                                    เพิ่มรายการ
                                  </Button>

                                </Grid></> : <Grid item md={12} lg={12}>
                                <Button
                                  variant="outlined"
                                  sx={{ width: '100%' }}
                                  disabled={isDisable}
                                  type="button"
                                  startIcon={<AddCircleIcon />}
                                  onClick={() => append({
                                    id: uuidv4(),
                                    type: 1,
                                    amount: 0,
                                    payDate: new Date(),
                                    payBy: props.user
                                  })}
                                >
                                  เพิ่มรายการ
                                </Button>

                              </Grid>
                              }

                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )
                  }

                  <Grid item xs={12} sm={12} mt={2} mb={2} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={7} md={7} lg={7}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography>เอกสาร</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button disabled={isDisabled} component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                          เลือกไฟล์
                          <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png" multiple onChange={handleFileChange} />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {selectedFiles.length > 0 && (
                          <Box component={'div'} mt={1}>
                            {selectedFiles.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`sf-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small" color="error" onClick={() => handleFileRemove(index)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                      style={{ maxWidth: "50%", height: "auto", cursor: "pointer" }}
                                      onClick={() => onImageClick(URL.createObjectURL(file))}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {images && (
                          <Box component={'div'} mt={1}>
                            {images.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`im-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small"
                                      color="error" onClick={() => handleFileRemoveImageList(file)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/uploads/${(file).name
                                        }`}
                                      alt="Selected"
                                      style={{ maxWidth: "50%", height: "auto", cursor: 'pointer' }}
                                      onClick={() => onImageClick(`${process.env.REACT_APP_API_URL}/uploads/${(file).name}`)}

                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} mt={2} mb={2} md={12} lg={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={4}>
                            <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                              <Typography>
                                <b>ผู้ทํารายการ: </b>
                                {props.farmProductReceipt?.createBy?.firstName} {props.farmProductReceipt?.createBy?.lastName}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                              <Typography>
                                <b>ผู้อนุมัติ/ปฏิเสธ: </b>
                                {props.farmProductReceipt?.approveBy?.firstName} {props.farmProductReceipt?.approveBy?.lastName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={5} md={5} lg={5} justifyContent={'flex-end'}>
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography >น้ำหนักทั้งหมด (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        {thaiFormatNumber(watch('totalWeight')?.toString() || '0')}
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography >น้ำหนักตะกร้าเปล่าทั้งหมด (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        {thaiFormatNumber(totalBasketWeight.toString() || '0')}
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography >น้ำหนักสุทธิ (Kg)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        {thaiFormatNumber(watch('netWeight')?.toString() || '0')}
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography >ราคาสุทธิ (บาท)</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                        <Typography>
                          {thaiFormatNumber(watch('grandTotal')?.toString() || '0')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} justifyContent={"end"}>
                    <Stack spacing={2} direction="row">
                      {
                        isFromStatus ? (
                          <>
                            {
                              userGroup?.canApprove && props.farmProductReceipt.status === "waiting" ? (
                                <>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="error"
                                    onClick={onReject}
                                  >
                                    ปฏิเสธ
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="success"
                                    fullWidth
                                    onClick={onApprove}
                                    style={{ marginLeft: '8px' }}
                                  >
                                    อนุมัติ
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {
                                    <> <Button
                                      variant="outlined"
                                      fullWidth
                                      onClick={() => navigate("/process-product-receipt")}
                                    >
                                      ยกเลิก
                                    </Button>
                                      {
                                        (props.permission.create || (props.farmProductReceipt.id && props.permission.edit)) ?
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleSave}
                                          >
                                            บันทึก
                                          </Button>
                                          : <></>
                                      }
                                    </>
                                  }
                                </>
                              )
                            }
                          </>
                        ) :
                          (<>
                            {
                              <> <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => navigate("/process-product-receipt")}
                              >
                                ยกเลิก
                              </Button>
                                {
                                  (props.permission.create || (props.farmProductReceipt.id && props.permission.edit)) && props.farmProductReceipt.status !== 'approved' ?
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      fullWidth
                                      onClick={handleSave}
                                    >
                                      บันทึก
                                    </Button>
                                    : <></>
                                }
                              </>
                            }
                          </>)
                      }

                    </Stack>
                  </Grid>

                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            onSubmit();
          }
        }}
      />
      <ImagePreview
        open={openImage}
        imageSrc={selectedImage}
        onClose={() => setOpenImage(false)}
      />
      <DialogCreate datas={openCreateDailog?.datas} open={openCreateDailog?.open && (openCreateDailog?.type === "agent" || openCreateDailog?.type === "vapourHeatTreatment")} title={openCreateDailog?.title} type={openCreateDailog?.type} onClose={() => setOpenCreateDailog({ ...openCreateDailog, open: false })} onSubmit={(data, type) => { props?.onCreateDialog(data, type); }} onFetchData={(id, type) => {
        props?.onFetchData(id, type);
      }} onCreateDialog={(data, type) => { props?.onCreateDialog(data, type); }} />
      <CarCreateView
        open={openCreateDailog?.open && openCreateDailog?.type === "car"}
        provinceData={props.provinceData || []}
        onClose={() => setOpenCreateDailog({ ...openCreateDailog, open: false })}
        onSubmit={(data) => { props?.onCreateDialog(data, nameType); }}
        permission={props.permission} name={name} />
      <DriverCreateView
        open={openCreateDailog?.open && openCreateDailog?.type === "driver"}
        onClose={() => setOpenCreateDailog({ ...openCreateDailog, open: false })}
        onSubmit={(data) => { props?.onCreateDialog(data, nameType); }}
        permission={props.permission} name={name} />
      <ExpenseTypeCreateView
        open={openExpenseType}
        onClose={() => setOpenExpenseType(false)}
        data={{} as IExpenseType}
        isLoading={false}
        onSubmit={(data: IExpenseType) => {
          setOpenExpenseType(false);
          props.onCreateExpenseType(data);
        }}
        permission={props.permission}
        name={name}
      />
    </Box >
  );
};

export default FarmProductReceiptCreateView;