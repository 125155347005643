import { FC, useState } from "react";
import { IUserCriteria, IUserListView, IUserSuperAdmin } from "../Services/UserService";
import { Box, Breadcrumbs, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
import { statusOptions } from "../../../constants/statusOptions";
import { isDeleteOptions } from "../../../constants/isDeleteOptions";
interface UsersListViewProps {
  onSearchByCriteria: (criteria: IUserCriteria) => Promise<void>;
  isLoading?: boolean | false;
  onCreate: (data: any) => Promise<void>;
  onUpdate: (data: any) => Promise<void>;
  onRemove: (id: string) => Promise<void>;
  data: IUserListView;
}
const UsersListView: FC<UsersListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [userGroup, setUserGroup] = useState('');
  const [status, setStatus] = useState<boolean | string>("undefined");
  const [isDelete, setIsDelete] = useState<boolean | string>("undefined");

  const navigate = useNavigate();

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);

    const criteria: IUserCriteria = {
      name: name,
      company: company,
      userGroup: userGroup,
      isDelete: isDelete === "undefined" ? undefined : isDelete === "true" ? true : false,
      status: status === "undefined" ? undefined : status === "true" ? true : false,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearchByCriteria(criteria);
  };

  //SECTION - Variables for columns TableDataList
  const columns = [
    { label: 'บริษัท', render: (row: IUserSuperAdmin) => <Box sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>{row.company?.name || ''}</Box> },
    {
      label: 'ชื่อ', render: (row: IUserSuperAdmin) => <Box sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>{`${row.createBy?.firstName || ''} ${row.createBy?.lastName || ''}`}</Box>
    },
    { label: 'อีเมล', render: (row: IUserSuperAdmin) => <Box sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>{row.email || ''}</Box> },
    { label: 'ตำแหน่ง', render: (row: IUserSuperAdmin) => <Box sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>{row.userGroup?.name || ''}</Box> },
    {
      label: 'Status', render: (row: IUserSuperAdmin) => (<Chip
        label={row.isActive ? 'Active' : 'Inactive'}
        color={row.isActive ? 'success' : 'default'}
        variant='outlined'
      />)
    },
    {
      label: 'บันทึกล่าสุด', render: (row: IUserSuperAdmin) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.createBy?.firstName || ''} {row.createBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? '' : dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IUserSuperAdmin) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.updateBy?.firstName || ''} {row.updateBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? '' : dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
        </Box>
      )
    },

  ];

  const actions = (row: IUserSuperAdmin) => (
    <IconButton
      onClick={() => {
        navigate(`/admin-user-detail/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        navigate(`/admin-user-detail`);
      }}>
      สร้าง
    </Button>
  );
  return (<ListViewPage
    breadcrumb={
      <>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>ผู้ใช้งาน</Typography>
          <Typography variant="h6" color="text.primary">รายการ</Typography>
        </Breadcrumbs>
      </>
    }
    criteria={
      <>
        <Box component={"form"}>
          <Grid container spacing={2} alignItems={"end"}>
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <Grid container spacing={2} alignItems={"end"}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <TextField fullWidth label="บริษัท" value={company} onChange={(e) => setCompany(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <TextField fullWidth label="ชื่อผู้ใช้งาน" value={name} onChange={(e) => setName(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <TextField fullWidth label="ตำแหน่ง" value={userGroup} onChange={(e) => setUserGroup(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">สถานะ</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      value={status}
                      label="สถานะ"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      {statusOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">ลบข้อมูล</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      value={isDelete}
                      label="ลบข้อมูล"
                      onChange={(e) => setIsDelete(e.target.value)}
                    >
                      {isDeleteOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} textAlign={"end"} alignSelf={"end"}>
              <Button
                onClick={() => searchByCriteria()}
                variant="contained"
                startIcon={<SearchIcon />}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>}
    table={
      <>
        <TableDataList
          data={props.data?.data || []}
          total={props.data?.total}
          columns={columns}
          actionButtons={actionButtons}
          rowActions={actions}
          page={page}
          limit={limit}
          onPageChange={(newPage: number) => {
            setPage(newPage);
            searchByCriteria(newPage);
          }}
          onRowsPerPageChange={(newLimit) => {
            setLimit(newLimit);
            setPage(0);
            searchByCriteria(0, newLimit);
          }}
        />
      </>}
    isLoading={props.isLoading ?? false}
  />);
};

export default UsersListView;
