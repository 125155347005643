import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import eliminateService, { IEliminate, IEliminateCreate } from "../Services/EliminateService";
import { enqueueSnackbar } from "notistack";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import marketService, { IMarket } from "../Services/MarketService";
import { IUploadFile } from "../../Company/Services/CompanyService";
import receiveService from "../../Inventory/Services/ReceiveService";
import EliminateCreateView from "../Views/EliminateCreateView";
import loginService, { IUserLogined } from "../../Login/Services/LoginService";
import clearState from "../../../utils/resetState";

const EliminateCreateController = observer(() => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();

  useEffect(() => {
    if (id) {
      findOne(id);
    }
    checkPermissionActionButton();
    getMarkets();
    getProvince();
    return () => {
      clearState(eliminateService, ["eliminate"]);
    };
  }, [id]);

  const update = async (data: IEliminateCreate, uploadFile: IUploadFile[], deleteImages: IUploadFile[]) => {
    try {
      setIsLoading(true);

      const _uploadFile = data.image === uploadFile ? undefined : uploadFile;

      const transformedData = {
        ...data,
        eliminateDetails: data.eliminateDetails?.map(detail => ({
          ...detail,
          grade: detail.grade ? { id: detail.grade.id } : undefined,
          market: detail.eliminateType === 1 ? detail.market && typeof detail.market === 'object' ? { id: detail.market.id } : detail.market : undefined
        }))
      };


      const { image, ...formatData } = transformedData;

      const res = await eliminateService.update(id ?? '', formatData, { file: _uploadFile });
      if (res) {
        if (deleteImages && deleteImages.length > 0) {
          const deletePromises = deleteImages.map((file: any) => {
            return receiveService.deleteImages({ file });
          });
          await Promise.all(deletePromises);
        }
        setTimeout(() => {
          findOne(id ?? "");
          window.location.reload();
        }, 200);
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    } finally {
      setIsLoading(false);
    }
  };


  const submitDicisionEliminate = async (id: string, status: string, orderId: string, totalEliminateWeight: number, totalSalesWeight: number) => {
    try {
      await eliminateService.submitDicisionEliminate(id, status, orderId, totalEliminateWeight, totalSalesWeight);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }
  };

  const findOne = async (id: string) => {
    try {
      setIsLoading(true);
      await eliminateService.getOne(id);
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-eliminate");
    if (result) {
      setPermission(result);
    }
  };

  const getMarkets = async () => {
    try {
      setIsLoading(true);
      await marketService.findAll();
    } catch (error) {
      console.error("An error occurred while getting the markets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async (data: IEliminateCreate) => {
    try {
      setIsLoading(true);
      delete data.image;
      const transformedData = {
        ...data,
        eliminateDetails: data.eliminateDetails?.map(detail => ({
          ...detail,
          grade: detail.grade ? { id: detail.grade.id } : undefined,
          market: detail.eliminateType === 1 ? detail.market && typeof detail.market === 'object' ? { id: detail.market.id } : detail.market : undefined
        }))
      };
      await eliminateService.update(id ?? "", transformedData);
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
      navigate("/process-eliminate");
    } catch (error) {
      enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการลบ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProvince = async () => {
    try {
      setIsLoading(true);
      await marketService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onFetchData = async (id?: any, type?: string) => {
    try {
      switch (type) {
        case "district": await marketService.getDistrict(id);
          break;
        case "subDistrict": await marketService.getSubDistrict(id);
          break;
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "market":
          response = await marketService.create(data);
          getMarkets();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  return <EliminateCreateView
    onUpdate={update}
    onDelete={onDelete}
    isLoading={isLoading}
    eliminateId={id ?? ""}
    permission={permission ?? {} as IUserDetail}
    submitDicisionEliminate={submitDicisionEliminate}
    markets={marketService.allMarketsData ?? [] as IMarket[]}
    user={loginService.userLoginedData ?? {} as IUserLogined}
    eliminate={eliminateService.eliminateData ?? {} as IEliminate}
    onCreateDialog={onCreateDialog}
    onFetchData={onFetchData}
    provinceData={marketService.provinceData || []}
    districtData={marketService.districtData || []}
    subDistrictData={marketService.subDistrictData || []}
  />;
});


export default EliminateCreateController;