import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import userService, { IUserDetail, IUserSuperAdmin } from "../../SuperAdminManagement/Services/UserService";
import farmProductReceiptService, { IFarmProductReceipt } from "../Services/FarmProductReceipt";
import { IUploadFile } from "../../Company/Services/CompanyService";
import { enqueueSnackbar } from "notistack";
import receiveService from "../../Inventory/Services/ReceiveService";
import FarmProductReceiptCreateView from "../Views/FarmProductReceiptCreateView";
import orderService, { IOrder } from "../Services/OrderService";
import loginService from "../../Login/Services/LoginService";
import supplierService, { ISupplier } from "../../Supplier/Services/SupplierService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import clearState from "../../../utils/resetState";
import vapourHeatTreatmentService from "../Services/VapourHeatTreatmentService";
import gradeService from "../Services/GradeService";
import { IGrade } from "../Services/GradeService";
import driverService, { IDriver } from "../../Transport/Services/DriverService";
import carService, { ICar } from "../../Transport/Services/CarService";
import expenseTypeService, { IExpenseType } from "../../Expense/Services/ExpenseTypeService";
import supplierTypeService from "../../Supplier/Services/SupplierTypeService";

const FarmProductReceiptCreateController = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [orders, setOrders] = useState<IOrder[]>();

  useEffect(() => {
    getOrder();
    getSupplier();
    getVapourHeatTreatment();
    checkPermissionActionButton();
    getGrade();
    getDriver();
    getCars();
    getExpenseType();
    getUser();
    getProvince();
    getSupplierType();
    if (id) {
      getOne(id);
    }
    return () => {
      clearState(farmProductReceiptService, ["farmProductReceipt"]);
    };
  }, [id]);

  const getOne = async (id: string) => {
    try {
      setIsLoading(true);
      await farmProductReceiptService.getOne(id);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("เกิดข้อผิดพลาดในการดึงข้อมูล", error);
    }
  };

  const getOrder = async () => {
    try {
      const result = await orderService.getAll();
      if (result) {
        setOrders(result ?? [] as IOrder[]);
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการดึงข้อมูล", error);
    }
  };

  const getSupplier = async () => {
    try {
      await supplierService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the suppliers:", error);
    }
  };

  const getVapourHeatTreatment = async () => {
    try {
      await vapourHeatTreatmentService.getVapourHeatTreatments();
    } catch (error) {
      console.error(error);
    }
  };

  const getGrade = async () => {
    try {
      await gradeService.getAll();
    } catch (error) {
      console.error(error);
    }
  };

  const getDriver = async () => {
    try {
      await driverService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the drivers:", error);
    }
  };

  const getCars = async () => {
    try {
      await carService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the drivers:", error);
    }
  };

  const getExpenseType = async () => {
    try {
      await expenseTypeService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the expense:", error);
    }
  };

  const getUser = async () => {
    try {
      await userService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the user:", error);
    }
  };
  const getProvince = async () => {
    try {
      await farmProductReceiptService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    }
  };
  const create = async (data: IFarmProductReceipt, uploadFile: IUploadFile) => {
    try {
      data.remainPaymentDate = data.totalRemain ? data.remainPaymentDate : undefined;
      data.depositDate = data.deposit ? data.depositDate : undefined;

      const result = await farmProductReceiptService.create(data, uploadFile);
      setTimeout(() => {
        navigate(`/process-product-receipt-edit/${result.id}`);
        window.location.reload();
      }, 200);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }
  };

  const update = async (data: IFarmProductReceipt, oldImage: IUploadFile[], uploadFile: IUploadFile[], deleteImages?: IUploadFile[]) => {
    try {
      const _uploadFile = oldImage === uploadFile ? undefined : uploadFile;
      const res = await farmProductReceiptService.update(data.id ?? '', data, _uploadFile);
      if (res) {
        if (deleteImages && deleteImages.length > 0) {
          const deletePromises = deleteImages.map((file: any) => {
            return receiveService.deleteImages({
              file: file,
            });
          });
          await Promise.all(deletePromises);
        }
        setTimeout(() => {
          getOne(data.id ?? '');
          window.location.reload();
        }, 200);

        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });

      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }
  };

  const remove = async (id: string) => {
    try {
      await farmProductReceiptService.delete(id);
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
      navigate("/process-product-receipt");
    } catch (error) {
      enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการลบ", error);
    }
  };

  const getSupplierType = async () => {
    try {
      await farmProductReceiptService.getAllSupplierType();
    } catch (error) {
      console.error("An error occurred while getting the supplier type:", error);
    }
  };

  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "agent":
          response = await supplierService.create(data);
          getSupplier();
          break;
        case "supplierType":
          response = await supplierTypeService.create(data);
          getSupplierType();
          break;
        case "vapourHeatTreatment":
          response = await vapourHeatTreatmentService.create(data);
          getVapourHeatTreatment();
          break;
        case "car":
          response = await carService.create(data);
          getCars();
          break;
        case "driver":
          response = await driverService.create(data);
          getDriver();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const onFetchData = async (id?: any, type?: string) => {
    try {
      switch (type) {
        case "district": await farmProductReceiptService.getDistrict(id);
          break;
        case "subDistrict": await farmProductReceiptService.getSubDistrict(id);
          break;
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-product-receipt");
    if (result) {
      setPermission(result);
    }
  };

  //#region ประเภทค่าใช้จ่าย
  const onCreateExpenseType = async (data: IExpenseType) => {
    try {
      setIsLoading(true);
      await expenseTypeService.create(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getExpenseType();
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion

  return <FarmProductReceiptCreateView
    suppliers={supplierService.supplierListData ?? [] as ISupplier[]}
    farmProductReceipt={farmProductReceiptService.farmProductReceipt ?? {} as IFarmProductReceipt}
    orders={orders ?? [] as IOrder[]}
    vapourHeatTreatmentData={vapourHeatTreatmentService.allVapourHeatTreatmentsData ?? []}
    permission={permission ?? {} as IUserDetail}
    onCreate={create}
    onUpdate={update}
    onRemove={remove}
    isLoading={isLoading}
    user={loginService.userLoginedData ?? {} as IUserDetail}
    grades={gradeService.gradeListData ?? [] as IGrade[]}
    drivers={driverService.driverListData ?? [] as IDriver[]}
    cars={carService.carListData ?? [] as ICar[]}
    expenseTypes={expenseTypeService.expenseTypeListData ?? [] as IExpenseType[]}
    users={userService.userListData ?? [] as IUserSuperAdmin[]}
    onCreateDialog={onCreateDialog}
    onFetchData={onFetchData}
    supplierTypesOptions={farmProductReceiptService.supplierTypeListData ?? []}
    provinceData={farmProductReceiptService.provinceData ?? []}
    districtData={farmProductReceiptService.districtData ?? []}
    subDistrictData={farmProductReceiptService.subDistrictData ?? []}
    // ประเภทค่าใช้จ่าย
    onCreateExpenseType={onCreateExpenseType}
  />;
});

export default FarmProductReceiptCreateController;