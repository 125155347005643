import { observer } from "mobx-react-lite";
import CompaniesListView from "../Views/CompaniesListView";
import companyService, { ICompanyCriteria, ICompanyListView } from "../Services/CompanyService";
import { useState, useEffect } from "react";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";


const CompaniesListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [companyData, setCompanyData] = useState<ICompanyListView>();
  const defaultCriteria: ICompanyCriteria = {
    name: '',
    status: undefined,
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getByCriteria(defaultCriteria);
    checkPermissionActionButton();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
  const getByCriteria = async (criteria: ICompanyCriteria) => {
    try {
      setIsLoading(true);
      await companyService.getByCriteria(criteria).then((data) => {
        data?.data?.sort((a, b) => (b.createAt?.toString() ?? '').localeCompare(a.createAt?.toString() ?? ''));
        setCompanyData(data);
      }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("setting-company");
    if (result) {
      setPermission(result);
    }
  };

  return <CompaniesListView isLoading={isLoading} onSearchByCriteria={getByCriteria} data={companyData ?? {} as ICompanyListView} permission={permission ?? {} as IUserDetail} />;
});

export default CompaniesListController;
