import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IOrder, IRefOrder } from "./OrderService";
import { IUploadFile } from "../../Company/Services/CompanyService";
import { IShowImages } from "../../Inventory/Services/ReceiveService";
import { IFarmProductReceipt } from "./FarmProductReceipt";
import { IUser } from "../../UserManagement/Services/RoleService";
import { IBakeDetail } from "./BakeService";
import { IPackagingDetail } from "./PackagingService";


export interface IRandomInspection {
  id: string;
  docNo: string;
  totalWeight: number;
  actualWeight: number;
  totalInspectionWeight: number;
  totalRemainingWeight: number;
  eliminateWeight: number;
  claimsWeight: number;
  cutPoleWeight: number;
  lostM: number;
  order: IRefOrder;
  farm: IFarmProductReceipt;
  isDelete?: boolean;
  createAt?: Date;
  updateAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  approveBy?: IUser | undefined;
  status: string;
  image?: IShowImages[];
  out: number;
  bakes?: IBakeDetail[] | undefined;
  packagings?: Omit<IPackagingDetail, 'random'>[] | undefined;
}

export interface IRandomInspections {
  datas: IRandomInspection[];
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IRandomInspectionCriteria {
  dateFrom?: Date;
  dateTo?: Date;
  docNo: string;
  status?: string;
  page: number;
  limit: number;
}

class RandomInspectionService {
  randomInspections: IRandomInspections | undefined;
  randomInspection: IRandomInspection | undefined;
  orders: IOrder[] | undefined;

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }
  async getOne(id: string) {
    try {
      const res = await apiService.get(`random-inspections/${id}`);
      if (res) {
        this.randomInspection = res;
      }
      return this.randomInspection;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IRandomInspectionCriteria) {
    try {
      this.randomInspections = await apiService.post("random-inspections/criteria", criteria);
      return this.randomInspections;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IRandomInspection, uploadFile?: IUploadFile) {
    try {
      const res = await apiService.post("random-inspections", data);
      if (uploadFile?.file.length > 0 && uploadFile?.file) {
        for (const file of uploadFile.file) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", res?.id || "");
          formData.append("refType", "random-inspection");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      return res;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: IRandomInspection, uploadFile?: IUploadFile) {
    try {
      const res = await apiService.put(`random-inspections/${id}`, data);
      if (uploadFile?.file.length > 0 && uploadFile?.file) {
        for (const file of uploadFile.file) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", id);
          formData.append("refType", "random-inspection");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getOrderByIsComplete() {
    try {
      this.orders = await apiService.get("orders");
      return this.orders;
    } catch (error) {
      console.error(error);
    }
  }


  get randomInspectionData() {
    return this.randomInspection;
  }

  get randomInspectionsData() {
    return this.randomInspections;
  }

  get ordersData() {
    return this.orders;
  }
}

const randomInspectionService = new RandomInspectionService();
export default randomInspectionService;