import { configure, makeAutoObservable } from "mobx";
import { IMaterial } from "./MaterialsService";
import apiService from "../../../services/ApiService";
import { IWarehouse } from "./WareshousesService";
import { IUser } from "../../UserManagement/Services/RoleService";


export interface ITransferView {
  datas: ITransfer[];
  all: number;
  page: number;
  limit: number;
  total: number;
}

export interface ITransfer {
  id: string;
  docDate: Date;
  docNo: string;
  material: IMaterial;
  stockFrom: IWarehouse;
  stockTo: IWarehouse;
  quantity: number;
  worker: IUser;
  note?: string;
  createAt: Date;
  updateAt?: Date;
  createBy: IUser;
  updateBy?: IUser | undefined;
  isDelete?: boolean;
  status?: string;
  approver?: IUser | undefined;
}

export interface ITransferCriteria {
  dateType: string;
  docNo: string;
  dateFrom: Date;
  dateTo: Date;
  material: string;
  worker: string;
  sortBy: ITransferSortBy;
  stockFrom: string;
  stockTo: string;
  page: number;
  limit: number;
  status?: ITransferStatus;
}

export interface IDocumentStatus {
  [key: string]: ITransferStatus;
}

export interface ITransferStatus {
  name: string;
  value: string;
  color: string;
}

export interface ITransferSortBy {
  name: string;
  value: string;
  sortBy: string;
  sortType: "ASC" | "DESC";
}

export interface IProvince {
  id?: number;
  nameTh?: string;
}

export interface IDistrict {
  id?: number;
  nameTh?: string;
  province?: IProvince | null;
}

export interface ISubDistrict {
  id?: number;
  nameTh?: string;
  district?: IDistrict | null;
  zipCode?: string;
}

class TransferService {
  transfers: ITransferView = {} as ITransferView;
  transfer: ITransfer = {} as ITransfer;
  workers: IUser[] = [];
  warehouses: IWarehouse[] = [];
  materials: IMaterial[] = [];
  province: IProvince[] = [];
  district: IDistrict[] = [];
  subDistrict: ISubDistrict[] = [];
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getOne(id: string): Promise<ITransfer> {
    try {
      const response = await apiService.get(`/transfers/${id}`);
      if (response) {
        this.transfer = response;
      }
      return this.transfer;
    }
    catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: ITransferCriteria) {
    try {
      const response = await apiService.post(`/transfers/criteria`, criteria);
      if (response.datas) {
        this.transfers = response;
      }
      return this.transfers;
    } catch (error) {
      throw error;
    }
  }

  async create(data: ITransfer) {
    try {
      const response = await apiService.post('/transfers', data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: ITransfer) {
    try {
      const response = await apiService.put(`/transfers/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/transfers/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getWorkers() {
    try {
      const response = await apiService.get(`/users`);
      if (response) {
        this.workers = response;
      }
      return this.workers;
    }
    catch (error) {
      throw error;
    }
  }

  async getWarehouses() {
    try {
      const response = await apiService.get(`/warehouses`);
      if (response) {
        this.warehouses = response;
      }
      return this.warehouses;
    }
    catch (error) {
      throw error;
    }
  }

  async getMaterials() {
    try {
      const response = await apiService.get(`/materials`);
      if (response) {
        this.materials = response;
      }
      return this.materials;
    }
    catch (error) {
      throw error;
    }
  }

  async getProvince() {
    try {
      this.province = await apiService.get(`/provinces`);
      return this.province;
    } catch (error) {
      throw error;
    }
  }

  async getDistrict(id: number) {
    try {
      this.district = await apiService.get(`/districts?provinceId=${id}`);
      return this.district;
    } catch (error) {
      throw error;
    }
  }

  async getSubDistrict(id: number) {
    try {
      this.subDistrict = await apiService.get(
        `/sub-districts?districtId=${id}`
      );
      return this.subDistrict;
    } catch (error) {
      throw error;
    }
  }

  get transferData() {
    return this.transfers;
  }

  get transferDataById() {
    return this.transfer;
  }

  get workersData() {
    return this.workers;
  }

  get warehousesData() {
    return this.warehouses;
  }

  get getMaterialsData() {
    return this.materials;
  }

  get provinceData() {
    return this.province;
  }

  get districtData() {
    return this.district;
  }

  get subDistrictData() {
    return this.subDistrict;
  }
}
const transferService = new TransferService();
export default transferService;
