import { FC, useState } from "react";
import { IProvince, IDistrict, ISubDistrict } from "../../Company/Services/CompanyService";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { IMarket, IMarketCriteria, IMarkets } from "../Services/MarketService";
import { Breadcrumbs, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListViewPage from "../../../components/ListViewPage";
import PlaceCreateView from "../../../components/PlaceCreateView";
import TableDataList from "../../../components/TableDataList";
import SearchIcon from "@mui/icons-material/Search";



interface IMarketListViewProps {
  isLoading: boolean;
  markets: IMarkets;
  market: IMarket;
  onSerchByCriteria: (criteria: IMarketCriteria) => Promise<void>;
  onCreate: (data: IMarket) => Promise<void>;
  onUpdate: (data: IMarket) => Promise<void>;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onFetchDistrict: (id: number | undefined) => void;
  onFetchSubDistrict: (id: number | undefined) => void;
  permission: IUserDetail;
}

const MarketListView: FC<IMarketListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [market, setMarket] = useState<IMarket>();

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IMarketCriteria = {
      name: name,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSerchByCriteria(criteria);
  };

  const onSubmit = (data: IMarket) => {
    if (data.id) {
      props.onUpdate(data);
    } else {
      props.onCreate(data);
    }
  };

  const marketColumns = [
    { label: 'ชื่อตลาด', render: (row: IMarket) => row.name },
    { label: 'ที่อยู่', render: (row: IMarket) => row.address },
  ];
  const marketActions = (row: IMarket) => (
    <IconButton
      onClick={() => {
        setMarket(row);
        setOpen(true);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );


  const actionButtons = (
    <Button
      id={`${!props.permission.create ? "hidden" : "create"}`}
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        setOpen(true);
        setMarket({} as IMarket);
      }}>
      สร้าง
    </Button>
  );

  return <ListViewPage
    breadcrumb={
      <>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>ตลาด</Typography>
          <Typography variant="h6" color="text.primary">รายการ</Typography>
        </Breadcrumbs>
      </>}

    criteria={
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10} md={10} lg={10}>
            <TextField fullWidth label="ชื่อรายการ" onChange={(e) => setName(e.target.value)} />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"center"}>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => searchByCriteria(0)}>ค้นหา
            </Button>
          </Grid>
        </Grid>
      </>
    }

    table={
      <>
        <TableDataList
          data={props.markets.datas}
          total={props.markets.total ?? 0}
          columns={marketColumns}
          actionButtons={actionButtons}
          rowActions={marketActions}
          page={page}
          limit={limit}
          onPageChange={(newPage) => {
            setPage(newPage);
            searchByCriteria(newPage, limit);
          }}
          onRowsPerPageChange={(newLimit) => {
            setLimit(newLimit);
            setPage(0);
            searchByCriteria(0, newLimit);
          }}
        />
      </>
    }

    dialog={
      <>
        <PlaceCreateView open={open}
          data={market ?? {} as IMarket}
          provinceData={props.provinceData || []}
          districtData={props.districtData || []}
          subDistrictData={props.subDistrictData || []}
          onClose={() => {
            setOpen(false);
            setMarket({} as IMarket);
          }}
          onSubmit={onSubmit}
          onFetchDistrict={props.onFetchDistrict}
          onFetchSubDistrict={props.onFetchSubDistrict}
          permission={props.permission}
          placeLabel="ตลาด"
        />
      </>

    }


  />;

};

export default MarketListView;