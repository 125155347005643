import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IWarehouse } from "./WareshousesService";
import { IUser } from "../../UserManagement/Services/RoleService";

export interface IMaterial {
  id: string;
  name: string;
  qty: number;
  price: number;
  unit: string;
  subUnit: string;
  valueUnit: number;
  isDelete?: boolean;
  isMain?: boolean;
  remain?: number;
  total?: number;
  createAt?: Date;
  updateAt?: Date;
  height?: number;
  width?: number;
  length?: number;
  CBM?: number;
  weight?: number;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
}
export interface IMaterialView {
  datas: IMaterial[];
  all: number;
  page: number;
  limit: number;
  total: number;
}

export interface IMaterialCriteria {
  name: string;
  page: number;
  limit: number;
}

export interface IMaterialTransaction {
  refType: string;
  refId: string;
  quantity: number;
  refDoc: string;
  docDate: Date;
  stockFrom: IWarehouse;
  stockTo: IWarehouse;
  worker: IUser;
  order?: string;
  transfer?: string;
}

export interface IMaterialTransactions {
  datas: IMaterialTransaction[];
  totalQuantity: number;
  all: number;
  page: number;
  limit: number;
  total: number;
}

export interface IMaterialInWarehouseCriteria {
  warehouseId?: string;
  materialId?: string;
  page?: number;
  limit?: number;
}

export interface IMaterialTransactionCriteria {
  warehouseId?: string;
  materialId: string;
  dateFrom?: Date;
  dateTo?: Date;
  page: number;
  limit: number;
}
export interface IMaterialInWarehouse {
  materialId: string;
  materialName: string;
  materialPrice: number;
  totalQuantity: number;
}


export interface IMaterialInWarehouses {
  datas: IMaterialInWarehouse[];
  total: number;
  page: number;
  limit: number;
  all: number;
}

class MaterialService {
  materials: IMaterialView = {} as IMaterialView;
  material: IMaterial = {} as IMaterial;
  materialTransactions: IMaterialTransactions = {} as IMaterialTransactions;
  materialsInWarehouse: IMaterialInWarehouses = {} as IMaterialInWarehouses;
  materialQuantityInWarehouseFrom = 0;
  materialQuantityInWarehouseTo = 0;

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getOne(id: string): Promise<IMaterial> {
    try {
      const response = await apiService.get(`/materials/${id}`);
      if (response) {
        this.material = response;
      }
      return this.material;
    }
    catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IMaterialCriteria) {
    try {
      const response = await apiService.post(`/materials/criteria`, criteria);
      if (response.datas) {
        this.materials = response;
      }
      return this.materials;
    } catch (error) {
      throw error;
    }
  }

  async getMaterialsInWarehouse(criteria: IMaterialInWarehouseCriteria) {
    try {
      const response = await apiService.post(`/materials/criteria/warehouse`, criteria);
      if (response.datas) {
        this.materialsInWarehouse = response;
      }
      return this.materialsInWarehouse;
    } catch (error) {
      throw error;
    }
  }
  async getMaterialsTransactions(criteria: IMaterialTransactionCriteria) {
    try {
      const response = await apiService.post(`/material-transactions/criteria`, criteria);
      if (response.datas) {
        this.materialTransactions = response;
      }
      return this.materialTransactions;
    } catch (error) {
      throw error;
    }
  }

  async getMaterialQuantityInWarehouse(criteria: IMaterialInWarehouseCriteria, warehouseType: string) {
    try {
      const response = await apiService.post(`/materials/material-quantity/warehouse`, criteria);
      if (warehouseType === 'stockFrom') {
        this.materialQuantityInWarehouseFrom = response ?? 0;
      } else {
        this.materialQuantityInWarehouseTo = response ?? 0;
      }
      return warehouseType === 'stockFrom' ? this.materialQuantityInWarehouseFrom : this.materialQuantityInWarehouseTo;
    } catch (error) {
      throw error;
    }
  }


  async create(data: IMaterial) {
    try {
      const response = await apiService.post('/materials', data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: IMaterial) {
    try {
      const response = await apiService.put(`/materials/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const data = { isDelete: true };
      const response = await apiService.put(`/materials/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  get materialData() {
    return this.materials;
  }

  get materialDataById() {
    return this.material;
  }

  get materialInWarehouseData() {
    return this.materialsInWarehouse;
  }

  get materialTransactionData() {
    return this.materialTransactions;
  }

  get materialQuantityInWarehouseFromData() {
    return this.materialQuantityInWarehouseFrom;
  }
  get materialQuantityInWarehouseToData() {
    return this.materialQuantityInWarehouseTo;
  }
}
const materialService = new MaterialService();
export default materialService;
