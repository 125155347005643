import { FC, useState } from "react";
import { IExpenseType, IExpenseTypeCriteria, IExpenseTypeListView } from "../Services/ExpenseTypeService";
import { Breadcrumbs, Typography, Grid, TextField, Button, IconButton } from "@mui/material";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpenseTypeCreateView from "./ExpenseTypeCreateView";
import { IUserDetail } from "../../Login/Services/LoginService";

interface ExpenseTypesListViewProps {
  onUpdate: (data: IExpenseType) => void;
  onCreate: (data: IExpenseType) => Promise<void>;
  expenseTypes: IExpenseTypeListView;
  onSearchByCriteria: (criteria: IExpenseTypeCriteria) => Promise<void>;
  isLoading?: boolean | false;
  permission: IUserDetail;
}
const ExpenseTypesListView: FC<ExpenseTypesListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [expenseType, setExpenseType] = useState<IExpenseType>();

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IExpenseTypeCriteria = {
      name: name,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearchByCriteria(criteria);
  };

  const onSubmit = (data: IExpenseType) => {
    if (data.id) {
      props.onUpdate(data);
    } else {
      props.onCreate(data);
    }
  };

  //SECTION - Variables for Warehouses TableDataList
  const expenseTypeColumns = [
    { label: 'ประเภทค่าใช้จ่ายอื่นๆ', render: (row: IExpenseType) => row.name },
  ];

  const expenseTypeActions = (row: IExpenseType) => (
    <IconButton
      onClick={() => {
        setExpenseType(row);
        setOpen(true);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = (
    <Button
      id={`${!props.permission.create ? "hidden" : "create"}`}
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        setOpen(true);
        setExpenseType({} as IExpenseType);
      }}>
      สร้าง
    </Button>
  );

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ประเภทค่าใช้จ่าย</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <TextField fullWidth label="ประเภทค่าใช้จ่ายอื่นๆ" onChange={(e) => setName(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"center"}>
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={() => searchByCriteria(0)}>ค้นหา
              </Button>
            </Grid>
          </Grid>
        </>
      }
      table={
        <>
          <TableDataList
            data={props.expenseTypes.datas || []}
            total={props.expenseTypes.total}
            columns={expenseTypeColumns}
            actionButtons={actionButtons}
            rowActions={expenseTypeActions}
            page={page}
            limit={limit}
            onPageChange={(newPage: number) => {
              setPage(newPage);
              searchByCriteria(newPage);
            }}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0);
              searchByCriteria(0, newLimit);
            }}
          />
        </>
      }
      dialog={
        <>
          <ExpenseTypeCreateView open={open}
            isLoading={props.isLoading ?? false}
            data={expenseType ?? {} as IExpenseType}
            onClose={() => {
              setOpen(false);
              setExpenseType({} as IExpenseType);
            }}
            onSubmit={onSubmit}
            permission={props.permission}
          />
        </>
      }
      isLoading={props.isLoading ?? false}
    />
  );
};

export default ExpenseTypesListView;
