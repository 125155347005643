import { configure, makeAutoObservable } from "mobx";
import { IWarehouse } from "../../Inventory/Services/WareshousesService";
import apiService from "../../../services/ApiService";
import { IUser } from "../../UserManagement/Services/RoleService";

export interface ICostReport {
  id: string;
  orderDate: Date;
  invoiceNo: string;
  cost: number;
  worker: IUser;
}

export interface ISalesReport {
  id: string;
  orderDate: Date;
  invoiceNo: string;
  totalSales: number;
  worker: IUser;
}

export interface IProfitReport {
  id: string;
  orderDate: Date;
  invoiceNo: string;
  totalProfit: number;
  worker: IUser;
  warehouse?: IWarehouse | undefined;
}

export interface ILossReport {
  id: string;
  orderDate: Date;
  invoiceNo: string;
  totalEliminateWeightFromRandom: number;
  totalEliminateWeightFromBake: number;
  totalEliminateWeightFromPack: number;
  totalEliminateWeight: number;
  totalPriceLoss: number;
}

export interface ICostReports {
  datas: ICostReport[];
  all: number;
  limit: number;
  page: number;
  total: number;
  totalValue: IAllTotalValue;
}

export interface ILossReports {
  datas: ILossReport[];
  all: number;
  limit: number;
  page: number;
  total: number;
  totalValue: IAllTotalValue;
}

export interface IProfitReports {
  datas: IProfitReport[];
  all: number;
  limit: number;
  page: number;
  total: number;
  totalValue: IAllTotalValue;
}

export interface ISalesReports {
  datas: ISalesReport[];
  all: number;
  limit: number;
  page: number;
  total: number;
  totalValue: IAllTotalValue;
}

export interface IAllTotalValue {
  totalCost: number;
  totalSales: number;
  totalProfit: number;
  totalLoss: number;
  costPercentChange: number;
  profitPercentChange: number;
  salesPercentChange: number;
  lossPercentChange: number;
}

export interface IReportCriteria {
  dateFrom?: Date;
  dateTo?: Date;
  page?: number;
  limit?: number;
}

export interface IDistribution {
  date: string;
  value: number;
}


class ReportService {
  costReports: ICostReports = {} as ICostReports;
  lossReports: ILossReports = {} as ILossReports;
  salesReports: ISalesReports = {} as ISalesReports;
  profitReports: IProfitReports = {} as IProfitReports;



  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getCostReports(criteria: IReportCriteria) {
    try {
      this.costReports = await apiService.post("report/cost", criteria);
      return this.costReports;
    } catch (error) {
      throw error;
    }
  }

  async getLossReports(criteria: IReportCriteria) {
    try {
      this.lossReports = await apiService.post("report/loss", criteria);
      return this.lossReports;
    } catch (error) {
      throw error;
    }
  }
  async getSalesReports(criteria: IReportCriteria) {
    try {
      const result = await apiService.post("report/sales", criteria);
      this.salesReports = result;
      return this.salesReports;
    } catch (error) {
      throw error;
    }
  }
  async getProfitReports(criteria: IReportCriteria) {
    try {
      const result = await apiService.post("report/profit", criteria);
      this.profitReports = result;
      return this.profitReports;
    } catch (error) {
      throw error;
    }
  }


  get costReportsData() {
    return this.costReports;
  }

  get lossReportsData() {
    return this.lossReports;
  }

  get salesReportsData() {
    return this.salesReports;
  }

  get profitReportsData() {
    return this.profitReports;
  }
}
const reportService = new ReportService();
export default reportService;
