import { FC, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { ICar, ICarCriteria, ICarView } from "../Services/CarService";
import { Breadcrumbs, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListViewPage from "../../../components/ListViewPage";
import SearchIcon from "@mui/icons-material/Search";
import TableDataList from "../../../components/TableDataList";
import CarCreateView from "./CarCreateView";
import { IProvince } from "../../Company/Services/CompanyService";

interface CarListViewProps {
  isLoading: boolean;
  cars: ICarView;
  car: ICar;
  onSerchByCriteria: (criteria: ICarCriteria) => Promise<void>;
  onCreate: (data: ICar) => Promise<void>;
  onUpdate: (data: ICar) => Promise<void>;
  provinceData?: IProvince[];
  permission: IUserDetail;
}

const CarListView: FC<CarListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [plateNumber, setPlateNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [carData, setCarData] = useState<ICar>();

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: ICarCriteria = {
      plateNumber: plateNumber,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSerchByCriteria(criteria);
  };

  const onSubmit = (data: ICar) => {
    if (data.id) {
      props.onUpdate(data);
    } else {
      props.onCreate(data);
    }
  };

  const carColumns = [
    { label: 'เลขทะเบียน', render: (row: ICar) => row.plateNumber },
    { label: 'จังหวัด', render: (row: ICar) => row.province?.nameTh },
    { label: 'ยี่ห้อ', render: (row: ICar) => row.brand },
  ];

  const carActions = (row: ICar) => (
    <IconButton
      onClick={() => {
        setCarData(row);
        setOpen(true);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = (
    <Button
      id={`${!props.permission.create ? "hidden" : "create"}`}
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        setOpen(true);
        setCarData({} as ICar);
      }}>
      สร้าง
    </Button>
  );

  return <ListViewPage
    breadcrumb={
      <>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>ข้อมูลรถ</Typography>
          <Typography variant="h6" color="text.primary">รายการ</Typography>
        </Breadcrumbs>
      </>}

    criteria={
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10} md={10} lg={10}>
            <TextField fullWidth label="เลขทะเบียน" onChange={(e) => setPlateNumber(e.target.value)} />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"center"}>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => searchByCriteria(0)}>ค้นหา
            </Button>
          </Grid>
        </Grid>
      </>
    }

    table={
      <>
        <TableDataList
          data={props.cars.datas}
          total={props.cars.total}
          columns={carColumns}
          actionButtons={actionButtons}
          rowActions={carActions}
          page={page}
          limit={limit}
          onPageChange={(newPage) => {
            setPage(newPage);
            searchByCriteria(newPage, limit);
          }}
          onRowsPerPageChange={(newLimit) => {
            setLimit(newLimit);
            setPage(0);
            searchByCriteria(0, newLimit);
          }}
        />
      </>
    }

    dialog={
      <>
        <CarCreateView
          open={open}
          data={carData ?? {} as ICar}
          provinceData={props.provinceData || []}
          onClose={() => {
            setOpen(false);
            setCarData({} as ICar);
          }}
          onSubmit={onSubmit}
          permission={props.permission} />
      </>
    }


  />;
};

export default CarListView;