import { configure, makeAutoObservable } from "mobx";
import { IUser } from "../../UserManagement/Services/RoleService";
import { IMarket } from "./MarketService";
import { IOrder } from "./OrderService";
import apiService from "../../../services/ApiService";
import { IShowImages, IUploadFile } from "../../Inventory/Services/ReceiveService";
import { IGrade } from "./GradeService";

export interface IEliminateListView {
  datas?: IEliminate[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IEliminate {
  id?: string;
  eliminateId?: string;
  order?: IOrder | undefined;
  status: string;
  note?: string;
  totalSalesWeight?: number;
  totalEliminateWeight?: number;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  approveBy?: IUser | undefined;
  createdAt?: string;
  updatedAt?: string;
  image?: IShowImages[];
  // คัดทิ้ง
  farmEliminate?: IEliminateDetail[] | undefined;
  inspectionEliminate?: IEliminateDetail[] | undefined;
  bakeEliminate?: IEliminateDetail[] | undefined;
}
export interface IEliminateCreate {
  eliminateId?: string;
  note?: string;
  image?: IShowImages[];
  status?: string;
  isDelete?: boolean;
  eliminateDetails?: IEliminateDetail[] | undefined;
}
export interface IEliminateDetail {
  id?: string;
  grade?: IGrade | undefined;
  eliminateWeight?: number;
  resultWeight?: number;
  eliminateType?: number;
  type?: number;
  market?: IMarket | undefined;
  label?: string;
  docNo?: string;
  isDelete?: boolean;
}

export interface IEliminateCriteria {
  dateFrom?: Date;
  dateTo?: Date;
  invoiceNo?: string;
  product?: string;
  status?: string;
  page?: number;
  limit?: number;
  supplier?: string;
}

class EliminateService {
  eliminates: IEliminateListView | undefined;
  eliminate: IEliminate | undefined;
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getOne(id: string) {
    try {
      const eliminate = await apiService.get(`eliminates/${id}`);
      this.eliminate = eliminate;
      return this.eliminate;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IEliminateCriteria) {
    try {
      const eliminates = await apiService.post("eliminates/criteria", criteria);
      this.eliminates = eliminates;
      return eliminates;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: IEliminateCreate, uploadFile?: IUploadFile) {
    try {
      const res = await apiService.put(`eliminates/${id}`, data);
      if (uploadFile?.file.length > 0 && uploadFile?.file) {
        for (const file of uploadFile.file) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", id);
          formData.append("refType", "eliminate");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      return res;
    } catch (error) {
      throw error;
    }
  }

  async submitDicisionEliminate(id: string, status: string, orderId: string, totalEliminateWeight: number, totalSalesWeight: number) {
    try {
      const eliminate = await apiService.put(`eliminates/${id}/submit-decision`, { status, orderId, totalEliminateWeight, totalSalesWeight });
      return eliminate;
    } catch (error) {
      throw error;
    }
  }

  get eliminatesData() {
    return this.eliminates;
  }

  get eliminateData() {
    return this.eliminate;
  }
}

const eliminateService = new EliminateService();
export default eliminateService;