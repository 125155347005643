import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from "./RoleService";


export interface IUserGroup {
  id?: string;
  name?: string;
  canApprove?: boolean;
  canCost?: boolean;
  canFinance?: boolean;
  createBy?: IUser | undefined;
  createAt?: Date;
  updateBy?: IUser | undefined;
  updateAt?: Date;
  details?: IUserGroupDetail[] | undefined;
}

export interface IUserGroupDetail {
  id?: string;
  userGroup?: Omit<IUserGroup, "details">;
  menu?: string;
  read?: boolean;
  edit?: boolean;
  create?: boolean;
  delete?: boolean;
  isSelected?: boolean;
  createBy?: IUser | undefined;
  createAt?: Date;
  updateBy?: IUser | undefined;
  updateAt?: Date;
  children?: Omit<IUserGroupDetail, "children">[] | undefined;
  value?: string;
  name?: string | null;
}

export interface IUserGroupCriteria {
  name: string;
  page: number;
  limit: number;
}

export interface IUserGroupListView {
  datas?: IUserGroup[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

class UserGroupService {
  userGroups: IUserGroupListView | undefined;
  userGroupList: IUserGroup[] | undefined;
  userGroupData: IUserGroup | undefined;

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const userGroups = await apiService.get("user-groups");
      this.userGroupList = userGroups;
      return this.userGroupList;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      const userGroup = await apiService.get(`user-groups/${id}`);
      this.userGroupData = userGroup;
      return this.userGroupData;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IUserGroupCriteria) {
    try {
      const userGroups = await apiService.post("user-groups/criteria", criteria);
      this.userGroups = userGroups;
      return this.userGroups;
    } catch (error) {
      throw error;
    }
  }

  async create(userGroup: IUserGroup) {
    try {
      const result = await apiService.post("user-groups", userGroup);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async update(userGroup: IUserGroup) {
    try {
      const result = await apiService.put(`user-groups/${userGroup.id}`, userGroup);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const result = await apiService.delete(`user-groups/${id}`);
      return result;
    } catch (error) {
      throw error;
    }
  }

  get getGroupList() {
    return this.userGroupList;
  }

  get getGroupData() {
    return this.userGroupData;
  }

  get getGroup() {
    return this.userGroups;
  }
}

const userGroupService = new UserGroupService();
export default userGroupService;
