import { FC, useState } from "react";
import { IproductSelect } from "../Services/ProductService";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import ListViewPage from "../../../components/ListViewPage";
import { Breadcrumbs, Typography, Box, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Button, IconButton, Chip } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import SearchIcon from "@mui/icons-material/Search";
import TableDataList from "../../../components/TableDataList";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { documentStatus } from "../../../constants/documentStatus";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import { IUserDetail, IUserLogined } from "../../Login/Services/LoginService";
import { useNavigate } from "react-router-dom";
import { IEliminate, IEliminateCriteria, IEliminateListView } from "../Services/EliminateService";
import { IOrderDetail } from "../Services/OrderService";

interface EliminateListViewProps {
  isLoading: boolean;
  ordersEliminate: IEliminateListView;
  productSelects: IproductSelect[];
  suppliers: ISupplier[];
  onSearchByCriteria: (criteria: IEliminateCriteria) => void;
  permission: IUserDetail;
  user: IUserLogined;
}

interface IGradeCounts {
  [key: string]: number;
}

const EliminateListView: FC<EliminateListViewProps> = (props) => {
  const { register, getValues, control } = useForm<IEliminateCriteria>({
    defaultValues: {
      dateFrom: dayjs().startOf('month').toDate(),
      dateTo: dayjs().toDate(),
      invoiceNo: '',
      product: '',
      status: 'all'
    }
  });

  const draftStatus = {
    draft: {
      name: "Draft",
      value: "draft",
      color: "default"
    }
  };

  const updatedDocumentStatus = {
    all: documentStatus.all,
    draft: draftStatus.draft,
    ...Object.fromEntries(
      Object.entries(documentStatus).filter(([key]) => key !== 'all')
    )
  };



  const documentStatusOptions = Object.values(updatedDocumentStatus);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [product, setProduct] = useState({ id: "", name: "ทั้งหมด" });
  const [supplier, setSupplier] = useState({ id: "", name: "ทั้งหมด" });
  const navigate = useNavigate();

  //SECTION - Variables for  TableDataList
  const OrderColumns = [
    // { label: 'วันที่สั่งซื้อ', render: (row: IEliminate) => dayjs(row.order?.orderDate).format('DD/MM/YYYY') },
    { label: 'เลขที่ใบคำสั่งซื้อ', render: (row: IEliminate) => row.order?.invoiceNo },
    { label: 'สินค้า', render: (row: IEliminate) => row.order?.product ? row.order.product.name : "" },
    { label: 'เกรด', render: (row: IEliminate) => groupByGrade(row.order?.orderDetails ?? []) },
    // { label: 'วัตถุดิบ', render: (row: IEliminate) => row.order?.product ? row.order.product.name : "" },
    // { label: 'supplier', render: (row: IEliminate) => row.order?.product && row.order?.product.supplier ? row.order?.product.supplier.name : "" },
    {
      label: 'สถานะ', render: (row: IEliminate) => (
        <Chip
          label={documentStatus[row.status]?.name || "draft"}
          color={documentStatus[row.status]?.color as "default"}
          onClick={() => {
            if (row.status !== 'draft')
              navigate(`/process-eliminate-create/${row.id}`, { state: { from: 'status' } });
          }}
        />
      )
    },
  ];

  const groupByGrade = (orderDetails: IOrderDetail[]) => {
    const gradeCounts: IGradeCounts = orderDetails.reduce<IGradeCounts>((acc, item) => {
      const gradeName = item.grade?.name ?? '';
      if (!acc[gradeName]) {
        acc[gradeName] = 0;
      }
      acc[gradeName]++;
      return acc;
    }, {});

    let gradeName = Object.entries(gradeCounts)
      .map(([name]) => `${name}`)
      .sort()
      .join(', ');

    return gradeName;
  };

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const data = getValues();
    data.product = product.id;
    data.supplier = supplier.id;
    props.onSearchByCriteria({ ...data, page: pageNumber, limit: rowLimit ? rowLimit : 10 });
  };

  const OrderAction = (row: IEliminate) => (
    <IconButton
      onClick={() => {
        navigate(`/process-eliminate-create/${row.id}`, { state: { from: 'arrow' } });
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  return (
    <>
      <ListViewPage
        breadcrumb={
          <>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Typography>คัดขาย/ทิ้ง</Typography>
              <Typography variant="h6" color="text.primary">รายการ</Typography>
            </Breadcrumbs>
          </>
        }
        criteria={
          <>
            <Box component="form">
              <Grid container spacing={2} alignItems={"end"}>
                <Grid item xs={12} sm={10} md={10} lg={10} >
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={4}>
                      <Controller
                        name="dateFrom"
                        control={control}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                            <DemoContainer components={['DatePicker', 'DatePicker']} >
                              <DatePicker sx={{ width: "100%" }}
                                defaultValue={dayjs().startOf('month')}
                                label="ตั้งแต่วันที่"
                                value={field.value ? dayjs(field.value) : dayjs()}
                                onChange={(newValue: any) => {
                                  field.onChange(newValue);
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={4}>
                      <Controller
                        name="dateTo"
                        control={control}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                            <DemoContainer components={['DatePicker', 'DatePicker']} >
                              <DatePicker
                                sx={{ width: "100%" }}
                                defaultValue={dayjs()}
                                label="ถึงวันที่"
                                value={field.value ? dayjs(field.value) : dayjs()}
                                onChange={(newValue: any) => {
                                  field.onChange(newValue);
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={4} alignSelf={'flex-end'}>
                      <TextField fullWidth label="Invoice No" {...register("invoiceNo")} />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={4}>
                      <Controller
                        name="product"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            value={product}
                            options={[{ id: "", name: "ทั้งหมด" }, ...props.productSelects]}
                            getOptionLabel={(option: any) => {
                              if (typeof option === 'string') {
                                return option;
                              }
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              return option.name || null;
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                              field.onChange(newValue);
                              setProduct(newValue ?? { id: "", name: "ทั้งหมด" });
                            }}
                            renderInput={(params) => <TextField
                              {...params} label="วัตถุดิบ" />}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={4}>
                      <Autocomplete
                        value={supplier}
                        options={[{ id: "", name: "ทั้งหมด" }, ...props.suppliers ?? [] as ISupplier[]]}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Supplier" />}
                        onChange={(e, value) => {
                          setSupplier(value ?? { id: "", name: "ทั้งหมด" });
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                      />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={4}>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel id="status-select-label">สถานะ</InputLabel>

                            <Select
                              labelId="status-select-label"
                              value={field.value || "all"}
                              onChange={(e) => field.onChange(e.target.value)}
                              label="สถานะ"
                            >                      {documentStatusOptions.map((status) => (
                              <MenuItem key={status.value} value={status.value}>
                                {status.name}
                              </MenuItem>
                            ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"end"}>
                  <Button
                    onClick={() => searchByCriteria()}
                    variant="contained"
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        }
        table={
          <>
            <>
              <TableDataList
                data={props.ordersEliminate.datas || []}
                total={props.ordersEliminate.total}
                columns={OrderColumns}
                actionButtons={
                  <></>
                }
                rowActions={OrderAction}
                page={page}
                limit={limit}
                onPageChange={(newPage) => {
                  searchByCriteria(newPage);
                }}
                onRowsPerPageChange={(newLimit) => {
                  setLimit(newLimit);
                  setPage(0);
                  searchByCriteria(0, newLimit);
                }}
              />
            </>
          </>
        }
      />
    </>
  );
};

export default EliminateListView;