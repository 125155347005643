import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import reportService, { IProfitReport, IProfitReports, IReportCriteria } from "../Services/ReportService";
import ProfitReportListView from "../Views/ProfitReportListView";


const ProfitReportListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [profitReportData, setProfitReportData] = useState<IProfitReports>();
  const defaultCriteria: IReportCriteria = {
    dateFrom: undefined,
    dateTo: undefined,
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getProfitReportData(defaultCriteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getProfitReportData = async (criteria: IReportCriteria) => {
    try {
      setIsLoading(true);
      await reportService.getProfitReports(criteria).then((data) => {
        data?.datas?.sort((a: IProfitReport, b: IProfitReport) => (b.orderDate?.toString() ?? '').localeCompare(a.orderDate?.toString() ?? ''));
        setProfitReportData(data);
      });
    } catch (error) {
      console.error("An error occurred while getting the loss reports:", error);
    } finally {
      setIsLoading(false);
    }
  };




  return <ProfitReportListView isLoading={isLoading} profitReportData={profitReportData ?? {} as IProfitReports}
    getProfitReportData={getProfitReportData}
  />;
});

export default ProfitReportListController;
