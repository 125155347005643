import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Checkbox, Select, MenuItem, Autocomplete, Box, Grid, Typography, createFilterOptions, ButtonProps, FormControlLabel, FormHelperText } from "@mui/material";
import { useState } from "react";
import { useForm, Controller, FieldValues, Path } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";
import DialogConfirm from "./DialogConfirm";
import { KeyboardEvent } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export interface IField {
  type: string; //NOTE: input type ( text, select, autocomplete, checkbox )
  label: string; //NOTE: label for input
  name: string; //NOTE: input name
  options?: string[]; //NOTE: options for select or autocomplete
  labelOption: any; //NOTE: label in options
  valueOption: any; //NOTE: value in options
  xs: number; //NOTE: gridBreakPoint for xs
  sm?: number; //NOTE: gridBreakPoint for sm
  md?: number; //NOTE: gridBreakPoint for md
  lg?: number; //NOTE: gridBreakPoint for lg
  defaultValue: any; //NOTE: input default value
  datasInChildCreateDialog: any[]; //NOTE: input that you want to create
  isRequired?: boolean; //NOTE: input that is required
  isFetchData?: string; //NOTE: input name of data to fetch and add to switch case in controller
  isCreate?: boolean; //NOTE: input that you want to create
  setField?: string; // NOTE: field name that you want to set value
  setFieldValue?: string; // NOTE: value that you want to set to field name
  minValue?: number; //NOTE: input that you want to set min value
  typeInput?: string; //NOTE: input type
  sx?: object; //NOTE: sx for input
}

interface DialogCreateProp<T extends FieldValues> {
  datas: FieldValues[];
  open: boolean;
  title: string;
  type: string;
  onClose: () => void;
  onSubmit: (data: T, type: string) => void;
  onFetchData?: (id?: any, type?: string) => void;
  onCreateDialog?: (data: any, type: string) => void;
}

const DialogCreate = <T extends FieldValues>({ datas, open, title, type, onClose, onSubmit, onFetchData, onCreateDialog }: DialogCreateProp<T>) => {
  const { register, handleSubmit, control, getValues, formState: { errors }, setValue, reset, watch } = useForm<T>();
  const filterOptions = createFilterOptions<any>();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });
  const [openCreateDailog, setOpenCreateDailog] = useState<{ open: boolean, datas: any[], type: string, title: string; }>({ open: false, datas: [], type: "", title: "" });
  const [nameType, setNameType] = useState("");
  const [name, setName] = useState("");

  watch();



  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Renders a field according to its type.
   * @param field The field to be rendered.
   * @param index The index of the field in the array.
   * @returns The rendered field.
   */
  /******  4883e376-586e-4792-bc2c-875cd81b3b23  *******/
  const renderField = (field: IField, index: number) => {
    switch (field.type) {
      case "text":
        return (
          <Grid item xs={field.xs} sm={field.sm} md={field.md} lg={field.lg} sx={field.sx}>
            <Controller
              key={index}
              name={field.name as any}
              control={control}
              rules={{
                required: field.isRequired || false,
                min: field.minValue ? { value: field.minValue, message: `${field.label} ต้องไม่ต่ำกว่า ${field.minValue}` } : undefined
              }}
              defaultValue={field.defaultValue || ""}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextField
                    label={field.label}
                    value={value}
                    fullWidth
                    type={field.typeInput || "text"}
                    error={!!errors?.[field.name]}
                    {...register(field.name as Path<T>, { required: field.isRequired ? `กรุณาระบุ ${field.label}` : undefined, min: { value: 0, message: `${field.label}ต้องไม่ติดลบ` } })}
                    onChange={onChange}
                    helperText={
                      errors?.[field.name]?.message && errors?.[field.name]?.message !== undefined ? errors?.[field.name]?.message as string | undefined :
                        (errors?.[field.name] ? `กรุณาระบุ ${field.label}` :
                          undefined)
                    }
                    InputProps={{
                      inputProps: {
                        min: field.minValue
                      }
                    }}
                  />
                );
              }}
            />
          </Grid>
        );
      case "select":
        return (
          <Grid item xs={field.xs} sm={field.sm} md={field.md} lg={field.lg} sx={field.sx}>
            <Controller
              key={index}
              name={field.name as any}
              rules={{ required: field.isRequired || false }}
              control={control}
              defaultValue={field.defaultValue || ""}
              render={({ field: { onChange, value } }) => (
                <>
                  <Select
                    label={field.label}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    displayEmpty
                  >
                    {field.options?.map((option, idx) => (
                      <MenuItem key={idx} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors?.[field.name] ? `กรุณาระบุ${field.label}` : ""}
                  </FormHelperText>
                </>
              )}
            />
          </Grid>
        );
      case "autocomplete":
        return (
          <Grid item xs={field.xs} sm={field.sm} md={field.md} lg={field.lg} sx={field.sx}>
            <Controller
              key={index}
              name={field.name as any}
              control={control}
              rules={{ required: field.isRequired || false }}
              defaultValue={field.defaultValue}
              render={({ field: { onChange, value } }) => {
                const optionsWithCreate = field.options?.map((option) => {
                  if (typeof option === 'object' && option !== null) {
                    return {
                      id: option[field?.valueOption],
                      label: option[field?.labelOption],
                      value: option[field?.valueOption],
                      ...option as object
                    };
                  } else {
                    return {
                      id: option[field?.valueOption],
                      label: option[field?.labelOption],
                      value: option[field?.valueOption],
                    };
                  }
                }) || [];
                return (
                  <Autocomplete
                    options={
                      optionsWithCreate}
                    getOptionLabel={(option) => option.label}
                    value={value}
                    onChange={(_, newValue: any) => {
                      const path = field?.setField as Path<T>;
                      const value = field?.setFieldValue as Path<T>;
                      if (newValue?.value === "create") {
                        setNameType(field.name);
                        const _datas = field.datasInChildCreateDialog?.map((data) => {
                          let _data = { ...data };
                          if (Object.keys(data).includes('name')) {
                            _data = {
                              ..._data,
                              defaultValue: name
                            };
                          }
                          return _data;
                        });
                        setOpenCreateDailog({ open: true, datas: _datas, type: field.type, title: `สร้าง${field.label}` });
                      } else if (newValue?.value) {
                        onChange(newValue);
                        if (field?.isFetchData && onFetchData) {
                          onFetchData(newValue?.value, field?.isFetchData);
                        }
                        if (path in getValues()) {
                          setValue(path, newValue[value]);
                        }
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filterOptions(options, params);
                      if (params.inputValue !== '' && field?.isCreate) {
                        filtered.push({
                          label: `สร้าง '${params.inputValue}'`,
                          value: 'create',
                        });
                        setName(params.inputValue);
                      }
                      return filtered;
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value?.value}
                    renderOption={(props, option) => {
                      const { key, ...rest } = props;
                      return (
                        <Typography key={key} {...rest} variant="body2" >{option.value === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                          <AddCircleIcon /><Box component={'span'} ml={1}> {option.label}</Box>
                        </Box> : option.label}</Typography>
                      );
                    }}
                    renderInput={(params) => {
                      return <TextField {...params} label={field.label} error={!!errors?.[field.name]}
                        helperText={(errors?.[field.name] ? `กรุณาระบุ${field.label}` : "").toString()} fullWidth margin="normal" />;
                    }}
                    clearOnBlur
                  />
                );
              }}
            />
          </Grid>
        );
      case "checkbox":
        return (
          <Grid item xs={field.xs} sm={field.sm} md={field.md} lg={field.lg} display={"flex"} alignItems={"center"}>
            <Controller
              key={index}
              name={field.name as any}
              control={control}
              defaultValue={field.defaultValue}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label={field.label}
                />
              )}
            />
          </Grid>
        );
      default:
        return null;
    }
  };

  const handleOnSubmit = (type: "create" | "delete" | "approve" | "reject") => {
    if (type === "delete") {
      setOpenConfirm({
        open: true,
        title: "ลบข้อมูล",
        message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
        color: "error",
        type: "delete",
      });
    } else {
      handleSubmit(() => {
        setOpenConfirm({
          open: true,
          title: "บันทึกข้อมูล",
          message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: type === "reject" ? "error" : "primary",
          type: "create",
        });
      })();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  // NOTE:May use later
  // const handleSetValue = (field: string, value: any) => {
  //   setValue(field as Path<T>, value);
  // };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'lg'} onKeyDown={handleKeyDown} >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box component="form" mt={1}>
          <Grid container spacing={2} display={"flex"} alignItems={"center"}>
            {datas &&
              datas.map((data, index) => {

                return (
                  <Fragment key={index}>
                    {
                      renderField(data as IField, index)

                    }</Fragment>
                );
              })
            }
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => {
          onClose();
          reset();
        }}>
          ยกเลิก
        </Button>
        <Button variant="contained" onClick={() => handleOnSubmit("create")}>
          ยืนยัน
        </Button>
      </DialogActions>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {

          setOpenConfirm({ ...openConfirm, open: false });;
          onSubmit(getValues(), type);

          reset();
          onClose();
        }}
      />
      {onCreateDialog && <DialogCreate datas={openCreateDailog?.datas} open={openCreateDailog?.open} title={openCreateDailog?.title} type={openCreateDailog?.type} onClose={() => {
        setOpenCreateDailog({ ...openCreateDailog, open: false });
        setName("");
      }
      } onSubmit={(data) => {
        onCreateDialog && onCreateDialog(data, nameType);
        setName("");
      }}
      />}
    </Dialog>
  );
};

export default DialogCreate;
