import { observer } from "mobx-react-lite";
import OrderService, { IOrderCriteria } from "../Services/OrderService";
import { useEffect, useState } from "react";

import productService, { IProductListView } from "../Services/ProductService";
import dayjs from "dayjs";
import OrderListView from "../Views/OrderListView";

const OrderListController = observer(() => {

  const [isLoading, setIsLoading] = useState(false);
  const defaultCriteria: IOrderCriteria = {
    dateFrom: dayjs().startOf('month').toDate(),
    dateTo: dayjs().toDate(),
    invoiceNo: '',
    transportType: 0,
    destination: '',
    product: '',
    status: 99,
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchOrdersByCriteria(defaultCriteria);
    getProduct();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const fetchOrdersByCriteria = async (criteria: IOrderCriteria) => {
    try {
      setIsLoading(true);
      await OrderService.getByCriteria(criteria);
    } catch (error) {
      console.error("An error occurred while getting the   orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProduct = async () => {
    try {
      setIsLoading(true);
      await productService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return < OrderListView
    isLoading={isLoading}
    Orders={OrderService.ordersData ?? {} as IProductListView}
    productSelects={productService.selectProduct ?? []}
    onSearchByCriteria={fetchOrdersByCriteria}
  />;
});

export default OrderListController;