import { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import receiveService, { IReceive, IReceiveCriteria, IReceiveView } from "../Services/ReceiveService";
import ReceivesListView from "../Views/ReceivesListView";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";

const ReceivesListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [receiveData, setReceiveData] = useState<IReceiveView>();
  const defaultCriteria: IReceiveCriteria = {
    page: 0,
    limit: 10,
  };
  useEffect(() => {
    searchByCriteria(defaultCriteria);
    getWarehouses();
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const searchByCriteria = async (criteria: IReceiveCriteria) => {
    try {
      setIsLoading(true);

      await receiveService.getByCriteria(criteria).then((data) => {
        data?.datas?.sort((a: IReceive, b: IReceive) => (b.createAt?.toString() ?? '').localeCompare(a.createAt?.toString() ?? ''));
        setReceiveData(data);
      });
    } catch (error) {
      console.error("Error searching materials by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouses = async () => {
    try {
      setIsLoading(true);
      await receiveService.getWarehouses();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("inventory-receive");
    if (result) {
      setPermission(result);
    }
  };


  return <ReceivesListView
    receives={receiveData ?? {} as IReceiveView} onSerchByCriteria={searchByCriteria} warehousesData={receiveService.warehousesData || []} isLoading={isLoading}
    permission={permission ?? {} as IUserDetail} />;
});

export default ReceivesListController;
