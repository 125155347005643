import { useEffect, useState } from "react";
import transferService, { ITransfer, ITransferCriteria, ITransferView } from "../Services/TransferService";
import TransfersListView from "../Views/TransfersListView";
import { observer } from "mobx-react-lite";
import { documentStatus } from "../../../constants/documentStatus";
import { sortOptions } from "../../../constants/sortOptions";
import { enqueueSnackbar } from "notistack";
import TransferService from "../Services/TransferService";
import dayjs from "dayjs";
import materialService, { IMaterialInWarehouseCriteria } from "../Services/MaterialsService";
import loginService, { IUserDetail, IUserLogined } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import wareshousesService from "../Services/WareshousesService";

const TransfersListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [transferData, setTransferData] = useState<ITransferView>();
  const defaultCriteria: ITransferCriteria = {
    dateType: "createDate",
    docNo: "",
    dateFrom: dayjs().startOf('month').toDate(),
    dateTo: dayjs().toDate(),
    material: "",
    worker: "",
    sortBy: sortOptions[0],
    stockFrom: "",
    stockTo: "",
    page: 0,
    limit: 10,
    status: documentStatus["all"],
  };
  useEffect(() => {
    searchByCriteria(defaultCriteria);
    getWorkers();
    getWarehouses();
    getMaterials();
    getProvince();
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const searchByCriteria = async (criteria: ITransferCriteria) => {
    try {
      setIsLoading(true);
      await transferService.getByCriteria(criteria).then((data) => {
        data?.datas?.sort((a: ITransfer, b: ITransfer) => (b.createAt?.toString() ?? '').localeCompare(a.createAt?.toString() ?? ''));
        setTransferData(data);
      });
    } catch (error) {
      console.error("Error searching transfers by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWorkers = async () => {
    try {
      setIsLoading(true);
      await transferService.getWorkers();
    } catch (error) {
      console.error("Error fetching workers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouses = async () => {
    try {
      setIsLoading(true);
      await transferService.getWarehouses();
    } catch (error) {
      console.error("Error fetching warehouses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMaterials = async () => {
    try {
      setIsLoading(true);
      await transferService.getMaterials();
    } catch (error) {
      console.error("Error fetching materials:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (data: ITransfer) => {
    try {
      setIsLoading(true);
      await TransferService.create(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      searchByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async (data: ITransfer) => {
    try {
      setIsLoading(true);
      await TransferService.update(data?.id ?? "", data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      searchByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const getMaterialQuantityInWarehouse = async (criteria: IMaterialInWarehouseCriteria, warehouseType: string) => {
    try {
      setIsLoading(true);
      await materialService.getMaterialQuantityInWarehouse(criteria, warehouseType);
    } catch (error) {
      console.error("An error occurred while getting the material in warehouse:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProvince = async () => {
    try {
      await transferService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    }
  };

  const onFetchData = async (id?: any, type?: string) => {
    try {
      switch (type) {
        case "district": await transferService.getDistrict(id);
          break;
        case "subDistrict": await transferService.getSubDistrict(id);
          break;
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "material":
          response = await materialService.create(data);
          getMaterials();
          break;
        case "warehouse":
          response = await wareshousesService.create(data);
          getWarehouses();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("inventory-transfer");
    if (result) {
      setPermission(result);
    }
  };

  return <TransfersListView onSerchByCriteria={searchByCriteria}
    onCreate={create}
    transfers={transferData ?? {} as ITransferView}
    workerData={transferService.workersData}
    warehouseData={transferService.warehousesData}
    materialData={transferService.getMaterialsData}
    onUpdate={onUpdate}
    isLoading={isLoading}
    getMaterialQuantityInWarehouse={getMaterialQuantityInWarehouse}
    materialQuantityInWarehouseFromData={materialService.materialQuantityInWarehouseFromData}
    materialQuantityInWarehouseToData={materialService.materialQuantityInWarehouseToData}
    permission={permission ?? {} as IUserDetail}
    user={loginService.userLoginedData ?? {} as IUserLogined}
    onFetchData={onFetchData}
    onCreateDialog={onCreateDialog}
    provinceData={transferService.provinceData ?? []}
    districtData={transferService.districtData || []}
    subDistrictData={transferService.subDistrictData || []}
  />;
});

export default TransfersListController;
