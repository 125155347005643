import { FC, useState } from "react";
import { IOrderListView, IOrder, IOrderCriteria } from "../Services/OrderService";
import { IconButton, Button, Breadcrumbs, Typography, Box, Grid, TextField, FormControl, InputLabel, MenuItem, Select, Autocomplete } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { IproductSelect } from "../Services/ProductService";
import SearchIcon from "@mui/icons-material/Search";
import { statusOrderOptions } from "../../../constants/statusOrderOptions";

interface OrderListViewProps {
  isLoading: boolean;
  Orders: IOrderListView;
  productSelects: IproductSelect[];
  onSearchByCriteria: (criteria: IOrderCriteria) => void;
}

const OrderListView: FC<OrderListViewProps> = (props) => {
  const { register, getValues, control } = useForm<IOrderCriteria>({
    defaultValues: {
      dateFrom: dayjs().startOf('month').toDate(),
      dateTo: dayjs().toDate(),
      invoiceNo: '',
      transportType: 0,
      destination: '',
      product: '',
      status: 99
    }
  });

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [product, setProduct] = useState({ id: "", name: "ทั้งหมด" });
  const navigate = useNavigate();

  //SECTION - Variables for  TableDataList
  const OrderColumns = [
    { label: 'วันที่เอกสาร', render: (row: IOrder) => <>{row.dateFrom ? `${dayjs(row.dateFrom).format('DD/MM/YYYY')} - ${dayjs(row.dateTo).format('DD/MM/YYYY')}` : "-"}</> },
    { label: 'Invoice No', render: (row: IOrder) => row.invoiceNo },
    { label: 'สินค้า', render: (row: IOrder) => row.product?.name ?? "" },
    { label: 'สถานะ', render: (row: IOrder) => row?.isComplete ? "เสร็จสิ้น" : "รอดำเนินการ" },
  ];

  const OrderAction = (row: IOrder) => (
    <IconButton
      onClick={() => {
        navigate(`/process-order-edit/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        openCreate();
      }}>
      สร้าง
    </Button>
  );

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const data = getValues();
    props.onSearchByCriteria({ ...data, page: pageNumber, limit: rowLimit ? rowLimit : 10 });
  };

  const openCreate = () => {
    navigate("/process-order-create");
  };

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ใบคำสั่งซื้อ</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Box component="form">
            <Grid container spacing={2} alignItems={"end"}>
              <Grid item xs={12} sm={10} md={10} lg={10} >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Controller
                      name="dateFrom"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker sx={{ width: "100%" }}
                              defaultValue={dayjs().startOf('month')}
                              label="ตั้งแต่วันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Controller
                      name="dateTo"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker
                              sx={{ width: "100%" }}
                              defaultValue={dayjs()}
                              label="ถึงวันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} alignSelf={'flex-end'}>
                    <TextField fullWidth label="Invoice No" {...register("invoiceNo")} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Controller
                      name="product"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={product}
                          options={[{ id: "", name: "ทั้งหมด" }, ...props.productSelects]}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            field.onChange(newValue?.id);
                            setProduct(newValue ?? { id: "", name: "ทั้งหมด" });
                          }}
                          renderInput={(params) => <TextField
                            {...params} label="สินค้า" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id="status-select-label">สถานะ</InputLabel>
                          <Select
                            labelId="status-select-label"
                            id="status-select"
                            value={field.value?.toString() || "99"}
                            label="สถานะ"
                            onChange={(event) => {
                              field.onChange(event.target.value === "99" ? 99 : event.target.value);
                            }}
                          >
                            <MenuItem value="99">ทั้งหมด</MenuItem>
                            {statusOrderOptions.map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"end"}>
                <Button
                  onClick={() => searchByCriteria()}
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      }
      table={
        <>
          <TableDataList
            data={props.Orders.datas || []}
            total={props.Orders.total}
            columns={OrderColumns}
            actionButtons={actionButtons}
            rowActions={OrderAction}
            page={page}
            limit={limit}
            onPageChange={(newPage) => {
              searchByCriteria(newPage);
            }}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0);
              searchByCriteria(0, newLimit);
            }}
          />
        </>
      }
    />
  );
};

export default OrderListView;