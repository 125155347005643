import supplierTypeService, { ISupplierType, ISupplierTypeCriteria, ISupplierTypeListView } from "../Services/SupplierTypeService";
import { enqueueSnackbar } from "notistack";
import SupplierTypeListView from "../Views/SupplierTypeListView";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";

const SupplierTypeController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [supplierTypeData, setSupplierTypeData] = useState<ISupplierTypeListView>();
  const defaultCriteria: ISupplierTypeCriteria = {
    name: '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchSupplierTypesByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSupplierTypesByCriteria = async (criteria: ISupplierTypeCriteria) => {
    try {
      setIsLoading(true);
      await supplierTypeService.getByCriteria(criteria).then((data) => {
        data?.datas?.sort((a: ISupplierType, b: ISupplierType) => (b.createAt?.toString() ?? '').localeCompare(a.createAt?.toString() ?? ''));
        setSupplierTypeData(data);
      }
      );
    } catch (error) {
      console.error("An error occurred while getting the supplier types:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const createSupplierType = async (supplierType: ISupplierType) => {
    try {
      await supplierTypeService.create(supplierType);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      fetchSupplierTypesByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };

  const updateSupplierType = async (supplierType: ISupplierType) => {
    try {
      await supplierTypeService.update(supplierType);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      fetchSupplierTypesByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("supplier-type");
    if (result) {
      setPermission(result);
    }
  };


  return (
    <SupplierTypeListView onSubmit={(data: ISupplierType) => {
      createSupplierType(data);
    }}
      supplierTypes={supplierTypeData ?? {} as ISupplierTypeListView}
      onSearchByCriteria={(criteria: ISupplierTypeCriteria) => {
        fetchSupplierTypesByCriteria(criteria);
      }}
      onUpdate={(data: ISupplierType) => {
        updateSupplierType(data);
      }}
      isLoading={isLoading}
      permission={permission ?? {} as IUserDetail}
    />
  );

});

export default SupplierTypeController;