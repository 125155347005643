import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import EliminateListView from "../Views/EliminateListView";
import productService from "../Services/ProductService";
import dayjs from "dayjs";
import supplierService, { ISupplierCriteria } from "../../Supplier/Services/SupplierService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import loginService, { IUserDetail } from "../../Login/Services/LoginService";
import eliminateService, { IEliminate, IEliminateCriteria, IEliminateListView } from "../Services/EliminateService";

const EliminateController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [eliminateData, setEliminateData] = useState<IEliminateListView>();
  const defaultCriteria: IEliminateCriteria = {
    dateFrom: dayjs().startOf('month').toDate(),
    dateTo: dayjs().toDate(),
    invoiceNo: '',
    product: '',
    supplier: '',
    status: 'all',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getByCriteria(defaultCriteria);
    getProduct();
    fetchOrchardSupplier();
    checkPermissionActionButton();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const getProduct = async () => {
    try {
      setIsLoading(true);
      await productService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getByCriteria = async (criteria: IEliminateCriteria) => {
    try {
      setIsLoading(true);
      await eliminateService.getByCriteria(criteria).then((data) => {
        data?.data?.sort((a: IEliminate, b: IEliminate) => (b.createdAt?.toString() ?? '').localeCompare(a.createdAt?.toString() ?? ''));
        setEliminateData(data);
      }
      );
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchOrchardSupplier = async () => {
    try {
      await supplierService.getOrchardSupplier();
    } catch (error) {
      console.error("An error occurred while getting the suppliers:", error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-eliminate");
    if (result) {
      setPermission(result);
    }
  };


  return <EliminateListView
    isLoading={isLoading}
    ordersEliminate={eliminateData ?? {} as IEliminateListView}
    productSelects={productService.selectProduct ?? []}
    onSearchByCriteria={getByCriteria}
    suppliers={supplierService.supplierListData ?? [] as ISupplierCriteria[]}
    permission={permission ?? {} as IUserDetail}
    user={loginService.userLoginedData ?? {} as IUserDetail}

  />;
});

export default EliminateController;