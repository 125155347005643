import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import packagingService, { IPackagingListView, IPackagingCriteria } from "../Services/PackagingService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import PackagingListView from "../Views/PackagingListView";
import { IUserDetail } from "../../Login/Services/LoginService";
const PackagingListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [packagesData, setPackagesData] = useState<IPackagingListView>();
  const defaultCriteria: IPackagingCriteria = {
    dateFrom: dayjs().startOf('month').toDate(),
    dateTo: dayjs().toDate(),
    docNo: '',
    status: 'all',
    page: 0,
    limit: 10,
  };
  useEffect(() => {
    fetchOrdersByCriteria(defaultCriteria);
    checkPermissionActionButton();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const fetchOrdersByCriteria = async (criteria: IPackagingCriteria) => {
    try {
      setIsLoading(true);
      await packagingService.getByCriteria(criteria).then((data) => {
        data?.datas?.sort((a: any, b: any) => (b.createdAt?.toString() ?? '').localeCompare(a.createdAt?.toString() ?? ''));
        setPackagesData(data);
      }
      );
    } catch (error) {
      console.error("An error occurred while getting the packages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-packaging");
    if (result) {
      setPermission(result);
    }
  };

  return <PackagingListView isLoading={isLoading} packagings={packagesData ?? {} as IPackagingListView} onSearchByCriteria={fetchOrdersByCriteria} permission={permission ?? {} as IUserDetail} />;
});

export default PackagingListController;