import { FC } from 'react';
import { TableRow, TableCell, Link } from '@mui/material';
import { IOrderWithoutRandom } from '../features/ProductProcess/Services/OrderService';
import { useNavigate } from 'react-router-dom';
import { IFarmProductReceipt, IFarmProductReceiptWithcostPerBox } from '../features/ProductProcess/Services/FarmProductReceipt';
import { IPackagingDetail } from '../features/ProductProcess/Services/PackagingService';
import { IRandomInspection } from '../features/ProductProcess/Services/RandomInspectionService';
import { IBakeDetail } from '../features/ProductProcess/Services/BakeService';
import { IShippingDetail } from '../features/ProductProcess/Services/ShippingService';
import { IUserLogined } from '../features/Login/Services/LoginService';

interface TableProcessSummaryRowProps {
  order: IOrderWithoutRandom;
  vht: string[];
  quality: string[];
  pack: string[];
  shipping: string[];
  user?: IUserLogined;
  allColSpan: number;
}

const commonStyles = (isBorderRight: boolean = false, head: boolean = false) => ({
  bgcolor: head ? '#efeded' : 'background.paper',
  border: 0.5,
  borderColor: 'lightgrey',
  textAlign: 'center',
  borderLeftColor: 'lightgrey',
  borderRight: isBorderRight ? 2 : 0.5,
  borderRightColor: 'lightgrey',
});

const TableProcessSummaryRow: FC<TableProcessSummaryRowProps> = (props: TableProcessSummaryRowProps) => {

  const rows: any = [];
  const userGroup = props.user?.userGroup;
  const permission = (userGroup?.canCost || userGroup?.canFinance) ? true : false;
  const navigate = useNavigate();
  const handleNavigate = (type: string, id?: string) => {
    //NOTE: Check can approve 
    switch (type) {
      case "farm":
        userGroup?.canApprove ? navigate(`/process-product-receipt-edit/${id}`, { state: { from: 'order' } }) : navigate(`/process-product-receipt-edit/${id}`);
        break;
      case "random":
        userGroup?.canApprove ? navigate(`/process-random-inspection-create/${id}`, { state: { from: 'order' } }) : navigate(`/process-random-inspection-create/${id}`);
        break;
      case "bake":
        userGroup?.canApprove ? navigate(`/process-bake-edit/${id}`, { state: { from: 'order' } }) : navigate(`/process-bake-edit/${id}`);
        break;
      case "packaging":
        userGroup?.canApprove ? navigate(`/process-packaging-edit/${id}`, { state: { from: 'order' } }) : navigate(`/process-packaging-edit/${id}`);
        break;
      case "shipping":
        userGroup?.canApprove ? navigate(`/process-shipping-edit/${id}`, { state: { from: 'order' } }) : navigate(`/process-shipping-edit/${id}`);
        break;
    }
  };

  const calShipInBake = (bake: IBakeDetail) => {
    let _ship = 0;
    bake?.packagings?.forEach((pack: IPackagingDetail) => {
      if (pack?.packaging?.shipping?.length === 0) {
        _ship += 1;
      } else {
        _ship += (pack?.packaging?.shipping?.length || 1);
      }
    });
    return _ship;
  };

  // NOTE: cal row span for all farm
  const calLongestInFarm = (farm: IFarmProductReceipt) => {
    const _random = farm?.randomInspections?.length || 1;
    let _bake = 0;
    let _pack = 0;
    let _ship = 0;

    farm?.randomInspections?.forEach((random: IRandomInspection) => {
      const PackFromRandom = random?.packagings?.filter((pack: IPackagingDetail) => pack?.id && !pack?.isBake);
      // find bake length that not have packaging or bake that have packagind detail with isBake
      const BakeInRandom = random?.bakes?.filter((bake: IBakeDetail) => ((bake?.id && bake?.packagings && bake?.packagings?.length === 0) || (bake?.id && bake?.packagings && bake?.packagings?.length > 0 && bake?.packagings?.find((pack: IPackagingDetail) => pack?.isBake))));
      // cal bake
      if (BakeInRandom && BakeInRandom?.length > 0) {
        _bake += ((BakeInRandom?.length || 1) + (PackFromRandom?.length || 0));
      } else {
        _bake += 1;
      }
      // cal pack in random
      if (PackFromRandom && PackFromRandom?.length > 0) {
        _pack += (PackFromRandom?.length || 0);
      }
      else {
        if (BakeInRandom?.length === 0) {
          _pack += 1;
          _ship += 1;
        }
      }
      // cal ship in random
      random?.packagings?.forEach((pack: IPackagingDetail) => {
        if (!pack?.isBake) {
          if (pack?.packaging?.shipping?.length === 0) {
            _ship += 1;
          } else {
            _ship += (pack?.packaging?.shipping?.length || 0);
          }
        }
      });

      // cal pack in bake
      random?.bakes?.forEach((bake) => {
        const PackFromBake = bake?.packagings?.filter((pack: IPackagingDetail) => pack?.isBake);
        if (PackFromBake && PackFromBake?.length > 0) {
          _pack += (PackFromBake?.length || 0);
        } else {
          _pack += 1;
          _ship += 1;
        }

        // cal ship in bake
        bake?.packagings?.forEach((pack: IPackagingDetail) => {
          if (pack?.isBake) {
            if (pack?.packaging?.shipping?.length === 0) {
              _ship += 1;
            } else {
              _ship += (pack?.packaging?.shipping?.length || 0);
            }
          }
        });
      });
    });
    if (_random > _bake && _random > _pack && _random > _ship) {
      return _random;
    } else if (_bake > _pack && _bake > _ship) {
      return _bake;
    } else if (_ship < _pack) {
      return _pack;
    } else { return _ship; }
  };
  //  NOTE: cal row span for all bake 
  const CalLongestInBake = (packagings: IPackagingDetail[]) => {
    const _pack = packagings?.length || 1;
    let _ship = 0;
    packagings?.forEach((pack: IPackagingDetail) => {
      if (pack?.packaging?.shipping?.length === 0) {
        _ship += 1;
      } else {
        _ship += (pack?.packaging?.shipping?.length || 1);
      }
    });
    return _pack > _ship ? _pack : _ship;
  };

  //  NOTE: cal row span for all random
  const CalLonhestInRandom = (randoms: IRandomInspection) => {
    // NOTE: Find all bake length
    let _bake = 0;
    let _pack = 0;
    let _ship = 0;
    //NOTE: all pack in not bake
    const PackFromRandomLength = randoms?.packagings?.filter((pack: IPackagingDetail) => pack?.id && !pack?.isBake)?.length;
    //NOTE: find bake length that not have packaging or bake that have packagind detail with isBake
    const BakeLength = randoms?.bakes?.filter((bake: IBakeDetail) => ((bake?.id && bake?.packagings && bake?.packagings?.length === 0) || (bake?.id && bake?.packagings && bake?.packagings?.length > 0 && bake?.packagings?.find((pack: IPackagingDetail) => pack?.isBake))))?.length;

    //NOTE: find bake that have pack
    const _BakeHavePackLength = randoms?.bakes?.filter((bake: IBakeDetail) => ((bake?.id && bake?.packagings && bake?.packagings?.length > 0 && bake?.packagings?.find((pack: IPackagingDetail) => pack?.isBake))))?.length;
    //NOTE: count pack in bake
    const _packInBake = randoms?.bakes?.reduce((accBake, bake: IBakeDetail) => {
      //NOTE: all pack in bake
      const bakeCount = bake?.packagings?.reduce((accPack, pack: IPackagingDetail) => {
        if (pack?.isBake) {
          return accPack + 1;
        } else {
          return accPack;
        }
      }, 0);
      return accBake + (bakeCount || 0);
    }, 0);

    // pack in not bake that have ship
    const _PackFromRandomLength = randoms?.packagings?.filter((pack: IPackagingDetail) => pack?.id && !pack?.isBake && pack?.packaging?.shipping && (pack?.packaging?.shipping?.length > 0))?.length;
    // NOTE: find all ship in not bake
    const _shipInNotBake = randoms?.packagings?.reduce((accPack, pack: IPackagingDetail) => {
      if (pack?.id && !pack?.isBake && !pack?.packaging?.shipping) {
        return accPack + 1;
      } else {
        return accPack;
      }
    }, 0);

    //NOTE: count pack that have ship in bake
    const _packHaveShipInBake = randoms?.bakes?.reduce((accBake, bake: IBakeDetail) => {
      //NOTE: all pack in bake
      const bakeCount = bake?.packagings?.reduce((accPack, pack: IPackagingDetail) => {
        if (pack?.isBake && pack?.packaging?.shipping && (pack?.packaging?.shipping?.length > 0)) {
          return accPack + 1;
        } else {
          return accPack;
        }
      }, 0);
      return accBake + (bakeCount || 0);
    }, 0);

    // NOTE: count ship in bake
    const _shipInBake = randoms?.bakes?.reduce((accBake, bake: IBakeDetail) => {
      //NOTE: all pack in bake
      const bakeCount = bake?.packagings?.reduce((accPack, pack: IPackagingDetail) => {
        if (pack?.isBake && pack?.packaging?.shipping && (pack?.packaging?.shipping?.length > 0)) {
          return accPack + (pack?.packaging?.shipping?.length || 1);
        } else {
          return accPack;
        }
      }, 0);
      return accBake + (bakeCount || 0);
    }, 0);

    //NOTE: bake = all bake +  all pack in not bake
    _bake = ((BakeLength || 0) + (PackFromRandomLength || 0)) || 1;
    // NOTE: pack = (all bake - bake have pack) + random have not pack + random have pack
    _pack = (Number(BakeLength || 0) - Number(_BakeHavePackLength || 0)) + Number(_packInBake) + Number(PackFromRandomLength || 0);
    // NOTE: ship = bake have pack + bake have not pack
    _ship = Number(_pack) - Number(_PackFromRandomLength || 0) + Number(_shipInNotBake || 0) - Number(_packHaveShipInBake) + Number(_shipInBake || 0);

    if ((_bake > _pack && _bake > _ship)) {
      return _bake;
    }
    else if (_pack > _ship) {
      return _pack;
    }
    else {
      return _ship;
    }
  };

  // NOTE:  Loop through each farm
  props.order?.farmProductReceipts?.forEach((farm: IFarmProductReceiptWithcostPerBox, farmIndex: number) => {
    let inspectionCount = farm?.randomInspections?.length || 0;
    const _calLongestFarm = calLongestInFarm(farm) || 1;
    if (inspectionCount === 0) {
      rows.push(
        <>
          <TableRow key={farm?.id}>
            {/*  NOTE: farm */}
            <TableCell rowSpan={_calLongestFarm} sx={{ ...commonStyles() }}>
              <Link
                component="button"
                variant="body2"
                sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                onClick={() => {
                  handleNavigate("farm", farm?.id);
                }}
              >
                {`${farm?.docNo} ${farm?.status}`}
              </Link>
            </TableCell>
            <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{props.order?.product?.name}</TableCell>
            <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.agent?.name}</TableCell>
            <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.basketAmount}</TableCell>
            <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{`${farm?.totalWeight} (${farm?.pricePerKg})`}</TableCell>
            <TableCell rowSpan={_calLongestFarm} sx={commonStyles(true)}>{permission ? (farm?.grandTotal || 0) : "-"}</TableCell>
            {/*  NOTE: no data */}
            <TableCell colSpan={props.quality?.length} sx={commonStyles(true)}>ไม่มีข้อมูลตรวจคุณภาพ</TableCell>
            <TableCell colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>
            <TableCell colSpan={props.pack?.length} sx={commonStyles(true)}>ไม่มีข้อมูลแพ็ค</TableCell>
            <TableCell colSpan={props.shipping?.length} sx={commonStyles()}>ไม่มีข้อมูล Shipping</TableCell>
            {/*  NOTE: Cost */}
            <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{permission ? (farm?.costPerBox || 0) : "-"}</TableCell>
          </TableRow>
        </>
      );
    } else {
      farm?.randomInspections?.forEach((random: IRandomInspection, randomIndex: number) => {
        const BakeInRandom = random?.bakes?.filter((bake: IBakeDetail) => ((bake?.id && bake?.packagings && bake?.packagings?.length === 0) || (bake?.id && bake?.packagings && bake?.packagings?.length > 0 && bake?.packagings?.find((pack: IPackagingDetail) => pack?.isBake))));
        let bakeCount = BakeInRandom?.length || 0;
        const _logestRandom = CalLonhestInRandom(random);
        //NOTE: filter pack only isBake is false
        const PackFromRandom = random?.packagings?.filter((pack: IPackagingDetail) => pack?.id && !pack?.isBake);
        const _calLongestBakeForRandom = CalLongestInBake(PackFromRandom || []);
        let _packCount = PackFromRandom?.length || 0;
        //NOTE: Check if random has bakes
        if (bakeCount === 0) {
          //NOTE: Check if random has packs
          if (PackFromRandom && _packCount > 0) {
            PackFromRandom?.forEach((pack, packIndex) => {
              if (!pack?.isBake) {     // Check if pack has ships
                if (!pack?.packaging?.shipping || pack?.packaging?.shipping?.length === 0) {
                  rows.push(
                    <TableRow key={`random-${farm.id}-${random.id}-${pack.id}`}>
                      {/* NOTE: farm */}
                      {randomIndex === 0 && packIndex === 0 && <>
                        <TableCell rowSpan={_calLongestFarm} sx={{ ...commonStyles() }}>
                          <Link
                            component="button"
                            variant="body2"
                            sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                            onClick={() => {
                              handleNavigate("farm", farm?.id);
                            }}
                          >
                            {`${farm?.docNo} ${farm?.status}`}
                          </Link>
                        </TableCell>
                        <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{props.order?.product?.name}</TableCell>
                        <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.agent?.name}</TableCell>
                        <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.basketAmount}</TableCell>
                        <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{`${farm?.totalWeight} (${farm?.pricePerKg})`}</TableCell>
                        <TableCell rowSpan={_calLongestFarm} sx={commonStyles(true)}>{permission ? (farm?.grandTotal || 0) : "-"}</TableCell>
                      </>}
                      {/* NOTE: Random  */}
                      {packIndex === 0 && (
                        <>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>
                            <Link
                              component="button"
                              variant="body2"
                              sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                              onClick={() => {
                                handleNavigate("random", random?.id);
                              }}
                            >
                              {`${random?.docNo} ${random?.status}`}
                            </Link></TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{farm?.vapourHeatTreatment?.name}</TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{random?.actualWeight}</TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>
                            {`${random?.out || ""} | ${random?.claimsWeight || ""} | ${random?.totalInspectionWeight || ""}`}
                          </TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{permission ? (random?.cutPoleWeight || 0) : "-"}</TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{permission ? (random?.lostM || 0) : "-"}</TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles(true)}>{random?.totalRemainingWeight}</TableCell>
                        </>
                      )}
                      {/* NOTE: Bake */}
                      {packIndex === 0 && <TableCell rowSpan={_calLongestBakeForRandom} colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>}
                      {/* NOTE: Pack */}
                      {(
                        <>
                          <TableCell sx={commonStyles()}> <Link
                            component="button"
                            variant="body2"
                            sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                            onClick={() => {
                              handleNavigate("packaging", pack?.packaging?.id);
                            }}
                          >
                            {`${pack?.packaging?.docNo || "-"} ${pack?.packaging?.status}`}
                          </Link></TableCell>
                          <TableCell sx={commonStyles()}>{pack?.quantity || "-"}</TableCell>
                          <TableCell sx={commonStyles()}>{pack?.totalBox || "-"}</TableCell>
                          <TableCell sx={commonStyles(true)}>{permission ? (pack?.materialCost || 0) : "-"}</TableCell>
                        </>
                      )}
                      {/*NOTE: No data for Shipping */}
                      {<TableCell rowSpan={1} colSpan={props.shipping?.length} sx={commonStyles()}>ไม่มีข้อมูล Shipping</TableCell>}

                      {/* NOTE: Cost */}
                      {randomIndex === 0 && packIndex === 0 && <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{permission ? (farm?.costPerBox || 0) : "-"}</TableCell>}
                    </TableRow>
                  );
                } else {
                  // NOTE: cal all pack row
                  const _shipSpanForRandom = pack?.packaging?.shipping?.length || 1;
                  pack?.packaging?.shipping?.forEach((ship: IShippingDetail, shipIndex: number) => {
                    rows.push(
                      <TableRow key={`random-${farm.id}-${random.id}-${pack.id}-${ship.id}`}>
                        {/* NOTE: farm */}
                        {randomIndex === 0 && packIndex === 0 && shipIndex === 0 && <>
                          <TableCell rowSpan={_calLongestFarm} sx={{ ...commonStyles() }}>
                            <Link
                              component="button"
                              variant="body2"
                              sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                              onClick={() => {
                                handleNavigate("farm", farm?.id);
                              }}
                            >
                              {`${farm?.docNo} ${farm?.status}`}
                            </Link>
                          </TableCell>
                          <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{props.order?.product?.name}</TableCell>
                          <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.agent?.name}</TableCell>
                          <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.basketAmount}</TableCell>
                          <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{`${farm?.totalWeight} (${farm?.pricePerKg})`}</TableCell>
                          <TableCell rowSpan={_calLongestFarm} sx={commonStyles(true)}>{permission ? (farm?.grandTotal || 0) : "-"}</TableCell>
                        </>}
                        {/* NOTE: Random  */}
                        {packIndex === 0 && shipIndex === 0 && (
                          <>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>
                              <Link
                                component="button"
                                variant="body2"
                                sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                                onClick={() => {
                                  handleNavigate("random", random?.id);
                                }}
                              >
                                {`${random?.docNo} ${random?.status}`}
                              </Link></TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{farm?.vapourHeatTreatment?.name}</TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{random?.actualWeight}</TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>
                              {`${random?.out || ""} | ${random?.claimsWeight || ""} | ${random?.totalInspectionWeight || ""}`}
                            </TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{permission ? (random?.cutPoleWeight || 0) : "-"}</TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{permission ? (random?.lostM || 0) : "-"}</TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles(true)}>{random?.totalRemainingWeight}</TableCell>
                          </>
                        )}
                        {/* NOTE: Bake */}
                        {packIndex === 0 && shipIndex === 0 && <TableCell rowSpan={_calLongestBakeForRandom} colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>}
                        {/* NOTE: Pack */}
                        {shipIndex === 0 && (
                          <>
                            <TableCell rowSpan={_shipSpanForRandom} sx={commonStyles()}> <Link
                              component="button"
                              variant="body2"
                              sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                              onClick={() => {
                                handleNavigate("packaging", pack?.packaging?.id);
                              }}
                            >
                              {`${pack?.packaging?.docNo || "-"} ${pack?.packaging?.status}`}
                            </Link></TableCell>
                            <TableCell rowSpan={_shipSpanForRandom} sx={commonStyles()}>{pack?.quantity || "-"}</TableCell>
                            <TableCell rowSpan={_shipSpanForRandom} sx={commonStyles()}>{pack?.totalBox || "-"}</TableCell>
                            <TableCell rowSpan={_shipSpanForRandom} sx={commonStyles(true)}>{permission ? (pack?.materialCost || 0) : "-"}</TableCell>
                          </>
                        )}
                        {/* NOTE: shipping*/}
                        {(
                          <>
                            <TableCell sx={commonStyles()}>
                              <Link
                                component="button"
                                variant="body2"
                                sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                                onClick={() => {
                                  handleNavigate("shipping", ship?.shipping?.id);
                                }}
                              >
                                {`${ship?.shipping?.docNo} ${ship?.shipping?.status}`}
                              </Link>
                            </TableCell>
                            <TableCell sx={commonStyles()}>{ship?.shipping?.type?.name || "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{ship?.quantity || "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.nonVat || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.vat || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.vat7 || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.vat3 || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.totalAmount || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.costPerBox || 0) : "-"}</TableCell>
                          </>
                        )}
                        {/* NOTE: Cost */}
                        {randomIndex === 0 && packIndex === 0 && shipIndex === 0 && <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{permission ? (farm?.costPerBox || 0) : "-"}</TableCell>}
                      </TableRow>
                    );
                  });
                }
              }
            });
          } else {
            rows.push(
              <TableRow key={`${farm.id}-${random.id}`}>
                {/*NOTE: farm */}
                {randomIndex === 0 && <>
                  <TableCell rowSpan={_calLongestFarm} sx={{ ...commonStyles() }}>
                    <Link
                      component="button"
                      variant="body2"
                      sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                      onClick={() => {
                        handleNavigate("farm", farm?.id);
                      }}
                    >
                      {`${farm?.docNo} ${farm?.status}`}
                    </Link>
                  </TableCell>
                  <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{props.order?.product?.name}</TableCell>
                  <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.agent?.name}</TableCell>
                  <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.basketAmount}</TableCell>
                  <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{`${farm?.totalWeight} (${farm?.pricePerKg})`}</TableCell>
                  <TableCell rowSpan={_calLongestFarm} sx={commonStyles(true)}>{permission ? (farm?.grandTotal || 0) : "-"}</TableCell>
                </>}
                {/*NOTE: random */}
                <TableCell sx={commonStyles()}>
                  <Link
                    component="button"
                    variant="body2"
                    sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                    onClick={() => {
                      handleNavigate("random", random?.id);
                    }}
                  >
                    {`${random?.docNo} ${random?.status}`}
                  </Link></TableCell>
                <TableCell sx={commonStyles()}>{farm?.vapourHeatTreatment?.name}</TableCell>
                <TableCell sx={commonStyles()}>{random?.actualWeight}</TableCell>
                <TableCell sx={commonStyles()}>
                  {`${random?.out || ""} | ${random?.claimsWeight || ""} | ${random?.totalInspectionWeight || ""}`}
                </TableCell>
                <TableCell sx={commonStyles()}>{permission ? (random?.cutPoleWeight || 0) : "-"}</TableCell>
                <TableCell sx={commonStyles()}>{permission ? (random?.lostM || 0) : "-"}</TableCell>
                <TableCell sx={commonStyles(true)}>{random?.totalRemainingWeight}</TableCell>
                {/* NOTE: no data */}
                <TableCell colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>
                <TableCell colSpan={props.pack?.length} sx={commonStyles(true)}>ไม่มีข้อมูลแพ็ค</TableCell>
                <TableCell colSpan={props.shipping?.length} sx={commonStyles()}>ไม่มีข้อมูล Shipping</TableCell>
                {/* NOTE: Cost */}
                {randomIndex === 0 && <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{permission ? (farm?.costPerBox || 0) : "-"}</TableCell>}
              </TableRow>
            );
          }
        } else {
          BakeInRandom?.forEach((bake, bakeIndex) => {
            let packCount = bake?.packagings?.length || 0;
            const _packCountInBake = packCount === 0 ? 1 : packCount;
            if (packCount === 0) {
              rows.push(
                <TableRow key={`${farm.id}-${random.id}-${bake.id}`}>
                  {/* NOTE: farm */}
                  {randomIndex === 0 && bakeIndex === 0 && (<>
                    <TableCell rowSpan={_calLongestFarm} sx={{ ...commonStyles() }}>
                      <Link
                        component="button"
                        variant="body2"
                        sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                        onClick={() => {
                          handleNavigate("farm", farm?.id);
                        }}
                      >
                        {`${farm?.docNo} ${farm?.status}`}
                      </Link>
                    </TableCell>
                    <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{props.order?.product?.name}</TableCell>
                    <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.agent?.name}</TableCell>
                    <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.basketAmount}</TableCell>
                    <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{`${farm?.totalWeight} (${farm?.pricePerKg})`}</TableCell>
                    <TableCell rowSpan={_calLongestFarm} sx={commonStyles(true)}>{permission ? (farm?.grandTotal || 0) : "-"}</TableCell>
                  </>)}
                  {/* NOTE: random */}
                  {bakeIndex === 0 && (
                    <>
                      <TableCell rowSpan={_logestRandom} sx={commonStyles()}>  <Link
                        component="button"
                        variant="body2"
                        sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                        onClick={() => {
                          handleNavigate("random", random?.id);
                        }}
                      >
                        {`${random?.docNo} ${random?.status}`}
                      </Link></TableCell>
                      <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{farm?.vapourHeatTreatment?.name}</TableCell>
                      <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{random?.actualWeight}</TableCell>
                      <TableCell rowSpan={_logestRandom} sx={commonStyles()}>
                        {`${random?.out || ""} | ${random?.claimsWeight || ""} | ${random?.totalInspectionWeight || ""}`}
                      </TableCell>
                      <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{permission ? (random?.cutPoleWeight || 0) : "-"}</TableCell>
                      <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{permission ? (random?.lostM || 0) : "-"}</TableCell>
                      <TableCell rowSpan={_logestRandom} sx={commonStyles(true)}>{random?.totalRemainingWeight}</TableCell>
                    </>
                  )}
                  {/* NOTE: bake */}
                  <TableCell sx={commonStyles()}>
                    <Link
                      component="button"
                      variant="body2"
                      sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                      onClick={() => {
                        handleNavigate("bake", bake?.bake?.id);
                      }}
                    >
                      {`${bake?.bake?.docNo} ${bake?.bake?.status}`}
                    </Link></TableCell>
                  <TableCell sx={commonStyles()}>{permission ? (bake?.bake?.eliminateWeight || 0) : "-"}</TableCell>
                  <TableCell sx={commonStyles()}>{permission ? (bake?.bake?.vhtCost || 0) : "-"}</TableCell>
                  <TableCell sx={commonStyles()}>{permission ? (bake?.bake?.vat7 || 0) : "-"}</TableCell>
                  <TableCell sx={commonStyles()}>{permission ? (bake?.bake?.vat3 || 0) : "-"}</TableCell>
                  <TableCell sx={commonStyles(true)}>{permission ? (bake?.bake?.totalVht || 0) : "-"}</TableCell>
                  {/* NOTE: no data */}
                  <TableCell colSpan={props.pack?.length} sx={commonStyles(true)}>ไม่มีข้อมูลแพ็ค</TableCell>
                  <TableCell colSpan={props.shipping?.length} sx={commonStyles()}>ไม่มีข้อมูล Shipping</TableCell>
                  {/* NOTE: Cost */}
                  {randomIndex === 0 && bakeIndex === 0 && <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{permission ? (farm?.costPerBox || 0) : "-"}</TableCell>}
                </TableRow>
              );
            } else {
              bake?.packagings?.forEach((pack, packIndex) => {
                const shipSpan = pack?.packaging?.shipping?.length || 0;
                const _shipSpan = shipSpan === 0 ? 1 : shipSpan;
                const _calShipInbake = calShipInBake(bake);
                const _RandomInPack = _packCountInBake > _calShipInbake ? _packCountInBake : _calShipInbake;
                // NOTE: Check if pack has ships
                if (shipSpan === 0) {
                  rows.push(
                    <TableRow key={`${farm.id}-${random.id}-${bake.id}-${pack.id}`}>
                      {/* NOTE: farm */}
                      {randomIndex === 0 && bakeIndex === 0 && packIndex === 0 && <>
                        <TableCell rowSpan={_calLongestFarm} sx={{ ...commonStyles() }}>
                          <Link
                            component="button"
                            variant="body2"
                            sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                            onClick={() => {
                              handleNavigate("farm", farm?.id);
                            }}
                          >
                            {`${farm?.docNo} ${farm?.status}`}
                          </Link>
                        </TableCell>
                        <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{props.order?.product?.name}</TableCell>
                        <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.agent?.name}</TableCell>
                        <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.basketAmount}</TableCell>
                        <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{`${farm?.totalWeight} (${farm?.pricePerKg})`}</TableCell>
                        <TableCell rowSpan={_calLongestFarm} sx={commonStyles(true)}>{permission ? (farm?.grandTotal || 0) : "-"}</TableCell>
                      </>}
                      {/* NOTE: Random  */}
                      {bakeIndex === 0 && packIndex === 0 && (
                        <>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>  <Link
                            component="button"
                            variant="body2"
                            sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                            onClick={() => {
                              handleNavigate("random", random?.id);
                            }}
                          >
                            {`${random?.docNo} ${random?.status}`}
                          </Link></TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{farm?.vapourHeatTreatment?.name}</TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{random?.actualWeight}</TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>
                            {`${random?.out || ""} | ${random?.claimsWeight || ""} | ${random?.totalInspectionWeight || ""}`}
                          </TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{permission ? (random?.cutPoleWeight || 0) : "-"}</TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{permission ? (random?.lostM || 0) : "-"}</TableCell>
                          <TableCell rowSpan={_logestRandom} sx={commonStyles(true)}>{random?.totalRemainingWeight}</TableCell>
                        </>
                      )}
                      {/* NOTE: Bake */}
                      {packIndex === 0 && (
                        <>
                          <TableCell rowSpan={_RandomInPack} sx={commonStyles()}> <Link
                            component="button"
                            variant="body2"
                            sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                            onClick={() => {
                              handleNavigate("bake", bake?.bake?.id);
                            }}
                          >
                            {`${bake?.bake?.docNo} ${bake?.bake?.status}`}
                          </Link></TableCell>
                          <TableCell rowSpan={_RandomInPack} sx={commonStyles()}>{permission ? (bake?.bake?.eliminateWeight || 0) : "-"}</TableCell>
                          <TableCell rowSpan={_RandomInPack} sx={commonStyles()}>{permission ? (bake?.bake?.vhtCost || 0) : "-"}</TableCell>
                          <TableCell rowSpan={_RandomInPack} sx={commonStyles()}>{permission ? (bake?.bake?.vat7 || 0) : "-"}</TableCell>
                          <TableCell rowSpan={_RandomInPack} sx={commonStyles()}>{permission ? (bake?.bake?.vat3 || 0) : "-"}</TableCell>
                          <TableCell rowSpan={_RandomInPack} sx={commonStyles(true)}>{permission ? (bake?.bake?.totalVht || 0) : "-"}</TableCell>
                        </>
                      )}
                      {/* NOTE: pack */}
                      {(
                        <>
                          <TableCell sx={commonStyles()}> <Link
                            component="button"
                            variant="body2"
                            sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                            onClick={() => {
                              handleNavigate("packaging", pack?.packaging?.id);
                            }}
                          >
                            {`${pack?.packaging?.docNo || "-"} ${pack?.packaging?.status}`}
                          </Link></TableCell>
                          <TableCell sx={commonStyles()}>{pack?.quantity || "-"}</TableCell>
                          <TableCell sx={commonStyles()}>{pack?.totalBox || "-"}</TableCell>
                          <TableCell sx={commonStyles(true)}>{permission ? (pack?.materialCost || 0) : "-"}</TableCell>
                        </>
                      )}
                      {/* NOTE: No data for Shipping */}
                      <TableCell colSpan={props.shipping?.length} sx={commonStyles()}>ไม่มีข้อมูล Shipping</TableCell>
                      {/* NOTE: Cost */}
                      {randomIndex === 0 && bakeIndex === 0 && packIndex === 0 && <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{permission ? (farm?.costPerBox || 0) : "-"}</TableCell>}
                    </TableRow>
                  );
                } else {
                  // NOTE: filter pack with isBake
                  const _packIsBake = bake?.packagings?.filter((pack: IPackagingDetail) => pack?.isBake);
                  const _longestInBake = CalLongestInBake(_packIsBake || []);
                  // NOTE: Loop through each ship in the pack
                  pack?.packaging?.shipping?.forEach((ship: IShippingDetail, shipIndex: number) => {
                    rows.push(
                      <TableRow key={`${farm.id}-${random.id}-${bake.id}-${pack.id}-${ship.id}`}>
                        {/* NOTE: farm */}
                        {randomIndex === 0 && bakeIndex === 0 && packIndex === 0 && shipIndex === 0 && <>
                          <TableCell rowSpan={_calLongestFarm} sx={{ ...commonStyles() }}>
                            <Link
                              component="button"
                              variant="body2"
                              sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                              onClick={() => {
                                handleNavigate("farm", farm?.id);
                              }}
                            >
                              {`${farm?.docNo} ${farm?.status}`}
                            </Link>
                          </TableCell>
                          <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{props.order?.product?.name}</TableCell>
                          <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.agent?.name}</TableCell>
                          <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{farm?.basketAmount}</TableCell>
                          <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{`${farm?.totalWeight} (${farm?.pricePerKg})`}</TableCell>
                          <TableCell rowSpan={_calLongestFarm} sx={commonStyles(true)}>{permission ? (farm?.grandTotal || 0) : "-"}</TableCell>
                        </>}
                        {/* NOTE: random */}
                        {bakeIndex === 0 && packIndex === 0 && shipIndex === 0 && (
                          <>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>
                              <Link
                                component="button"
                                variant="body2"
                                sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                                onClick={() => {
                                  handleNavigate("random", random?.id);
                                }}
                              >
                                {`${random?.docNo} ${random?.status}`}
                              </Link>
                            </TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{farm?.vapourHeatTreatment?.name}</TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{random?.actualWeight}</TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>
                              {`${random?.out || ""} | ${random?.claimsWeight || ""} | ${random?.totalInspectionWeight || ""}`}
                            </TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{permission ? (random?.cutPoleWeight || 0) : "-"}</TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles()}>{permission ? (random?.lostM || 0) : "-"}</TableCell>
                            <TableCell rowSpan={_logestRandom} sx={commonStyles(true)}>{random?.totalRemainingWeight}</TableCell>
                          </>
                        )}
                        {/* NOTE: bake */}
                        {
                          packIndex === 0 && shipIndex === 0 && (
                            <>
                              <TableCell rowSpan={_longestInBake} sx={commonStyles()}>
                                <Link
                                  component="button"
                                  variant="body2"
                                  sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                                  onClick={() => {
                                    handleNavigate("bake", bake?.bake?.id);
                                  }}
                                >
                                  {`${bake?.bake?.docNo} ${bake?.bake?.status}`}
                                </Link>
                              </TableCell>
                              <TableCell rowSpan={_longestInBake} sx={commonStyles()}>{permission ? (bake?.bake?.eliminateWeight || 0) : "-"}</TableCell>
                              <TableCell rowSpan={_longestInBake} sx={commonStyles()}>{permission ? (bake?.bake?.vhtCost || 0) : "-"}</TableCell>
                              <TableCell rowSpan={_longestInBake} sx={commonStyles()}>{permission ? (bake?.bake?.vat7 || 0) : "-"}</TableCell>
                              <TableCell rowSpan={_longestInBake} sx={commonStyles()}>{permission ? (bake?.bake?.vat3 || 0) : "-"}</TableCell>
                              <TableCell rowSpan={_longestInBake} sx={commonStyles(true)}>{permission ? (bake?.bake?.totalVht || 0) : "-"}</TableCell>
                            </>
                          )
                        }
                        {/* NOTE: pack */}
                        {shipIndex === 0 && (
                          <>
                            <TableCell rowSpan={_shipSpan} sx={commonStyles()}>
                              <Link
                                component="button"
                                variant="body2"
                                sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                                onClick={() => {
                                  handleNavigate("packaging", pack?.packaging?.id);
                                }}
                              >
                                {`${pack?.packaging?.docNo} ${pack?.packaging?.status}`}
                              </Link>
                            </TableCell>
                            <TableCell rowSpan={_shipSpan} sx={commonStyles()}>{pack?.quantity || "-"}</TableCell>
                            <TableCell rowSpan={_shipSpan} sx={commonStyles()}>{pack?.totalBox || "-"}</TableCell>
                            <TableCell rowSpan={_shipSpan} sx={commonStyles(true)}>{permission ? (pack?.materialCost || 0) : "-"}</TableCell>
                          </>
                        )}
                        {/* NOTE: shipping*/}
                        {(
                          <>
                            <TableCell sx={commonStyles()}>
                              <Link
                                component="button"
                                variant="body2"
                                sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                                onClick={() => {
                                  handleNavigate("shipping", ship?.shipping?.id);
                                }}
                              >
                                {`${ship?.shipping?.docNo} ${ship?.shipping?.status}`}
                              </Link>
                            </TableCell>
                            <TableCell sx={commonStyles()}>{ship?.shipping?.type?.name || "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{ship?.quantity || "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.nonVat || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.vat || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.vat7 || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.vat3 || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.totalAmount || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.costPerBox || 0) : "-"}</TableCell>
                          </>
                        )}
                        {/* NOTE: Cost */}
                        {randomIndex === 0 && bakeIndex === 0 && packIndex === 0 && shipIndex === 0 && <TableCell rowSpan={_calLongestFarm} sx={commonStyles()}>{permission ? (farm?.costPerBox || 0) : "-"}</TableCell>}
                      </TableRow>);
                  });
                }
              });
            }
          });

          if (PackFromRandom && _packCount > 0) {
            PackFromRandom?.forEach((pack, packIndex) => {
              if (!pack?.isBake) {
                if (!pack?.packaging?.shipping || pack?.packaging?.shipping?.length === 0) {
                  rows.push(
                    <TableRow key={`random-${farm.id}-${random.id}-${pack.id}-bake`}>
                      {/* NOTE: Bake */}
                      {packIndex === 0 && <TableCell rowSpan={_calLongestBakeForRandom} colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>}
                      {/* NOTE: Pack */}
                      {(
                        <>
                          <TableCell sx={commonStyles()}> <Link
                            component="button"
                            variant="body2"
                            sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                            onClick={() => {
                              handleNavigate("packaging", pack?.packaging?.id);
                            }}
                          >
                            {`${pack?.packaging?.docNo || "-"} ${pack?.packaging?.status}`}
                          </Link></TableCell>
                          <TableCell sx={commonStyles()}>{pack?.quantity || "-"}</TableCell>
                          <TableCell sx={commonStyles()}>{pack?.totalBox || "-"}</TableCell>
                          <TableCell sx={commonStyles(true)}>{permission ? (pack?.materialCost || 0) : "-"}</TableCell>
                        </>
                      )}
                      {/* NOTE: No data for Shipping */}
                      {<TableCell rowSpan={1} colSpan={props.shipping?.length} sx={commonStyles()}>ไม่มีข้อมูล Shipping</TableCell>}

                    </TableRow>
                  );
                } else {
                  // NOTE: cal all pack row
                  const _shipSpanForRandom = pack?.packaging?.shipping?.length || 1;
                  pack?.packaging?.shipping?.forEach((ship: IShippingDetail, shipIndex: number) => {
                    rows.push(
                      <TableRow key={`random-${farm.id}-${random.id}-${pack.id}-${ship.id}`}>
                        {/* NOTE: Bake */}
                        {packIndex === 0 && shipIndex === 0 && <TableCell rowSpan={_calLongestBakeForRandom} colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>}
                        {/* NOTE: Pack */}
                        {shipIndex === 0 && (
                          <>
                            <TableCell rowSpan={_shipSpanForRandom} sx={commonStyles()}> <Link
                              component="button"
                              variant="body2"
                              sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                              onClick={() => {
                                handleNavigate("packaging", pack?.packaging?.id);
                              }}
                            >
                              {`${pack?.packaging?.docNo || "-"} ${pack?.packaging?.status}`}
                            </Link></TableCell>
                            <TableCell rowSpan={_shipSpanForRandom} sx={commonStyles()}>{pack?.quantity || "-"}</TableCell>
                            <TableCell rowSpan={_shipSpanForRandom} sx={commonStyles()}>{pack?.totalBox || "-"}</TableCell>
                            <TableCell rowSpan={_shipSpanForRandom} sx={commonStyles(true)}>{permission ? (pack?.materialCost || 0) : "-"}</TableCell>
                          </>
                        )}
                        {/* NOTE: shipping*/}
                        {(
                          <>
                            <TableCell sx={commonStyles()}>
                              <Link
                                component="button"
                                variant="body2"
                                sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                                onClick={() => {
                                  handleNavigate("shipping", ship?.shipping?.id);
                                }}
                              >
                                {`${ship?.shipping?.docNo} ${ship?.shipping?.status}`}
                              </Link>
                            </TableCell>
                            <TableCell sx={commonStyles()}>{ship?.shipping?.type?.name || "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{ship?.quantity || "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.nonVat || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.vat || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.vat7 || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.vat3 || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.totalAmount || 0) : "-"}</TableCell>
                            <TableCell sx={commonStyles()}>{permission ? (ship?.shipping?.costPerBox || 0) : "-"}</TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  });
                }
              }
            });
          }
        }
      });
    }
    // NOTE: Summary
    if (farmIndex === (props.order?.farmProductReceipts?.length || 1) - 1) {
      rows.push(
        <TableRow key={`summary`}>
          <TableCell colSpan={props?.allColSpan} sx={{ ...commonStyles(), textAlign: 'end', fontWeight: 'bold' }}>จำนวนทั้งหมด (กล่อง)</TableCell>
          <TableCell sx={{ ...commonStyles(), fontWeight: 'bold' }}>{props?.order?.totalBox}</TableCell>
        </TableRow>
      );
      rows.push(
        <TableRow key={`summary`}>
          <TableCell colSpan={props?.allColSpan} sx={{ ...commonStyles(), textAlign: 'end', fontWeight: 'bold' }}>ต้นทุนทั้งหมด</TableCell>
          <TableCell sx={{ ...commonStyles(), fontWeight: 'bold' }}>{permission ? props?.order?.totalCostInOrder : '-'}</TableCell>
        </TableRow>
      );
      rows.push(
        <TableRow key={`summary`}>
          <TableCell colSpan={props?.allColSpan} sx={{ ...commonStyles(), textAlign: 'end', fontWeight: 'bold' }}>กำไร</TableCell>
          <TableCell sx={{ ...commonStyles(), fontWeight: 'bold' }}>{permission ? props?.order?.profit : "-"}</TableCell>
        </TableRow>
      );
    }
  });
  return rows;
};

// Ensure you export the component
export default TableProcessSummaryRow;
