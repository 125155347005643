import { FC, useState } from "react";
import { IRoleCriteria, IRoleListView, IUser, IUserRole } from "../Services/RoleService";
import { useNavigate } from "react-router-dom";
import { Box, Breadcrumbs, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
import { IUserDetail } from "../../Login/Services/LoginService";


interface RolesListViewProps {
  onSearchByCriteria: (criteria: IRoleCriteria) => Promise<void>;
  isLoading?: boolean | false;
  onCreate: (data: any) => Promise<void>;
  onRemove: (id: string) => Promise<void>;
  data: IRoleListView;
  permission: IUserDetail;
}
const RolesListView: FC<RolesListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState('');
  const [userGroup, setUserGroup] = useState('');
  const statusOptions = [
    { value: "undefined", name: "ทั้งหมด" },
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" }
  ];
  const [status, setStatus] = useState<boolean | string>("undefined");

  const navigate = useNavigate();
  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IRoleCriteria = {
      name: name,
      userGroup: userGroup,
      status: status === "undefined" ? undefined : status === "true" ? true : false,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearchByCriteria(criteria);
  };
  //SECTION - Variables for columns TableDataList
  const columns = [
    { label: 'ชื่อผู้ใช้งาน', render: (row: IUserRole) => <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>{`${row.firstName || ''} ${row.lastName || ''}`}</Box> },
    { label: 'ตำแหน่ง', render: (row: IUserRole) => <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>{row.userGroup?.name || ''}</Box> },
    { label: 'อีเมล', render: (row: IUserRole) => <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>{row.email || ''}</Box> },
    {
      label: 'Status', render: (row: IUserRole) => (<Chip
        label={row.isActive === true ? 'Active' : 'Inactive'}
        color={row.isActive ? "success" : "default"}
        variant="outlined"
      />)
    },
    {
      label: 'บันทึกล่าสุด', render: (row: IUserRole) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.createBy?.firstName || ''} {row.createBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? '' : dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IUserRole) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.updateBy?.firstName || ''} {row.updateBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? '' : dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
        </Box>
      )
    },
  ];
  const actions = (row: IUser) => (
    <IconButton
      onClick={() => {
        navigate(`/user-role-detail/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        navigate(`/user-role-detail`);
      }}>
      สร้าง
    </Button>
  ) : <></>;
  return (<ListViewPage
    breadcrumb={
      <>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>ผู้ใช้งาน</Typography>
          <Typography variant="h6" color="text.primary">รายการ</Typography>
        </Breadcrumbs>
      </>
    }
    criteria={
      <>
        <Box component={"form"}>
          <Grid container spacing={2} alignItems={"end"}>
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <Grid container spacing={2} alignItems={"end"}>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <TextField fullWidth label="ชื่อผู้ใช้งาน" value={name} onChange={(e) => setName(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <TextField fullWidth label="ตำแหน่ง" value={userGroup} onChange={(e) => setUserGroup(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">สถานะ</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      value={status}
                      label="สถานะ"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      {statusOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} textAlign={"end"} alignSelf={"end"}>
              <Button
                onClick={() => searchByCriteria()}
                variant="contained"
                startIcon={<SearchIcon />}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>}
    table={
      <>
        <TableDataList
          data={props.data?.data || []}
          total={props.data?.total || 0}
          columns={columns}
          actionButtons={actionButtons}
          rowActions={actions}
          page={page}
          limit={limit}
          onPageChange={(newPage: number) => {
            setPage(newPage);
            searchByCriteria(newPage);
          }}
          onRowsPerPageChange={(newLimit) => {
            setLimit(newLimit);
            setPage(0);
            searchByCriteria(0, newLimit);
          }}
        />
      </>}
    isLoading={props.isLoading ?? false}
  />);
};

export default RolesListView;
